//Avatar
.avatar {
//        display: inline-block;
          vertical-align: middle;
          border-radius: 3px;
          overflow: hidden;
          img {height: 32px; width: 32px; }
}

//STILE GENERICO AVATAR BOX
.avatarbox{

          margin: 0px;
          padding: 0px;
          display: block;


          .blocco-avatar{
                    float: left;
                    display: table;


                    img{ border-radius: 4px; margin-right: 5px; max-width: 30px;  }
          }


          .intnome{
                    font-size: 0.9em;
                    margin-bottom: 0px;
                    clear: both;
                    display: block;
                    text-align: left;
          }
          .nome{
                    font-size: 1.1em;
                    line-height: 1.0em;
                    font-weight: 600;
                    text-align: left;
          }

          .info-avatar{float: left;}

          a{  color: $txtcolor; }

             .avatar {

//                              border-radius: 50px!important;
//                       float: left;
//                       width: 30px;

                              //      display: inline-block;
//                                        vertical-align: middle;
//                                        border-radius: 3px;
//                                        overflow: hidden;
                              }
          height: 40px;
          .dropdown-menu{
                    margin-top: 5px;
//                    display: block;
          }


}
//======= FINE GENERICO


//avatar BOX TESTATA

.album-cover .avatarbox{

          color: #ffffff!important;
          display: block;
//          z-index: 3000;
          cursor: pointer;
//          font-size: 50px;


           .intnome{
                    font-size: 0.9em;

          }
          .nome{
                    font-size: 1.3em;

          }

          a.avatarlink {
                    color: #ffffff!important;
                    display: block;
                    cursor: pointer!important;

          }

}
//======= FINE TESTATA



//avatar BOX  OVERLAY Fotografie

//.gallery-item-credits .avatarbox{
//
//             .intnome{
//                    font-size: 0.9em;
//
//          }
//          .nome{
//                    font-size: 1.3em;
//
//          }
//
//
//
//          a{
//                    color: #ffffff;
//          }
//          .dropdown-menu{
//                    visibility: hidden;
//          }
//
//}



.avatar-nav .avatarbox{



            .blocco-avatar{
                    float: left;
                    display: table;

                    img{ border-radius: 4px; margin-right: 5px; height: 30px; }
          }

           .intnome{
                    font-size: 0.9em;

          }
          .nome{
                    font-size: 1.1em;
                    line-height: 1.0em;

          }


}



//avatar BOX  OVERLAY Fotografie

.gallery-item-credits .avatarbox{

             .intnome{
                    font-size: 0.9em;
          }
          .nome{
                    font-size: 1.3em;
          }

          a{
                    color: #ffffff;
          }
          .dropdown-menu{
                    visibility: hidden;
          }

}



.avatar-nav .avatarbox{

            .blocco-avatar{
                    float: left;
                    display: table;
                    img{ border-radius: 4px; margin-right: 5px; height: 30px; }
             }
           .intnome{
                    font-size: 0.9em;

            }
          .nome{
                    font-size: 1.1em;
                    line-height: 1.0em;
           }

}



//avatar DRUGO ---- BOX  OVERLAY Fotografie


.dr--gallery-item-overlay .avatarbox{

    display: inline-block;
    position: relative;
    position: static;


            .blocco-avatar{
                    float: left;
                    display: table;

                    img{ border-radius: 4px; margin-right: 5px; height: 28px; }
          }

           .intnome{
                    font-size: 0.8em;

          }
          .nome{
                    font-size: 1.0em;
                    line-height: 0.8em;

          }

          a{
                    color: #ffffff;
          }
          .dropdown-menu{
                    visibility: hidden;
          }


}


.img-infobar01 .avatarbox{

	color: #ffffff!important;
	display: block;
//          z-index: 3000;
	cursor: pointer;
//          font-size: 50px;


	 .intnome{
						font-size: 0.9em;

	}
	.nome{
						font-size: 1.3em;

	}

	a.avatarlink {
						color: #ffffff!important;
						display: block;
						cursor: pointer!important;

	}


}

.comment-avatar {img {width: 40px; height: 40px; }}
