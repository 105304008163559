//Fonts

@font-face {
    font-family: 'CalibriFF';
    src: url('fonts/calibriz-webfont.eot');
    src: url('fonts/calibriz-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/calibriz-webfont.woff2') format('woff2'),
         url('fonts/calibriz-webfont.woff') format('woff'),
         url('fonts/calibriz-webfont.ttf') format('truetype'),
         url('fonts/calibriz-webfont.svg#calibribold_italic') format('svg');
    font-weight: bold;
    font-style: italic;

}

@font-face {
    font-family: 'CalibriFF';
    src: url('fonts/calibrili-webfont.eot');
    src: url('fonts/calibrili-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/calibrili-webfont.woff2') format('woff2'),
         url('fonts/calibrili-webfont.woff') format('woff'),
         url('fonts/calibrili-webfont.ttf') format('truetype'),
         url('fonts/calibrili-webfont.svg#calibrilight_italic') format('svg');
    font-weight: 300;
    font-style: italic;

}

@font-face {
    font-family: 'CalibriFF';
    src: url('fonts/calibril-webfont.eot');
    src: url('fonts/calibril-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/calibril-webfont.woff2') format('woff2'),
         url('fonts/calibril-webfont.woff') format('woff'),
         url('fonts/calibril-webfont.ttf') format('truetype'),
         url('fonts/calibril-webfont.svg#calibrilight') format('svg');
    font-weight: 300;
    font-style: normal;

}

@font-face {
    font-family: 'CalibriFF';
    src: url('fonts/calibrii-webfont.eot');
    src: url('fonts/calibrii-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/calibrii-webfont.woff2') format('woff2'),
         url('fonts/calibrii-webfont.woff') format('woff'),
         url('fonts/calibrii-webfont.ttf') format('truetype'),
         url('fonts/calibrii-webfont.svg#calibriitalic') format('svg');
    font-weight: normal;
    font-style: italic;

}

@font-face {
    font-family: 'CalibriFF';
    src: url('fonts/calibrib-webfont.eot');
    src: url('fonts/calibrib-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/calibrib-webfont.woff2') format('woff2'),
         url('fonts/calibrib-webfont.woff') format('woff'),
         url('fonts/calibrib-webfont.ttf') format('truetype'),
         url('fonts/calibrib-webfont.svg#calibribold') format('svg');
    font-weight: bold;
    font-style: normal;

}

@font-face {
    font-family: 'CalibriFF';
    src: url('fonts/calibri-webfont.eot');
    src: url('fonts/calibri-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/calibri-webfont.woff2') format('woff2'),
         url('fonts/calibri-webfont.woff') format('woff'),
         url('fonts/calibri-webfont.ttf') format('truetype'),
         url('fonts/calibri-webfont.svg#calibriregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

//Calvert light
@font-face {
    font-family: 'Calvert';
    src: url('fonts/calvertmtstdlight-webfont.eot');
    src: url('fonts/calvertmtstdlight-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/calvertmtstdlight-webfont.woff2') format('woff2'),
         url('fonts/calvertmtstdlight-webfont.woff') format('woff'),
         url('fonts/calvertmtstdlight-webfont.ttf') format('truetype'),
         url('fonts/calvertmtstdlight-webfont.svg#calvert_mt_stdlight') format('svg');
    font-weight: 300;
    font-style: normal;

}

//Calvert bold
@font-face {
    font-family: 'Calvert';
    src: url('fonts/calvertmtstdbold-webfont.eot');
    src: url('fonts/calvertmtstdbold-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/calvertmtstdbold-webfont.woff2') format('woff2'),
         url('fonts/calvertmtstdbold-webfont.woff') format('woff'),
         url('fonts/calvertmtstdbold-webfont.ttf') format('truetype'),
         url('fonts/calvertmtstdbold-webfont.svg#calvert_mt_stdbold') format('svg');
    font-weight: bold;
    font-style: normal;

}

//Calvert regular
@font-face {
    font-family: 'Calvert';
    src: url('fonts/calvertmtstd-webfont.eot');
    src: url('fonts/calvertmtstd-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/calvertmtstd-webfont.woff2') format('woff2'),
         url('fonts/calvertmtstd-webfont.woff') format('woff'),
         url('fonts/calvertmtstd-webfont.ttf') format('truetype'),
         url('fonts/calvertmtstd-webfont.svg#calvert_mt_stdregular') format('svg');
    font-weight: normal;
    font-style: normal;

}






 
