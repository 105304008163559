.file-loading {
    top: 0;
    right: 0;
    width: 25px;
    height: 25px;
    font-size: 999px;
    text-align: right;
    color: #fff;
    background: transparent url('../css/images/loading.gif') top left no-repeat;
    border: 0
}

.file-object {
    margin: 0 0 -5px 0;
    padding: 0
}

.btn-file {
    position: relative;
    overflow: hidden
}

.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    text-align: right;
    opacity: 0;
    background: none repeat scroll 0 0 transparent;
    cursor: inherit;
    display: block
}

.file-caption-name {
    display: inline-block;
    overflow: hidden;
    height: 20px;
    word-break: break-all
}

.input-group-lg .file-caption-name {
    height: 25px
}

.file-zoom-dialog {
    text-align: left
}

.file-error-message {
    color: #a94442;
    background-color: #f2dede;
    margin: 5px;
    border: 1px solid #ebccd1;
    border-radius: 4px;
    padding: 15px
}

.file-error-message pre, .file-error-message ul {
    margin: 0;
    text-align: left
}

.file-error-message pre {
    margin: 5px 0
}

.file-caption-disabled {
    background-color: #eee;
    cursor: not-allowed;
    opacity: 1
}

.file-preview {
    border-radius: 3px;
    border: 1px solid #ddd;
//    padding: 5px;
    width: 100%;
    margin-bottom: 5px;
    background-color: $backgroundgrigio;
}

.file-preview-frame {
    position: relative;
    display: table;
    margin: 8px;
    height: 100px;
    border: 1px solid #ddd;
//    box-shadow: 1px 1px 5px 0 #a2958a;
    padding: 6px;
    float: left;
    text-align: center;
    vertical-align: middle;
          background-color: #ffffff;
}

.file-preview-frame:not(.file-preview-error):hover {
    box-shadow: 3px 3px 5px 0 #333 
}

.file-preview-image {
    vertical-align: middle
}

.file-preview-text {
    display: block;
    color: #428bca;
    border: 1px solid #ddd;
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
    outline: 0;
    padding: 8px;
    resize: none
}

.file-preview-html {
    border: 1px solid #ddd;
    padding: 8px;
    overflow: auto
}

.file-zoom-dialog .file-preview-text {
    font-size: 1.2em
}

.file-preview-other {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    text-align: center;
    vertical-align: middle;
    padding: 10px
}

.file-preview-other:hover {
    opacity: .8
}

.file-actions, .file-other-error {
    text-align: left
}

.file-other-icon {
    font-size: 4.8em
}

.file-zoom-dialog .file-other-icon {
    font-size: 8em;
    font-size: 55vmin
}

.file-input-new .file-preview, .file-input-new .close, .file-input-new .glyphicon-file, .file-input-new .fileinput-remove-button, .file-input-new .fileinput-upload-button, .file-input-ajax-new .fileinput-remove-button, .file-input-ajax-new .fileinput-upload-button {
    display: none
}

.btn-file ::-ms-browse {
    width: 100%;
    height: 100%
}

.btn-primary {
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4
}


.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    text-align: right;
    opacity: 0;
    background: none repeat scroll 0 0 transparent;
    cursor: inherit;
    display: block
}

.btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px
}

.form-control {
    display: block;
width: 100%;
height: 34px;
padding: 6px 12px;
font-size: 14px;
line-height: 1.42857143;
color: #555;
background-color: #fff;
background-image: none;
//border: none;
border: 1px solid #ccc;
border-radius: 4px;
//-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
//box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
//-webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
//-o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
//transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
box-shadow: none!important;

}

.input-group {
    position: relative;
    display: table;
    border-collapse: separate
}


/* noinspection CssOverwrittenProperties */

.file-zoom-dialog .file-other-icon {
    font-size: 8em;
    font-size: 55vmin
}

.close{

          margin: 10px 10px;
}

.file-input-new .file-preview, .file-input-new .close, .file-input-new .glyphicon-file, .file-input-new .fileinput-remove-button, .file-input-new .fileinput-upload-button, .file-input-ajax-new .fileinput-remove-button, .file-input-ajax-new .fileinput-upload-button {
    display: none
}

.file-caption-main {
    width: 100%
}

.file-input-ajax-new .no-browse .input-group-btn, .file-input-new .no-browse .input-group-btn {
    display: none
}

.file-input-ajax-new .no-browse .form-control, .file-input-new .no-browse .form-control {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px
}

.file-thumb-loading {
    background: transparent url('../css/images/loading.gif') no-repeat scroll center center content-box !important
}

.file-actions {
    margin-top: 15px
}

.file-footer-buttons {
    float: right
}

.file-upload-indicator {
    display: inline;
    cursor: default;
    opacity: .8;
    width: 60%
}

.file-upload-indicator:hover {
    font-weight: bold;
    opacity: 1
}

.file-footer-caption {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 160px;
    text-align: center;
    padding-top: 4px;
    font-size: 11px;
    color: #777;
    margin: 5px auto
}

.file-preview-error {
    opacity: .65;
    box-shadow: none
}

.file-preview-frame:not(.file-preview-error) .file-footer-caption:hover {
    color: #000
}

.file-drop-zone {
//    border: 1px dashed #aaa;
    border-radius: 4px;
    height: 100%;
    text-align: center;
    vertical-align: middle;
//    margin: 12px 15px 12px 12px;
    padding: 5px;
          background-image: url("images/bkg-upload.jpg");

}

.file-drop-zone-title {
    color: #aaa;
    font-size: 1.6em;
    padding: 85px 10px;
    cursor: default
}

.file-preview .clickable, .clickable .file-drop-zone-title {
    cursor: pointer
}

.file-drop-zone.clickable:hover {
//    border: 2px dashed #999
}

.file-drop-zone.clickable:focus {
    border: 2px solid #5acde2
}

.file-drop-zone .file-preview-thumbnails {
    cursor: default
}

.file-highlighted {
    border: 2px dashed #999 !important;
    background-color: #f0f0f0
}

.file-uploading {
    background: url('/css/images/loading-sm.gif') no-repeat center bottom 10px;
    opacity: .65
}

.file-thumb-progress {
    height: 10px
}

.file-thumb-progress .progress, .file-thumb-progress .progress-bar {
    height: 10px;
    font-size: 9px;
    line-height: 10px
}

.file-thumbnail-footer {
    position: relative
}

.file-thumb-progress {
    position: absolute;
    top: 35px;
    left: 0;
    right: 0
}

.file-zoom-fullscreen.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
}

.file-zoom-fullscreen .modal-dialog {
    position: fixed;
    margin: 0;
    width: 100%;
    height: 100%;
    padding: 0
}

.file-zoom-fullscreen .modal-content {
    border-radius: 0;
    box-shadow: none
}

.file-zoom-fullscreen .modal-body {
    overflow-y: auto
}

.file-zoom-dialog .modal-body {
    position: relative !important
}

.file-zoom-dialog .btn-navigate {
    position: absolute;
    padding: 0;
    margin: 0;
    background: transparent;
    text-decoration: none;
    outline: 0;
    opacity: .7;
    top: 45%;
    font-size: 4em;
    color: #1c94c4
}

.file-zoom-dialog .floating-buttons {
    position: absolute;
    top: 5px;
    right: 10px
}

.floating-buttons, .floating-buttons .btn {
    z-index: 3000
}

.file-zoom-dialog .kv-zoom-actions .btn, .floating-buttons .btn {
    margin-left: 3px
}

.file-zoom-dialog .btn-navigate:not([disabled]):hover, .file-zoom-dialog .btn-navigate:not([disabled]):focus {
    outline: 0;
    box-shadow: none;
    opacity: .5
}

.file-zoom-dialog .btn-navigate[disabled] {
    opacity: .3
}

.file-zoom-dialog .btn-prev {
    left: 1px
}

.file-zoom-dialog .btn-next {
    right: 1px
}

.file-drag-handle {
    display: inline;
    margin-right: 2px;
    font-size: 16px;
    cursor: move;
    cursor: -webkit-grabbing
}

.file-drag-handle:hover {
    opacity: .7
}

.file-zoom-content {
    height: 480px;
    text-align: center
}

.file-preview-initial.sortable-chosen {
    background-color: #d9edf7
}

.file-preview-frame.sortable-ghost {
    background-color: #eee
}


.kv-main{
          *:focus, *:active {
                    outline: none!important;
          }
}
