//Base
//a { color: $brand-primary; /*text-decoration: underline; */}
//a:hover { text-decoration: none; }

img {
	width: 100%;
}

h1 {
	font-family: $fontCalvert;
	font-weight: normal;
	line-height: 1;
}

h4 {
	font-family: $fontCalvert;
	font-weight: bold;
	line-height: 1.2;
}

body {
	height: 92vh;
}
@media #{$md-screen} {}
@media #{$sm-screen} {
	h1 {
		font-size: $font-size-h1-tablet;
	}

	body {
		height: 92vh;
	}
}
@media #{$mobile} {
	body {
		font-size: $font-size-base-mobile;
	}
}
@media #{$mobile-sm} {
	h1 {
		font-size: 40px;
	}
}
//pannello controllo

.imagecontrol {
	position: absolute;
	top: 30px;
	left: 30px;
	z-index: 15;
}

body {
	-webkit-font-smoothing: antialiased!important;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
}

.aligncenter {
	text-align: center;

	input {
		height: 40px;
		padding: 5px 10px;
		width: 300px;
	}
}

.caricatore {
	max-width: 20px;
}

.caricatore--bar {
	max-width: 20px;
	margin-top: -4px;
}
//.save-loading-gif{
//          max-width: 20px;
//}

.animate-in {
	-webkit-animation: fadeIn 0.3s ease-in;
	animation: fadeIn 0.3s ease-in;
}

.animate-out {
	-webkit-transition: opacity 0.3s;
	transition: opacity 0.3s;
	opacity: 0;
}
@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
// Immagini e loghi pagine statiche chi siamo, abbonamenti..//

.staticpage {
	img {
		width: auto;
	}
	}
	@media #{$mobile} {
		.staticpage {
			img {
				max-width: 100%;
			}
		}
	}
	@media #{$mobile-sm} {
		.staticpage {
			img {
				max-width: 100%;  
			}
		}
	}

.pagina404 { text-align: center;
	h1 {font-size: 15rem; color: #FFF; text-shadow: 2px 2px 0 rgba(0,0,0,0.2);}
h2 {font-size: 3rem; font-family: "Calvert", serif; color: #FFF; text-shadow: 2px 2px 0 rgba(0,0,0,0.2);}
h3 {font-size: 2rem; font-family: "Calvert", serif; color: #FFF; text-shadow: 2px 2px 0 rgba(0,0,0,0.2);}


}

.icona-calendar {float: left; font-size: 30px; color: #CCC;
                  i {margin: 20px 15px 0px 0px;}
								}
@media #{$sm-screen} { .icona-calendar {i {margin: 0px 12px 0px 0px; } }
	                     .date-evento {margin-top: 15px; font-size: 12px !important;
	                                   strong {font-size: 15px !important; }
										 }
									 }

@media #{$md-screen} {.date-evento {margin-top: 15px;}
                      //.icona-calendar i {margin: 15px 12px 0px 0px;}
											 }

/* STILI NEWSLETTER */

.invio-newsletter {
	h2 {font-size: 2.5em; font-family: "Calvert", serif; }
	h3 {font-size: 1.8em; font-family: "Calvert", serif; float: left; margin: 3px 15px 0 0; }
	h4 {font-size: 1.5em; font-family: "Calvert", serif; margin-top: 25px;}
	input, textarea {display: block;
    width: 100%;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: none !important; }
}

.newsletter-notizia {padding: 5px 20px 15px 20px; border-radius: 4px; border: 1px solid #CCC; margin: 20px 0; }

.alert-cookies {
		position: fixed;
    bottom: 20px;
    z-index: 5000;
    width: 430px;
    background-color: #55a5e2;
    padding: 15px;
    right: 20px;
    color: #ffffff;
    font-size: 13px;
    line-height: 1.3em;
    letter-spacing: 1px;
    border-radius: 6px;
    -webkit-box-shadow: 0px 0px 24px 4px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 24px 4px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 24px 4px rgba(0, 0, 0, 0.25); }

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;}

.alert-cookies .contentalert .linkinfo {
    color: #ffffff !important;
    font-style: italic;
    font-size: 10px;
    display: block;
    text-decoration: underline;}

.alert-cookies button.accept {
    float: right;
    margin-top: 5px;
    padding: 5px 15px;
    background-color: #fbba00;
    border: none;
    border-radius: 6px;
    color: #000000;
    font-weight: 400;
}
