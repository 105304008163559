//Header

.header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 11;
	width: 100%;
	height: 50px;
	background: rgba(0, 44, 74, .8);
}

.header .logo,
.header .nav-secondary {
	float: left;
}

.header .nav-trigger {
	display: none;
}

.header .logo {
	float: left;
	margin-top: 9px;
	background-size: contain;
}

.header .nav-secondary {
	padding: 14px 0 0 28px;
}

.header-inner {
	float: right;
	z-index: 51;
}

.header-actions {
	float: left;
	padding: 16px 15px 0 0;
}

.header-actions .link-close {
	display: none;
}

.header-actions a {
	color: #fff;
	text-decoration: none;
}

.header .nav-user {
	position: relative;
	height: 50px;
	padding: 10px 0 0 15px;
	float: right;
	background: #4e97cb;
}

.header .nav-user:after {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 100%;
	width: 1000%;
	background: #4e97cb;
}
@media #{$sm-screen} {
	.header .nav-secondary,
	.header .nav-user li,
	.header-actions .link-search,
	.nav-user > ul > li > a > i {
		display: none;
	}

	.header-actions {
		padding: 13px 12px 0 0;
		opacity: 0;
		visibility: hidden;
		@include transition(opacity .4s ease, visibility .4s ease);
	}

	.header-actions .link-close {
		display: inline-block;
		position: relative;
		width: 32px;
		height: 22px;
	}

	.header-actions .link-close span {
		position: absolute;
		top: 12px;
		display: block;
		height: 3px;
		width: 100%;
		background: #fff;
	}

	.header-actions .link-close span:nth-child(1) {
		@include rotate(45);
	}

	.header-actions .link-close span:nth-child(2) {
		@include rotate(-45);
	}

	.header {
		position: fixed;
		@include transition(margin .4s ease);
	}

	.header-hidden {
		top: 0;
	}

	.header-inner {
		position: absolute;
		top: 0;
		right: 15px;
	}

	.header .nav-user {
		position: fixed;
		top: 0;
		right: 24px;
		// z-index: 102;
		background: #55a5e2;
	}

	.header .nav-user:after {
		background: #55a5e2;
	}

	.header .nav-user .dropdown {
		display: block;
	}

	.header .logo {
		float: none;
		position: absolute;
		top: 0;
		left: 50%;
		margin-left: -57px;
		@include transition(opacity .4s ease);
	}

	.header .nav-trigger {
		position: absolute;
		top: 0;
		left: 25px;
		display: inline-block;
		margin-top: 14px;
	}
}
@media #{$mobile} {
	.header .nav-trigger {
		left: 20px;
	}

	.header .nav-user {
		right: 23px;
	}
}
