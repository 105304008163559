//Popup
.popup {
          max-width: 975px;
          margin: 0 auto;
          width: 100%;
}

.popup .mfp-close {
          display: none;
}

.popup-actions {
          padding-bottom: 10px;
}

.popup-actions a {
          color: #fff;
          text-decoration: none;
}

.popup-image {
          border-radius: 4px;
          overflow: hidden;
}
.modal-login-title {
          font-family: "Calvert", serif;
          color: #0061a1;
          font-size: 3rem;
          margin-top: 10px;
}

.modal-login {
          border: 0px;
          padding: 0px;
          max-width: 850px;
          margin: auto;
//          border-radius: 7px;
//          -webkit-border-top-left-radius: 7px;
//          -webkit-border-top-right-radius: 7px;
//          -webkit-border-bottom-left-radius: 7px;
//          -webkit-border-bottom-right-radius: 7px;
//          -moz-border-radius-topleft: 7px;
//          -moz-border-radius-bottomleft: 7px;
//          border-top-left-radius: 7px;
//          border-bottom-left-radius: 7px;

          label {
                    font-weight: 300;
                    font-size: 1.5rem;
          }
          a {
                    text-decoration: underline;
                    color: #337ab7;
                    margin-top: 20px;
                    font-size: 1.4rem;
                    text-align: right;
          }
          button {
                    width: 100%;
                    margin-top: 10px;
                    margin-bottom: 10px;
          }
}

.modal-login-left {
          background-color: $backgroundgrigio;
          height: 355px;
          -webkit-border-top-left-radius: 7px;
          -webkit-border-bottom-left-radius: 7px;
          -moz-border-radius-topleft: 7px;
          -moz-border-radius-bottomleft: 7px;
          border-top-left-radius: 7px;
          border-bottom-left-radius: 7px;
          p {
                    text-align: center;
          }

}

@media #{$mobile} {
          .modal-login-left {
                    -webkit-border-top-left-radius: 7px;
                    -webkit-border-top-right-radius: 7px;
                    -moz-border-radius-topleft: 7px;
                    -moz-border-radius-topright: 7px;
                    border-top-left-radius: 7px;
                    border-top-right-radius: 7px;
                    border-bottom-left-radius: 0px;
          }
}

@media #{$sm-screen} {
          .modal-login-left {
                    padding-top: 1px;
                    -webkit-border-top-left-radius: 7px;
                    -webkit-border-top-right-radius: 7px;
                    -moz-border-radius-topleft: 7px;
                    -moz-border-radius-topright: 7px;
                    border-top-left-radius: 7px;
                    border-top-right-radius: 7px;
                    border-bottom-left-radius: 0px;
          }
}

.modal-login-right {
          background-image: url(../../css/images/registrazione/bkg-modulo-adv.png);
          padding-left: 0px;
          padding-right: 0px;
          background-position: center center;
          background-size: cover;
          height: 355px;
          img {
                    bottom: 0px;
                    right: 0px;
                    position: absolute;
                    max-width: 400px;
          }
          p {
                    font-size: 1.8rem;
                    color: #FFF;
                    text-align: center;
                    padding: 20px 15px;
                    font-weight: 100
          }
}



.modal-content2 {
          position: relative;
          -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
          box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
          background-clip: padding-box;
          outline: 0;
}

@media #{$mobile} {
          .modal-login-right {
                    img {
                              height: 50%;
                    }
          }
}

@media #{$sm-screen}  {
          .modal-login-right {
                    img {
                              height: 80%;
                    }
          }
}

.alertmodal { font-family: "Calvert"; text-align: center;

              i {font-size: 12rem; font-family: "Calvert"}
              i:last-child {font-size: 2rem; font-family: "Calvert"}
              h2 {font-size: 3rem; font-family: "Calvert"}
              h3 {font-size: 2rem; font-family: "Calvert"} }

.popup-termini {

	 overflow: auto;
	 height: 300px;


 }
#alert_cookie_newterms_button{ background-color: #ccc!important; color: #000!important;}
