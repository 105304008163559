//Main

.main { background: $backgroundgrigio; }

.main-primary { position: relative; padding-top: 56px; }

@media #{$sm-screen} {
	.main-primary { padding-top: 56px; }
}

@media #{$mobile} {
	.main-primary { padding-top: 0; }
}
