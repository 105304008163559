//Post
.post { padding-bottom: 16px; border-bottom: 1px solid #ccc; font-size: 20px; line-height: 1.4; }
.post ~ .post { padding-top: 27px; }
.post-content { overflow: hidden; padding-top: 30px; }
.post-meta { min-width: 126px; float: left; padding-right: 12px; border-right: 1px solid #ccc; margin-right: 16px; color: $brand-lightgreen; font-size: 22px; font-family: $fontCalvert; font-weight: 300; text-align: right; line-height: 1; }
.post-meta strong { display: block; padding-bottom: 5px; }
.post-entry { overflow: hidden; }
.post-title { margin: 0 0 10px; color: #333; text-transform: uppercase; }
.post-title span { display: block; text-transform: none; }
.post-actions { padding-top: 10px; text-align: right; }

@media #{$sm-screen} {
	.post-meta { float: none; width: 100%; text-align: left; border: none; padding-bottom: 13px; }
	.post-meta strong { display: inline; }
	.post-title { font-size: 28px; margin-bottom: 5px; }

	.post-content { padding: 30px 20px 0; }
}

@media #{$mobile} {
	.post-content { padding: 30px 0 0; }
	.post-title span { display: inline; }
	.post-actions .btn { width: 100%; display: block; font-size: 20px; padding: 12px 19px 10px; }
}



.riga-posts {border-bottom: 1px dashed #ccc; padding: 15px; line-height: 30px; a { color: #0061a1; font-weight: bold; }
 a:hover {color: #002b4b;}}
 .riga-posts:hover {background-color: $backgroundgrigio;}

.posts-icon {text-align: right; i {color: #999; margin-left: 10px;} i:hover {color: #0f0f0f; } }

.posts-add {font-size: 1.7rem; text-transform: uppercase; text-align: right; color: #999; font-weight: 100;
i, a {font-size: 4.5rem; color: #0061a1; }
i:hover, a:hover {color: #002b4b;}
span {line-height: 55px; vertical-align: top; margin-right: 10px; }

 }

.title-posts {background-color: #f7f7f7; border-bottom: 1px solid #ccc; margin-left: 0px; margin-right: 0px;
              h5 {color: #000; margin-left: 15px; font-weight: 900; font-size: 2.8rem;}
}

.filtro-modera {margin-top: 15px; margin-bottom: 9px; input {margin-left: 20px;} span {color: #999; font-size: 13px; } }

li.tab-active a {font-weight: bold; color: #FFFFFF !important; background-color: #0c629f; list-style: none;}
