kick//Intro

.intro {
	position: relative;
	color: #fff;
}

.intro-image {
	height: 577px;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}

.intro-image img {
	display: none;
}

.intro-darkimage {
	a {
		height: 100%;
		width: 100%;
	}

	img {
		height: 100%;
		width: 100%;
	}
	//          width: 100%;
	//          height: 100%;
	//          background-color: #000;
	//          opacity: 0.5;
	//       position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	//       opacity: 0;
	//          pointer-events: none;
	padding-top: 20px;
	min-height: 90vh; //          height: 100%;
	@media #{$mobile} {
		min-height: 80vh;
	}
	// ====================================
	// MODIFICHE OMBRA FOTOGRAFIA ARTICOLO
	background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.0)));
	background: -webkit-linear-gradient(bottom, transparent 0%, rgba(0, 0, 0, 0.0) 100%);
	background: -moz-linear-gradient(bottom, transparent 0%, rgba(0, 0, 0, 0.0) 100%);
	background: linear-gradient(to top, transparent 1%, rgba(0, 25, 75, 0.85) 100%);
	-webkit-transition: opacity 0.8s;
	-moz-transition: opacity 0.8s;
	transition: opacity 0.8s; // ==============
	// ====================================
	z-index: 60000;
	//          &:hover{
	//                    background: none;
	//          }
}

.blog-darkimage {
	bottom: 0;
	left: 0;
	right: 0;
	padding-top: 20px;
	min-height: 75vh;
	background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.0)));
	background: -webkit-linear-gradient(bottom, transparent 0%, rgba(0, 0, 0, 0.0) 100%);
	background: -moz-linear-gradient(bottom, transparent 0%, rgba(0, 0, 0, 0.0) 100%);
	background: linear-gradient(to top, transparent 1%, rgba(0, 25, 75, 0.85) 100%);
	-webkit-transition: opacity 0.8s;
	-moz-transition: opacity 0.8s;
	transition: opacity 0.8s;
	z-index: 60000;
}

.intro-content {
	position: absolute;
	top: 50%;
	left: 50%;
	max-width: 842px;
	width: 100%;
	margin: 10px 0 0 -430px;
	display: block;
	-webkit-transition: opacity 1;
	-moz-transition: opacity 1s;
	transition: opacity 1s;
	color: #FFF;
	/*         padding: 20px 30px; */
	/*          background-image: url('images/intro-content_bg60.png') ; */
	/*          background-repeat: repeat; */
	@include transform(translateY(-50%));
}

.intro-credits {
	position: absolute;
	bottom: 17px;
	left: 0;
	width: 100%;
	text-align: right;

	p {
		color: #FFF;
	}

	a.autorecopertina {
		color: #ffffff;
		text-decoration: underline;
	}
}

.intro-content h3 {
	margin: 0 0 15px;
	@media #{$mobile} {
		font-size: 1.2em;
		line-height: 1em;
	}

	a {
		color: #ffffff;
		text-decoration: none;
	}
}

.intro-title {
	line-height: 1.2;
	font-size: 3em;
	margin: 0 0 6px;
	text-shadow: 2px 2px 0 rgba(0, 0, 0, .2);
	letter-spacing: -0.021em;

	.fa {
		font-size: 25px;
		cursor: pointer;
	}
	@media #{$mobile} {
		font-size: 2em;
	}

	a {
		color: #ffffff;
		text-decoration: none;
	}
}

.intro-title small {
	display: block;
	color: inherit;
	font-size: 25px;
	text-transform: uppercase;
	letter-spacing: -0.038em;
	font-family: $fontCalibri;
	text-shadow: none;
	font-weight: bold;
	padding-bottom: 8px;
}

.intro-meta {
	padding-top: 15px;
	border-top: 1px dashed #d6d6d6;
	margin-top: 10px;
	font-size: 14px;
}

.intro .ribbon {
	position: absolute;
	bottom: 100%;
	left: 0;
	margin-bottom: 20px;
}

.intro-secondary .intro-image {
	height: 360px;
}

.intro-secondary .intro-content {
	left: 0;
	max-width: 100%;
	margin: 0;
}

.intro-secondary .intro-entry {
	max-width: 390px;
}

.intro-actions {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding: 16px 0 14px;
	border-bottom: 1px solid rgba(255, 255, 255, .4);
}

.intro-actions a {
	color: #fff;
	text-decoration: none;
}

.intro-actions i {
	margin: -2px 1px 0 0;
}

.intro-actions a:hover {
	text-decoration: underline;
}

.intro-actions-secondary {
	display: none;
}

.intro-credits-secondary {
	padding-top: 3px;
	font-size: 18px;
	line-height: 1;
}

.intro-credits-secondary .avatar {
	border-radius: 8px;
	margin-right: 5px;
}

.intro-credits-secondary span {
	display: inline-block;
	vertical-align: middle;
}

.intro-credits-secondary strong {
	display: block;
	font-size: 24px;
}

.intro-tertiary .intro-image {
	/*height: 532px;*/
	height: 90vh;
	margin-bottom: 30px;
}

.intro {
	position: relative;
}

.height80 .intro-image {
	height: 90vh;
}
@media #{$md-screen} {
	.intro-content {
		max-width: 654px;
		margin-left: -347px;
	}

	.height80 .intro-image {
		height: 90vh;
	}
}

.intro-image {
	@media (max-device-aspect-ratio: 3/4) {
		height: calc(100vw * 1.333 - 9%);
	}
	@media (max-device-aspect-ratio: 2/3) {
		height: calc(100vw * 1.5 - 9%);
	}
	@media (max-device-aspect-ratio: 10/16) {
		height: calc(100vw * 1.6 - 9%);
	}
	@media (max-device-aspect-ratio: 9/16) {
		height: calc(100vw * 1.778 - 9%);
	}
}
@media #{$sm-screen} {
	.intro-image {
		height: 90vh;
		background-position: 25% center;
	}

	.intro-content {
		top: 178px;
		max-width: 586px;
		margin-left: -339px;
		@include transform(none);
	}

	.intro-credits {
		text-align: left;
	}

	.intro-actions-secondary {
		display: block;
		position: absolute;
		bottom: 30px;
		left: 0;
		right: 0;
		text-align: center;
	}

	.intro-actions-secondary a {
		display: inline-block;
		text-decoration: none;
		@include rotate(-90);
	}

	.intro-actions-secondary i {
		width: 20px;
		height: 30px;
	}

	.intro-actions-secondary i + i {
		margin-left: -12px;
	}

	.intro-secondary .intro-content {
		top: 94px;
		left: 20px;
	}

	.intro-secondary .intro-image {
		height: 90vh;
		background-position: 75% center;
	}

	.intro-actions {
		border: none;
		padding-bottom: 13px;
	}

	.intro-actions .link-back {
		font-size: 0;
		line-height: 0;
	}

	.intro-actions .ico-arrow-left {
		width: 16px;
		height: 26px;
	}

	.intro-tertiary .intro-image {
		height: 90vh;
	}
}
@media #{$mobile} {
	.intro-image {
		height: 90vh;
	}

	.height80 .intro-image {
		height: 90vh;
	}

	.intro-content {
		top: 148px;
		left: 20px;
		right: 20px;
		margin-left: 0;
		width: auto;
		max-width: none;
	}

	.intro-credits {
		display: none;
	}

	.intro-secondary .intro-image,
	.intro-tertiary .intro-image {
		height: 80vh;
	}

	.intro-secondary .intro-content {
		left: 5px;
	}

	.intro-actions {
		left: 5px;
		padding-top: 9px;
	}
}
// FIRMA TITOLO ARTICOLO

.blocco-firme {
	float: right;
	margin-top: -15px;
	@media #{$mobile} {
		float: none;
		margin-top: 5px;
	}
	// a:before{
	//
	// 	content: "";
	// position: absolute;
	// width: 100%;
	// height: 1px;
	// bottom: 0;
	// left: 0;
	// background-color: #ffffff;
	// visibility: hidden;
	// -webkit-transform: scaleX(0);
	// transform: scaleX(0);
	// -webkit-transition: all 0.3s ease-in-out 0s;
	// transition: all 0.3s ease-in-out 0s;
	// }
	//
	// a:hover:before{
	// 	visibility: visible;
	// -webkit-transform: scaleX(1);
	// transform: scaleX(1);
	// }
	a {
		color: #ffffff;
		position: relative;
	}

	a:hover {
		text-decoration: underline;
	}

	.firma-articolo {
		font-style: italic;
		// color: #000000!important;
	}

	.crediti-fotografo {
		font-style: italic;
	}
}

.opacity0 {
	opacity: 0;
	// display: block;

}

.opacity1 {
	// opacity: 1 !important;
	// visibility: visible !important;
	// display: block!important;
	// height: auto!important;
}

.intro-content-grey {
	// visibility: hidden;
	transition: visibility 0s, opacity 0.5s linear;
	-webkit-transition: opacity 1;
	-moz-transition: opacity 1s;
	transition: opacity 1s;

	// opacity: 0;
	// display: none;
	z-index: 10000;
	margin: 0px auto 30px;

	// max-width: 740px;
	// height: 165px;
	// padding-left: 20px;
	// padding-right: 20px;

	// indicazioni prese da ediotr-normal
	max-width: 740px;
	padding-left: 20px;
	padding-right: 20px;
	@media (min-width: $screen-sm-min) {
		width: $container-sm;
		                  //  padding-right: 60px;
		//                    padding-left: 210px;
	}
	@media (min-width: $screen-md-min) {
		width: $container-md;
		//                    padding-right: 100px;
		//                    padding-left: 260px;
		//                              padding-left: 27vw;
		//                              padding-right: 8vw;
	}
	@media (min-width: $screen-lg-min) {
		//                    width: 800px;
		//                    width: $container-lg;
		//                    padding-left: 100px;
		//                    padding-right: 160px;
		//                              padding-left: 20vw;
		//                              padding-right: 7vw;
		//                    padding-right: 120px;
		//                    padding-left: 310px;
	}




	h1 {
		  color: $titlecolor;
	}

	.blocco-firme a{color: $link-color;}

	// @include transform(translateY(-50%));

	.intro-title {
		padding: 50px 0 0;
		text-shadow: none;
		font-size: 2.3em;
	}


	@media #{$md-screen} {
		// margin: 75px auto 30px;
		margin: 0px auto 30px;
	}
	@media #{$sm-screen} {
		// margin: 80px auto 0;
		margin: 0px auto 30px;
	}
	@media #{$mobile} {
		// margin: 75px 20px 0;
		margin: 0px 20px 30px;
		padding: 0;
	}
}

#title-collapse{

}

.padding150 {
	padding: 150px 0 0;
}
