
.custom-article_head {
    width: 100%;
    background-color: #000;
    height: 30vh;
    background: url(../img/sfondo-viaggio-orobie.jpg) no-repeat 50% 0;
    background-size: cover;



  img {margin-top: 20px; max-height: 25vh; width: auto; max-width: 100%; }


// img {max-width: 400px; margin-top: 40px; }

}

.custom-article_head_cordata {
    width: 100%;
    background-color: #000;
    height: 30vh;
    background: url(../img/sfondo-cordata.jpg) no-repeat 50% 0;
    background-size: cover;
    text-align: center;



  img {margin-top: 20px; max-height: 25vh; width: auto; max-width: 100%; }


// img {max-width: 400px; margin-top: 40px; }

}



.custom-article_head_millegradini {
    width: 100%;
    background-color: #000;
    height: 30vh;
    background: url(../img/sfondo-millegradini.jpg) no-repeat 50% 0;
    background-size: cover;
    text-align: center;



  img {margin-top: 20px; max-height: 25vh; width: auto; max-width: 100%; }


// img {max-width: 400px; margin-top: 40px; }

}



.intro-testo-cordata {margin-bottom: 0px; color: #4e97cb;font-weight: bold; font-size: 1.7em; font-family: "Calvert", serif; display: block;}

.custom-article_cordata {background: url(../img/sfondo-cordata.jpg) no-repeat 50% 0; text-align: center;

}

.cordata-live {margin: 20px 0 10px; font-size: 1.3em; color: #043756; text-align: left; }

.article-cordata {position: relative;
  .article-inner {margin-top: 25px; text-align: center; }
  .article-content {min-height: 170px; padding: 10px 15px 15px; }
  .testone { margin-top: 30px; font-size: 1.2em; a {font-size: 0.8em; } b {color: #4e97cb;}}
  .numero {margin-top: 15px; font-size: 2em; color: #000; font-weight: bold;}
  .hashtag {margin-top: 5px; color: #4e97cb;font-weight: bold; font-size: 1.6em;}
  .icon {top: 0; position: absolute; text-align: center; width: 100%; margin-right: 15px;}
  img {width: auto;}
}
@media #{$mobile} {
    .article-cordata { .article-content {min-height: 10px; padding-bottom: 20px;}

  } }
