//Feeds

.feeds { padding: 0 210px 0 30px; }
.feed { position: relative; border: 1px solid #ccc; border-radius: 3px; background: #fff; }
.feed:last-child {margin-bottom: 22px; }
.feed a { text-decoration: none; display: inline; }
.feed ~ .feed { margin-top: 22px; }
.feed-content { min-height: 103px; padding: 10px 114px 30px 20px; font-size: 18px; color: #777; }
.feed-content span { color: #015fa5; text-decoration: none; font-weight: bold; }
.feed-content p { margin: 0 0 7px; }
.feed-content h4 { color: #333; }

.feed-meta { position: absolute; font-size: 14px; margin-top: 5px;}
.feed-image { position: absolute; top: 0px; right: 0px; border-radius: 0 3px 3px 0; background-size: cover;
              background-color: #ccc; width: 100px; height: 100%; background-position: center;
img {width: 100px; display: none;}
}

.feed-icon { position: absolute; top: 50%; right: -18px; z-index: 8; width: 36px; height: 36px; margin-top: -18px; border-radius: 50%; text-align: center; line-height: 36px; background: #babbbd; color: #fff; font-size: 16px; }

@media #{$md-screen} {
	.feeds { padding: 0 210px 22px 0;}
	.feed-content { padding: 10px 97px 30px 10px;}
}

@media #{$sm-screen} {
	.feeds { padding: 0; }
	.feed-content { padding: 10px 107px 30px 20px; background-color: #f1f1f1; padding: 0 5px 0 0 ; margin-top: 0px;  }
}

@media #{$mobile} {
	.feed { background: none; border-width: 1px 0 1px 0; border-radius: 0; }
	.feed ~ .feed { margin-top: 44px; }
	.feed-content { font-size: 16px; line-height: 1.43; margin-top: 10px; padding: 10px 100px 10px 0px;}
	.feed-image { position: absolute; width: 100px; float: left; height: 100%; }
	.feed-meta { font-size: 12px; }
	.feed-title { font-size: 18px; line-height: 1.18;}
	.feed-icon { right: auto; left: 10px; top: -18px; margin: 0; }
}

@media #{$mobile-sm} {
	.feed-content { padding: 10px 100px 10px 0px; }
}

.feeds-notifiche { padding: 50px;   }
