//BARRA BASE
.bar-album-hidden {
          opacity: 0;

          -webkit-transition: opacity 0.6s;
          -moz-transition: opacity 0.6s;
          transition: opacity 0.6s;

}

.bar-album-visible {
          opacity: 100;

          -webkit-transition: opacity 0.9s;
          -moz-transition: opacity 0.9s;
          transition: opacity 0.9s;
}

.nav-album-bar {

          @extend .bar;
          padding-top: 0px;
          //          height: 51px;
          box-shadow: none !important;
          border-bottom: 1px solid $txtchiarocolor;

          .titoloalbumbar {

                    h1.titolo-album {
                              font-size: 15px;
                              color: $txtcolor;
                              text-align: center;
                              line-height: 55px;
                              margin: 0px;
                              padding: 0px;
                              text-transform: uppercase;
                    }
          }
}

// FINE BARRA BASE

.album-cover {
          @extend .profile-cover;
          height: 60vh;
          position: relative;
          background-position: center center;
          position: relative;
          //       margin-bottom: 15px;
          img {
                    visibility: visible !important;
                    display: block !important;
          }
          @media #{$mobile}{
                    //                    height: 180px!important;
          }
}

.album-meta {

          @extend .gallery-meta;

          margin-left: 0px;
          position: absolute;

          width: 100%;
          bottom: 30px;
          .container {
                    margin: auto;
          }

}

.gradientback {

          //       position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          //       opacity: 0;
          //          pointer-events: none;
          padding-top: 20px;
          min-height: 60vh;
          background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.0)));
          background: -webkit-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.0) 100%);
          background: -moz-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 0.0) 100%);
          background: linear-gradient(to bottom, transparent 20%, rgba(0, 0, 0, 0.35) 100%);
          -webkit-transition: opacity 0.8s;
          -moz-transition: opacity 0.8s;
          transition: opacity 0.8s;
          z-index: 60000;

          //          &:hover{
          //                    background: none;
          //          }

}

//div.gradientback:hover {
//          background: none;
//           transition: opacity 0.8s;
//
//}

.mod-image {
          //                              padding: 10px 10px 5px;
          background: #ffffff;
          //                              opacity: 0.5;
          color: #000;
          font-weight: 400;
          margin-bottom: 10px;
          //            margin-right: 0px;
          //                              color: #000;
          ////                              font-size: 2rem;
          //                              font-weight: 100;
          ////                              text-align: center;
          //                              cursor: pointer;
          //                              border-radius: 5px;
          //                            padding-top: 7px;
          //                              width: 200px;
          //                              margin: 320px auto;
          //                              position:absolute;
          //                              right: 15px;

}

.album-credits {
          text-align: center;
          line-height: 21px;
          font-size: 1.0em;
          font-weight: 300;
          margin-bottom: 20px;
          i, dt, dd, dl {
                    color: $txtcolor;
          }
}

.info-album {
          background-color: #ffffff;

          h1 {
                    font-size: 2.7em;
                    color: $titlecolor;
                    font-weight: 800;
                    text-align: center;
                    line-height: 1.3em;
                    font-family: $fontCalvert;
                    margin-bottom: 20px;

                    @media #{$mobile}{
                              font-size: 1.7em;
                              line-height: 1.3em;
                    }
          }

          .container {
                    margin: 0px auto;
          }

}

.descrizione-album {
          font-size: 18px;
          margin: 0 0 30px 0;
          font-weight: 200;
          line-height: 1.35em;

          a {
                    text-decoration: underline;
                    color: #0061a1;
          }

          //          p:first-child:first-letter {
          //
          //                    float: left;
          ////                 color: #903;
          //                    font-size: 56px;
          //                    letter-spacing: -5px;
          //                    line-height: 38px;
          //                    font-weight: 700;
          //                    padding-top: 4px;
          //                    padding-right: 8px;
          //                    padding-left: 0px;
          ////                 font-family: Georgia;
          //
          //          }
}

.cont-descr {
          background-color: #fff;
          width: 100%;
          h1 {
                    color: #333;
          }
}

.add-image {
          font-size: 55px;
          text-align: center;
          bottom: -37px;
          position: relative;
          a i {
                    color: rgb(0, 97, 161);
          }
          p {
                    line-height: 55px;
          }
}

.form-group {
          h6 {
                    color: rgb(0, 97, 161);
          }
}

.modal-content {
          border-radius: 1px;
          border: 15px solid rgb(0, 97, 161);
          border-bottom: 0px;
          border-left: 0px;
          border-right: 0px;
          line-height: 1em;
          input {height: 40px !important;}
}

.h40 {
          height: 40px;
}

.hashtags {
          font-weight: bold;
          margin-left: 15px;
          line-height: 30px;
          a {
                    color: #333;
          }
}

.social-iconz {
          margin-left: 15px;
          a i {
                    color: #666;
                    font-size: 20px;
                    line-height: 30px;
          }
}

.avatar-nav {
          //          display: table;
          //          line-height: 29px;
          padding-top: 12px;
          color: $txtcolor;
          font-size: 14px;
          font-weight: 400;

          a.avatarlink {
                    display: block;
                    margin-bottom: 10px;
                    padding-bottom: 0px;

          }

          a {
                    color: $txtcolor;
                    cursor: pointer;
                    display: block;

          }

          // padding-bottom: 15px;
          img {
                    //                    float: left;
                    margin-right: 5px;
                    border-radius: 5px;

          }
}

.sfondobianco {
          background-color: #fff;
          padding-bottom: 15px;
          margin-top: 30px;
}

.sfondogrigio {
          background-color: $backgroundgrigio;
          padding-top: 15px;
}

.album {
          margin: 40px 0px;
}

.album-inner {
          position: relative;
          border-radius: 7px;
          overflow: hidden;
}

.album-image a {
          display: block;
          overflow: hidden;
          height: 218px;
          background: #fff;
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          img {
                    display: none;
          }
}

.album-image a:hover {
          opacity: .8;
}

.album-title {

}

.album-title a:hover {
          color: $brand-info;
}

.album-content {
          position: relative;
          padding: 15px;
          background: #fff;
          color: #CCC;
          min-height: 130px;
          margin-bottom: 20px;
          max-height: 130px;

          h4 {
                    margin: 0;
                    padding-bottom: 5px;
                    text-transform: uppercase;
                    font-size: 1.7rem;
          }

          i {
                    margin-bottom: 5px;
                    color: #ccc;
          }
          span {
                    margin-right: 20px;
                    font-weight: lighter;
                    font-size: 1.4rem;
          }
}

.album-icon {
          position: absolute;
          top: 200px;
          left: 45%;
          background-color: #015fa5;
          width: 42px;
          height: 42px;
          padding: 10px;
          -webkit-border-radius: 50px;
          -moz-border-radius: 50px;
          border-radius: 50px;
          z-index: 4;
          i {
                    text-align: center;
                    color: #FFF;
                    font-size: 2rem;
          }
}

.infinite-loading {
          margin-bottom: 15px;
          text-align: center;
          background-image: url('/css/images/loading_35.gif');
          background-position: center;
          background-repeat: no-repeat;
}

.tags-end {
          text-align: center;
          font-size: 1.6rem;
          clear: both;
}

.center-text {
          text-align: center;
          width: 100%;
          position: absolute;
          bottom: 20px;
}

.drag-drop-modal {
          padding: 15px;
}

div.allow-fullsize {
          display: none;
}

.div-center-text {
          text-align: center;
}

.a.block {
          display: block
}

//LISTA TAGS ALBUM - DRUGO

.album-tags {

          margin: 0px 0px 30px 0px;
          padding: 0px;
          li {
                    display: inline-block;

                    a {
                              display: block;
                              margin-right: 5px;
                              font-weight: 200;
                              color: $objgrigio;
                              &:hover {

                                        color: $brand-primary;
                              }
                    }
          }

}

.upload-album {

          padding: 30px;
          .container {
                    margin: 0 auto;
          }

}

.flex-images.gallery-items.infinite-container.row.album {
          overflow: visible !important
}


#modalCaricaImmaginiAlbum{

.modal-dialog{

	width: 95%!important;
	// height: 90vh!important;

}

.sfondogrigio, .drag-drop-modal, form{
	// height: 90vh!important;
}

.file-drop-zone {min-height: 80vh!important;}


}
