@font-face {
    font-family: 'orobieIcons';
    src:    url('fonts/orobieIcons.eot?k5ttif');
    src:    url('fonts/orobieIcons.eot?k5ttif#iefix') format('embedded-opentype'),
        url('fonts/orobieIcons.ttf?k5ttif') format('truetype'),
        url('fonts/orobieIcons.woff?k5ttif') format('woff'),
        url('fonts/orobieIcons.svg?k5ttif#orobieIcons') format('svg');
    font-weight: normal;
    font-style: normal;
}


[class*="ico-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'orobieIcons' !important;
    display: inline-block;
    vertical-align: middle;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ico-facebook3 .path1:before {
    content: "\e900";
    color: rgb(0, 130, 221);
}
.ico-facebook3 { position: relative; }
.ico-facebook3 .path2 { position: absolute; top: 0; left: 0; }
.ico-facebook3 .path2:before {
    content: "\e901";
    color: rgb(255, 255, 255);
}
.ico-google .path1:before {
    content: "\e902";
    color: rgb(237, 93, 71);
}

.ico-google { position: relative; }
.ico-google .path2 { position: absolute; top: 0; left: 0; }
.ico-google .path2:before {
    content: "\e903";
    color: rgb(255, 255, 255);
}
.ico-pinterest .path1:before {
    content: "\e904";
    color: rgb(209, 31, 6);
}
.ico-pinterest { position: relative; }
.ico-pinterest .path2 { position: absolute; top: 0; left: 0; }
.ico-pinterest .path2:before {
    content: "\e905";
    color: rgb(255, 255, 255);
}

.ico-t .path1:before {
    content: "\e906";
    color: rgb(61, 110, 157);
}
.ico-t { position: relative; }
.ico-t .path2 { position: absolute; top: 0; left: 0; }
.ico-t .path2:before {
    content: "\e907";
    color: rgb(255, 255, 255);
}
.ico-twitter3 .path1:before {
    content: "\e908";
    color: rgb(0, 197, 249);
}
.ico-twitter3 { position: relative; }
.ico-twitter3 .path2 { position: absolute; top: 0; left: 0; }
.ico-twitter3 .path2:before {
    content: "\e909";
    color: rgb(255, 255, 255);
}
.ico-facebook2:before {
    content: "\e90a";
}
.ico-instagram:before {
    content: "\e90b";
}
.ico-pinterest2:before {
    content: "\e90c";
}
.ico-t2:before {
    content: "\e90d";
}
.ico-twitter2:before {
    content: "\e90e";
}
.ico-youtube:before {
    content: "\e90f";
}
.ico-arrowleft:before {
    content: "\e910";
}
.ico-arrowright:before {
    content: "\e911";
}
.ico-bell:before {
    content: "\e912";
}
.ico-bubbles:before {
    content: "\e913";
}
.ico-camera:before {
    content: "\e914";
}
.ico-camera2:before {
    content: "\e915";
}
.ico-camera3 { position: relative; }
.ico-camera3 .path1:before {
    content: "\e916";
    color: rgb(21, 99, 158);
}
.ico-camera3 .path2 { position: absolute; top: 0; left: 0; }
.ico-camera3 .path2:before {
    content: "\e917";
    color: rgb(255, 255, 255);
}
.ico-clock:before {
    content: "\e918";
}
.ico-e { position: relative; }
.ico-e .path1:before {
    content: "\e919";
    color: #ffb100;
}
.ico-e .path2:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "\e91a";
    color: rgb(255, 255, 255);
}
.ico-edit { position: relative; }
.ico-edit .path1:before {
    content: "\e91b";
    color: rgb(20, 174, 156);
}
.ico-edit .path2:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "\e91c";
    color: rgb(255, 255, 255);
}
.ico-edit .path3:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "\e91d";
    color: rgb(255, 255, 255);
}
.ico-eye:before {
    content: "\e91e";
}
.ico-facebook:before {
    content: "\e91f";
}
.ico-file:before {
    content: "\e920";
}
.ico-files .path1:before {
    content: "\e921";
    color: rgb(170, 170, 170);
}
.ico-files .path2:before {
    content: "\e922";
    margin-left: -0.9501953125em;
    color: rgb(170, 170, 170);
}
.ico-files .path3:before {
    content: "\e923";
    margin-left: -0.9501953125em;
    color: rgb(170, 170, 170);
}
.ico-files .path4:before {
    content: "\e924";
    margin-left: -0.9501953125em;
    color: rgb(255, 255, 255);
}
.ico-files .path5:before {
    content: "\e925";
    margin-left: -0.9501953125em;
    color: rgb(170, 170, 170);
}
.ico-files .path6:before {
    content: "\e926";
    margin-left: -0.9501953125em;
    color: rgb(255, 255, 255);
}
.ico-heart:before {
    content: "\e927";
}
.ico-heart2 .path1:before {
    content: "\e928";
    color: rgb(146, 146, 146);
}
.ico-heart2 .path2:before {
    content: "\e929";
    margin-left: -1.0703125em;
    color: rgb(221, 221, 221);
}
.ico-heart3:before {
    content: "\e92a";
}
.ico-heart4 { position: relative; }
.ico-heart4 .path1:before {
    content: "\e92b";
}
.ico-heart4 .path2:before {
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    content: "\e92c";
}
.ico-heart5:before {
    content: "\e92d";
}
.ico-heart6:before {
    content: "\e92e";
}
.ico-link:before {
    content: "\e92f";
}
.ico-image:before {
    content: "\e930";
}
.ico-location:before {
    content: "\e931";
}
.ico-mail:before {
    content: "\e932";
}
.ico-compass:before {
    content: "\e933";
}
.ico-people:before {
    content: "\e934";
}
.ico-add:before {
    content: "\e935";
}
.ico-search:before {
    content: "\e936";
}
.ico-share:before {
    content: "\e937";
}
.ico-speech1:before {
    content: "\e938";
}
.ico-twitter:before {
    content: "\e939";
}
.ico-zoom:before {
    content: "\e93a";
}

