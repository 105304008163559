//Navigations

//Nav-secondary
.nav-secondary { overflow: hidden; overflow: visible; }
.nav-secondary ul { margin: 0; padding: 0; list-style: none outside none; }
.nav-secondary li { float: left; }
.nav-secondary li:after { content: ''; display: inline-block; vertical-align: middle; width: 1px; height: 33px; margin: -3px 9px 0 5px; background: #6e8798; }
.nav-secondary li:last-child:after { opacity: 0; }

//Dropdown
.nav-secondary li.dropdown a{ cursor: pointer;}
.nav-secondary li.dropdown .dropdown-menu{ top:45px; right: 50px;}
.nav-secondary li.dropdown .dropdown-menu li{float: none;}
.nav-secondary li.dropdown .dropdown-menu li::after{display: block; float: none; content: none; }

.nav-secondary a { color: #fff; text-transform: uppercase; text-decoration: none; @include transition(color .3s); }

.nav-secondary .current a,
.nav-secondary a:hover { color: $brand-success; }

.nav-secondary i { font-size: 20px; margin-top: -4px; }
.nav-secondary .ico-compass { margin-left: 3px; }
.nav-secondary .ico-add { margin-right: 3px; }

//Nav-user
.nav-user ul { padding: 0; list-style: none outside none; }
.nav-user > ul > li { display: inline-block; vertical-align: middle; }
.nav-user > ul > li:last-child { border-left: 1px solid #97c1e0; margin-left: 11px; cursor: pointer; }
.nav-user > ul > li.optionsolo { border-left: none;  }
//.nav-user > ul > li + li { padding-left: 11px; }
.nav-user > ul > li > a, .nav-user > ul > li > .dropdown > a  { position: relative; color: #fff; text-decoration: none; }
.nav-user > ul > li > button { display: inline-block; vertical-align: middle; background: none; border: none; color: #fff; padding: 0; }
.nav-user button strong { font-weight: normal; text-transform: capitalize;}
.nav-user > ul > li > a > span, .nav-user > ul > li > .dropdown > a > span { position: absolute; top: -6px; right: -10px; width: 18px; height: 18px; border: 2px solid #fff; border-radius: 50%; font-size: 12px; text-align: center; line-height: 14px; background: $brand-danger; }
.nav-user > ul > li > a > i, .nav-user > ul > li > .dropdown > a >i { color: #cbe1f0; font-size: 18px; @include transition(color .3s); }
.nav-user .avatar { display: inline-block; vertical-align: middle; margin-left: 6px; }

.nav-user > ul > li > a:hover > i { color: #fff; }

//Nav
.nav ul { list-style: none outside none; overflow: hidden; margin: 0; padding: 0; }
.nav li { float: left; }
.nav li + li { padding-left: 18px; }
.nav a { display: block; padding-bottom: 14px; border-bottom: 2px solid transparent; font-size: 18px; color: $titlecolor; text-transform: uppercase; text-decoration: none; @include transition(border-color .3s); }

.nav .current a { font-weight: bold; }

.nav a:hover,
.nav .current a { border-color: $brand-primary;  color:$brand-primary; }

.nav-alt a { color: $brand-info; }

.nav-alt a:hover,
.nav-alt .current a { border-color: $brand-info; }

//Nav tertiary
.nav-tertiary ul { list-style: none outside none; margin: 0; padding: 0; }
.nav-tertiary ul:after { content: ''; display: inline-block; vertical-align: middle; width: 1px; height: 25px; margin: -14px 15px 0 10px; background: #ccc; }
.nav-tertiary li { display: inline-block; vertical-align: middle; }
.nav-tertiary li + li { margin-left: 15px; }
.nav-tertiary a { display: block; padding-bottom: 14px; border-bottom: 2px solid transparent; text-transform: uppercase; font-size: 18px; color: $brand-success; text-decoration: none; @include transition(border-color .3s); }

.nav-tertiary .current a,
.nav-tertiary a:hover { border-color: $brand-success; }

//Nav-quaternary

.nav-quaternary ul { list-style: none outside none; margin: 0; padding: 0; }
.nav-quaternary li { display: inline-block; vertical-align: middle; margin: 0 8px; }
.nav-quaternary a { text-decoration: none; color: #777; }

.nav-quaternary .current a,
.nav-quaternary a:hover { color: $brand-info; }

.nav-quaternary .current a { font-weight: bold; }

.nav-mobile { display: none; }

@media #{$md-screen} {
	.nav li + li { padding-left: 13px; }
	.nav-alt li + li { padding-left: 19px; }

	.nav-tertiary ul:after { opacity: 0; margin-right: 9px; }
	.nav-tertiary li + li { margin-left: 11px; }
}

@media #{$sm-screen} {
	.nav-user { position: fixed; top: 0; right: 0; }
	.nav-user > ul > li:last-child { border: none; margin: 0; padding: 0; }

	.nav-user button { position: relative; width: 39px; height: 32px; @include transition(width .4s ease); }
	.nav-user button em { font-style: normal; margin: 0 5px 0 14px; }
	.nav-user button strong { font-weight: bold; }

	.nav-user button em,
	.nav-user button strong { position: absolute; opacity: 0; visibility: hidden; float: left; margin-top: 3px; font-size: 22px; line-height: 30px; }

	.nav-user button .avatar { position: absolute; top: 0; right: 0; overflow: visible; }
	.nav-user button .avatar img { border-radius: 3px; }
	.nav-user button .avatar span { position: absolute; top: -1px; right: -9px; z-index: 3; width: 18px; height: 18px; border: 2px solid #fff; border-radius: 50%; font-size: 12px; text-align: center; line-height: 14px; background: $brand-danger; @include transition(opacity .4s ease); }

	.nav-user .dropdown-menu { display: none !important; }

	.nav-trigger { position: relative; width: 32px; height: 22px; }

	.nav-trigger span { position: absolute; display: block; height: 3px; width: 100%; background: #fff; @include transition(top .2s .25s, left .2s .25s, opacity .2s .25s, transform .2s 0s); }

	.nav-trigger span:nth-child(1) { top: 0; }
	.nav-trigger span:nth-child(2) { top: 10px; }
	.nav-trigger span:nth-child(3) { top: 19px; }

	.nav-trigger.active span { @include transition(background .2s, top .2s, left .2s, opacity .2s, transform .2s .25s) }

	.nav-trigger.active span:nth-child(3),
	.nav-trigger.active span:nth-child(1) { top: 10px; }

	.nav-trigger.active span:nth-child(2) { opacity: 0; }
	.nav-trigger.active span:nth-child(1) { @include rotate(45); }
	.nav-trigger.active span:nth-child(3) { @include rotate(-45); }

	.nav-mobile { position: fixed; top: 0; left: -300px;
		 z-index: 10;
		display: block; width: 300px; height: 100%; padding-bottom: 15px; background: $brand-primary; overflow: auto; @include transition(left .1s ease); }
	.nav-mobile-visible { left: 0; }

	.nav-mobile .nav-head { height: 50px; padding: 9px 29px 0; background: #002c49; }

	.nav-mobile > ul { list-style: none outside none; padding: 0; margin: 0;}
	.nav-mobile > ul > li { overflow: hidden;}
	.nav-mobile > ul > li + li { border-top: 1px solid #004273; }
	.nav-mobile > ul > li > a { display: block; padding: 23px 27px 23px; border-left: 4px solid transparent; color: #fff; text-decoration: none; text-transform: uppercase; font-size: 22px; }
	.nav-mobile > ul > li > .link-alt { color: $brand-success; }
	.nav-mobile > ul > li > .link-search { padding: 0; float: left; font-size: 28px; margin: 16px 0 0 26px; }
	.nav-mobile > ul > li > .socials { float: right; margin: 20px 29px 0 0; }
	.nav-mobile > ul > li > .socials a { color: #fff; font-size: 24px; }

	.nav-mobile > ul > li > .socials .ico-twitter,
	.nav-mobile > ul > li > .socials .ico-youtube { font-size: 18px; }

	.nav-mobile > ul > .link-success { border: none; }
	.nav-mobile > ul > .link-success a { background: $brand-success; color: #fff; }

	.nav-mobile > ul > .link-info a { background: $brand-info; color: #fff; }

	.nav-mobile > ul > .link-dark a { background: #2c81c4; }

	.nav-mobile > ul > .link-success i,
	.nav-mobile > ul > .link-info i { float: right; position: relative; color: #FFF; font-size: 30px; }

	.nav-mobile > ul > .current > a { border-color: #fff; }

	.nav-mobile-secondary {position: fixed; z-index: 10; left: auto; right: -300px; padding-top: 50px; background: #55a5e2; @include transition(right .4s ease); }
	.nav-mobile-secondary.nav-mobile-visible { left: auto; right: 0; }

	.nav-mobile-secondary > ul > li:last-child { border-bottom: 1px solid #4b91cd; }
	.nav-mobile-secondary > ul > li + li { border-color: #4b91cd; }
	.nav-mobile-secondary > ul > li > a { text-transform: none; }
	.nav-mobile-secondary > ul > li > a > i { float: right; position: relative; color: #FFF; font-size: 18px; margin: 4px 2px 0 0; }
	.nav-mobile-secondary > ul > li > a > i span { position: absolute; top: -7px; right: -8px; z-index: 3; width: 18px; height: 18px; border: 2px solid #fff; border-radius: 50%; font-size: 12px; text-align: center; line-height: 14px; background: $brand-danger; color: #fff; }
	.nav-mobile-secondary > ul > li > a > .ico-add { font-size: 30px; color: #fff; margin: -3px -3px 0 0; }
	.nav-mobile-secondary > ul > li > a > .ico-bell { margin-right: 4px; }
	.nav-mobile-secondary > ul > .current a { border-color: transparent; background: #015ea3; }
}

 .nav-mobile-sub > ul > a {list-style: none; padding: 5px; margin: 0px; color: #FFF; }
 .nav-mobile-sub {list-style: none; color: #FFF; }

@media #{$mobile} {
	.nav-quaternary { font-size: 16px; white-space: nowrap; }
          .nav-mobile-secondary{padding-top: 0px!important; margin-top: 50px!important; padding-bottom:100px;}
}

@media #{$mobile-sm} {
	.nav-mobile { width: 250px; left: -250px; }
	.nav-mobile-visible { left: 0; }

	.nav-mobile-secondary { left: auto; right: -250px; }
	.nav-mobile-secondary.nav-mobile-visible { left: auto; right: 0;  }
}

.nav-user strong {margin-left: 10px; line-height: 30px;}
.menuvert { ul {list-style: none; margin-left: -20px;}
	li {font-size: 1.2rem; color: #FFF; text-transform: uppercase; line-height: 0.5em;
		  display: block; padding: 13px 0px; list-style: none; background-color: #004273;
			border-bottom: 1px solid #427aaa; }

	li:last-child {border-bottom: 0px solid #427aaa;}
 }
.menuaccount {li {background-color: #FFF ; padding: 5px 0px; }}

 .link-info {
							div {padding: 23px 27px 23px; border-left: 4px solid transparent; border-top: 1px solid #428bca; border-bottom: 1px solid #428bca;
							     a { color: #FFF; font-size: 22px; }
						       }
							div > a > i { float: right; position: relative; color: #FFF; font-size: 30px;  }

						 }
 ul { list-style: none;}
.menu-dx-sub { li {border-bottom: 1px solid #0061a1;  background-color: #004273;  text-transform: uppercase; margin-left: -40px;
                   a {color: #FFF;padding: 23px 0px 23px 30px;  display: block; }
									 i {margin-right: 10px; color: #428bca }
								  }
							li:last-child {border-bottom: 1px solid transparent; a {color: #428bca} }
 }

 .menu-sx-sub{ li {border-bottom: 1px solid #0061a1;  background-color: #004273;  text-transform: uppercase; margin-left: -40px;
                    a {color: #FFF;padding: 23px 0px 23px 30px;  display: block; }
 									 i {margin-right: 10px; color: #428bca }
									 span {background-color: #428bca; padding: 3px 9px; margin-left: 12px; font-weight: bold; border-radius: 4px;}
 								  }
 							 }


.linkviaggioorobie{

	color: #85a21c!important;
	font-weight: 800;

	&:hover{color: #85a21c!important; border-color:#85a21c!important;}

}
