body {
          margin-top: 0px;
          margin-bottom: 0px;
          background: none;
}

.registrationbackground {
          background: url("images/registrazione/bg-login_3.jpg") no-repeat center center fixed;
          -webkit-background-size: cover;
          -moz-background-size: cover;
          -o-background-size: cover;
          background-size: cover;
}

.pagina404background {
          background: #002b4b url("images/bg-404.jpg") no-repeat center center fixed;
          -webkit-background-size: cover;
          -moz-background-size: cover;
          -o-background-size: cover;
          background-size: cover;
          background-color:rgba(0,44,74,0.7);
}






.wrapper-registration {

          //          background-image: url("../../css/images/temp/intro-02.jpg");
          //          background-position: center center;
          //          background-repeat: no-repeat;
          //          background-size: cover;
          //          display: block;
          //          height: 100vh;
          //          margin-bottom: 40px;

}

.container-registration {

          .logocol {
                    margin-top: 20px;
                    margin-bottom: 20px;
                    padding-left: 30px;
          }

          .modulo {
                    background-image: url("../../css/images/registrazione/bkg-modulo-form.png");
                    padding: 5px 20px 20px 20px;
                    height: 560px;

                    @media (max-width: $screen-xs){ height: 610px;  }
                    @media (min-width: $screen-md){ height: 560px;  }
                    //                    opacity: 0.9;
          }

          .modulo-adv {
                    background-image: url("../../css/images/registrazione/bkg-modulo-adv.png");
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    color: #ffffff;
                    height: 560px;
                    position: relative;

          }

          h3 {
                    font-family: $fontCalvert;
                    font-weight: bold;
                    line-height: 1;
                    color: #3b5c7d;
          }

          .login-alternative {

                    border-top: 1px solid #d2d2d2;
                    margin: 10px 0px 0px 0px;
                    padding: 0px 15px;

                    a {
                            text-decoration: none;
                    }

          }

          .btn-modificato {

                    color: #fff;
                    border: none;
                    margin: 10px 0px;
                    height: 40px;
                    font-weight: 200;
                    line-height: 1.3em;
                    color: #fff;
          }
          .btn-facebook {
                    @extend .btn-modificato;
                    background-color: #3b5998;
                    color: #FFF !important;
                    &:hover {
                              background-color: #263a64;

                    }
          }
          .btn-eco {
                    @extend .btn-modificato;
                    background-color: #9b005e;
                    color: #FFF !important;
                    &:hover {
                              background-color: #5f013a;

                    }

          }
          a > .btn-facebook {
                    color: #fff;
                    text-decoration: none;
                    font-weight: 200;
          }

          .termini-condizioni {
                    font-size: 1.3rem;
                    font-weight: lighter;
                    line-height: 1.6rem;

                    a {cursor: pointer; }
          }

          .check-condizioni {
            font-size: 1.3rem;
            font-weight: lighter;
            line-height: 1rem;
            a     {cursor: pointer; }
            label {
                    font-size: 1.3rem;
                    font-weight: lighter;
                    line-height: 1.6rem;
                  }
                }

          .quote-registrazione {
                    font-weight: lighter;
                    font-size: 1.4em;
                    text-align: center;
                    padding: 20px 20%;
          }

          .quote-fineregistrazione {

                    font-size: 1.9em;
                    text-align: center ;
                    display: block;
                    padding-top: 60px;
                    vertical-align: middle;



          }

          .vertical{display: inline-block;
                     vertical-align: middle;
                     float: none;}
          .img-decoration {
                    display: block;
                    position: absolute;
                    bottom: 0px;
          }

          .step {
                    text-align: right;
                    ul.steplist {
                              float: right;
                              margin-top: 20px;
                              padding-right: 20px;
                              width: 100%;

                              li {
                                        display: inline;
                                        color: #fff;
                                        font-weight: 600;
                                        text-align: center;
                                        position: relative;
                                        padding-bottom: 10px;
                                        border-bottom: 1px solid #09def7;

                                        //                                        &:before{
                                        //                                                  content: "e";
                                        ////                                                  position: absolute;
                                        //                                                  margin:20px auto 0px auto;
                                        //                                        }
                                        a {
                                                  margin-right: 10px;
                                                  margin-left: 10px;
                                                  color: #fff;
                                                  text-decoration: none;
                                                  font-weight: 300;
                                                  text-align: center;
                                        }

                                        .iconstep {
                                                  color: #09def7;
                                                  position: absolute;
                                                  bottom: -10px;
                                                  left: 50%;
                                        }
                              }

                              li.select {

                                        a {
                                                  color: #ffff00;
                                        }
                                        .iconstep {
                                                  color: #ffff00;
                                        }
                                        border-bottom: 1px solid #ffff00;

                              }

                    }

          }

}


.bg-staticlogin2 { background-color: #000; position: relative; overflow: hidden; min-height: 600px; background-size: cover;
}

.staticlogin2 {background-image : url('../../css/images/bg-trans.png'); background-repeat: repeat; width:100%; height:100%; min-height: 650px; }

.staticlogin-cont {margin-top: 100px;
    width: 850px;
    position: relative;
    margin: 30px auto auto auto; }

@media #{$mobile} {

.staticlogin-cont {margin-top: 20px; width:100%;  left:3%; }

.container-registrazione { h3 {font-size: 23px;}
                           .modulo-adv {height: 380px;}
}




}

@media #{$sm-screen}  { .staticlogin-cont {margin-top: 20px; width:100%; left:3%; } }

.has-error {font-size: 1.3rem; font-weight: lighter;}

.no-padding {padding-right: 0px !important; padding-left: 0px !important;}

.recuperopassw { p {text-align: left;}
                 b {font-size: 1.8rem; line-height: 3rem;}
}
