
.alert-cookies {

          //          display: block;
          //       position: absolute;
          position: fixed;
          bottom: 20px;
          z-index: 5000;

          width: 430px;
          background-color: #4e97cb;
          padding: 15px;
          right: 20px;
          color: #ffffff;
          font-size: 13px;
          line-height: 1.3em;
          letter-spacing: 1px;
          border-radius: 6px;

          -webkit-box-shadow: 0px 0px 24px 4px rgba(0, 0, 0, 0.25);
          -moz-box-shadow: 0px 0px 24px 4px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 0px 24px 4px rgba(0, 0, 0, 0.25);


          @media #{$mobile}{

                    width: 100%;
                    bottom: 0px;
                    right: 0px;

                    button.accept{
                              width: 100%;
                              height: 40px;
                              margin-top:15px;

                    }

          }


          .title-cookies {

                    font-size: 16px;
                    color: #ffffff;
                    font-weight: 600;
                    margin-bottom: 10px;
                    display: block;

          }

          .contentalert {

                    .linkinfo {
                              color: #ffffff !important;
                              font-style: italic;
                              font-size: 10px;
                              display: block;
                              text-decoration: underline;
                    }

          }

          button.accept{


                    float: right;
                    margin-top: 5px;
                    padding: 5px 15px;
                    background-color: #fbba00;
                    border: none;
                    border-radius: 6px;
                    color: #000000;
                    font-weight: 400;

          }

}

.fadeInUp {
          -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
          -webkit-animation-duration: 1s;
          animation-duration: 1s;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

@-webkit-keyframes fadeInUp {
          0% {
                    opacity: 0;
                    -webkit-transform: translate3d(0, 100%, 0);
                    transform: translate3d(0, 100%, 0);
          }
          100% {
                    opacity: 1;
                    -webkit-transform: none;
                    transform: none;
          }
}

@keyframes fadeInUp {
          0% {
                    opacity: 0;
                    -webkit-transform: translate3d(0, 100%, 0);
                    transform: translate3d(0, 100%, 0);
          }
          100% {
                    opacity: 1;
                    -webkit-transform: none;
                    transform: none;
          }
}


    .fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  }
  @-webkit-keyframes fadeOutDown {
  0% {
  opacity: 1;
  }
  100% {
  opacity: 0;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  }
  }
  @keyframes fadeOutDown {
  0% {
  opacity: 1;
  }
  100% {
  opacity: 0;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  }
  }
