//Widgets
.widgets { list-style: none outside none; padding: 0;
	         // margin-top: 15px;

 }

//Stats
.widget-stats ul { list-style: none outside none; margin: 0; padding: 0; }
.widget-stats li { font-size: 27px; }
.widget-stats li + li { padding-top: 27px; }
.widget-stats strong { font-size: 47px; line-height: 1; display: inline-block; vertical-align: middle; padding-right: 10px; margin-top: -4px; }
.widget-stats i { min-width: 65px; }
.widget-stats .ico-image { font-size: 38px; }
.widget-stats .ico-people { font-size: 44px; }

@media #{$md-screen} {
	.widget-stats span { display: inline-block; vertical-align: middle; width: 140px; line-height: 1; }
}

@media #{$sm-screen} {
	.widget-stats { float: left; width: 66.666%; }
	.widget-stats li { float: left; width: 50%; position: relative; padding-top: 36px; font-size: 22px; }
	.widget-stats li + li { padding-top: 36px; }
	.widget-stats span { position: absolute; top: 0; left: 0; width: 100%; }
}

@media #{$mobile} {
	.widget-stats { width: 100%; padding-bottom: 26px; }
	.widget-stats ul { margin: 0 -10px; }
	.widget-stats li { padding: 36px 10px 0; }
	.widget-stats span { left: 10px; }
}

@media #{$mobile-sm} {
	.widget-stats span { font-size: 18px; }
	.widget-stats strong { font-size: 36px; }
	.widget-stats i { min-width: 50px; }
}

//Socials
.widget-socials { padding-top: 50px; }
.widget-socials .widget-title { font-family: $fontCalvert; font-weight: bold; margin: 0; padding-bottom: 18px; }
.widget-socials ul { list-style: none outside none; margin: 0 0 0 -48px; padding: 0; overflow: hidden; }
.widget-socials li { float: left; padding: 0 0 30px 48px; font-size: 0; line-height: 0; }
.widget-socials i { vertical-align: top; font-size: 90px; }
.widget-socials a { @include transition(opacity .3s); }
.widget-socials a:hover { opacity: .8; }

@media #{$md-screen} {
	.widget-socials ul { margin-left: -14px; }
	.widget-socials li { padding-left: 14px; }
}

@media #{$sm-screen} {
	.widget-socials { float: left; width: 33.333%; padding-top: 0; }
	.widget-socials ul { margin-left: -5px; }
	.widget-socials li { padding-left: 5px; padding-bottom: 0; }
	.widget-socials i { font-size: 38px; }
	.widget-socials .widget-title { font-size: 22px; font-family: $fontCalibri; font-weight: normal; padding-bottom: 0; margin-bottom: 10px; }

}

@media #{$mobile} {
	.widget-socials { width: 100%; }
}

//Widget-ad
.widget-ad { margin-top: -28px; }
.widget-ad img { max-width: 100%; height: auto; }
.widget-ad-sm { margin-top: 0; }

.widget-ad-alt img { width: 100%; height: auto; }

@media #{$sm-screen} {
	.widget-ad { padding-bottom: 30px; margin: 30px 0 0; }

	.widget-ad-alt img,
	.widget-ad img { width: 100%; height: 90px; }
}

//Widget about
.widget-about { position: relative; max-width: 300px; width: 100%; line-height: 1.4; }
.widget-about .widget-image img { width: 100%; height: auto; }
.widget-about .widget-inner { border-radius: 4px; overflow: hidden; }
.widget-about .widget-content { padding: 2px 0 13px; border: 1px solid #ccc; border-radius: 4px; margin-bottom: 16px; text-align: center; }
@media #{$mobile} { .widget-about .widget-content { padding: 0px; } }
.widget-about h6 { color: #333; font-family: $fontCalvert; font-weight: bold; }
.widget-about .widget-controls { position: relative; margin-top: 16px; }
.widget-about .widget-controls input { height: 35px; width: 100%; padding: 0 45px 0 10px; background: $backgroundgrigio; border: 1px solid #e5e5e5; color: #525252; }
.widget-about .widget-controls input:focus { outline: 0; }
.widget-about .widget-controls .ico-copy { position: absolute; top: 1px; right: 1px; bottom: 1px; width: 40px; background: #c7c7c7 url(images/files.svg); background-repeat: no-repeat; background-position: center center; background-size: 25px 25px; cursor: pointer; }

.widget-about .widget-ico { position: absolute; top: -15px; left: -15px; font-size: 35px; line-height: 0; }

@media #{$sm-screen} {
	.widget-about { max-width: 100%; }
	.widget-about .widget-image,
	.widget-about .widget-entry { display: none; }

	.widget-about h6 { float: left; margin: 8px 0 0 8px; font-size: 14px; }

	.widget-about .widget-content { overflow: hidden; margin: 15px 0 0 0; }
	.widget-about .widget-content-inner { float: right; }
	.widget-about .widget-content-inner p { float: left; margin: 5px 22px 0 0; }
	.widget-about .widget-actions { float: right; }
	.widget-about .widget-actions .btn { min-width: 110px; font-size: 13px; padding: 0 10px; border-width: 0 0 0 1px; border-radius: 0; border-left: 1px solid #ccc; height: 34px; line-height: 34px; }
	.widget-about .widget-ico { left: 10px; }

	.widget-about-alt h6 { margin-left: 50px; }
}

@media #{$mobile} {
	.widget-about h6 { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
	.widget-about-alt h6 { margin-left: 45px; }
	.widget-about .widget-content-inner p { display: none; }
}



//Widget map
.widget-map { margin-bottom: 60px; }
.widget-map .map { height: 250px; }
.widget-map .widget-image { border-radius: 5px; overflow: hidden; }
.widget-map .widget-actions { padding-bottom: 10px; }
.widget-map .widget-actions a { font-weight: bold; text-decoration: none; color: #969696; }

@media #{$sm-screen} {
	.widget-map { position: relative; }
	.widget-map .widget-image { height: 200px; }
	.widget-map .widget-image img { width: 100%; height: auto; }
	.widget-map .widget-actions { position: absolute; top: -16px; left: 15px; font-size: 0; line-height: 0; width: 33px; height: 33px; line-height: 33px; background: #ccc; text-align: center; border-radius: 50%; }
}

//widget-info
.widget-info { color: #777; }
.widget-info a { color: #0559aa; text-decoration: none; }
.widget-info a:hover { text-decoration: underline; }
.widget-info ul { list-style: none outside none; padding: 0; margin: 0; }
.widget-info li { padding-bottom: 10px; }
.widget-info li + li { padding-top: 12px; border-top: 1px solid #ddd; }
.widget-info span { display: block; padding-bottom: 5px; font-weight: bold; }
.widget-info strong { font-size: 20px; color: #333; }
.widget-info .link-download { display: inline-block; vertical-align: middle; text-align: center; margin-right: 15px; }
.widget-info .link-download span { font-weight: normal; }
.widget-info .link-download i { font-size: 35px; color: #b5cfe3; }
.widget-info .ico-e { font-size: 50px; }

@media #{$sm-screen} {
	.widget-info ul { overflow: hidden; }
	.widget-info li { min-height: 85px; width: calc(50% - 10px); padding-top: 12px; border-bottom: 1px solid #ddd; }
	.widget-info li + li { padding-top: 12px; border-top: none; }
	.widget-info li:nth-child(even) { float: right; }
	.widget-info li:last-child { border: none; }
	.widget-info li:nth-last-child(2):nth-child(odd) { border: none; }
}

@media #{$mobile} {
	.widget-info { font-size: 16px; }
	.widget-info strong { font-size: 24px; }

	.widget-info li { min-height: 80px; padding-top: 11px; padding-bottom: 0; }
	.widget-info li + li { padding-top: 11px; }
}

@media (max-width: 479px) {
	.widget-info li { width: 100%; }
}

//widget-share
.widget-share { text-align: center; }
.widget-share ul { list-style: none outside none; padding: 0; margin: 0; border: 1px solid #ccc; vertical-align: top; line-height: 0; margin-top: 13px; }
.widget-share li { overflow: hidden; font-size: 0; line-height: 0; }
.widget-share li + li { border-top: 1px solid #ccc; }
.widget-share ul a { font-size: 58px; line-height: 0; display: block; @include transition(background .3s, color .3s); }
.widget-share ul a:hover { background: #ccc; color: #fff; }
.widget-share a { color: #808080; }
.widget-share .ico-heart { font-size: 25px; }
.widget-share .fa-star { font-size: 25px; }
.widget-share .fa { font-size: 28px; }


@media #{$sm-screen} {
	.widget-share ul a { font-size: 40px; }
}

//widget-article
.widget-article { margin-top: 5px; }
.widget-article .widget-avatar { position: relative; margin: 20px 0px 20px 0px; }
.widget-article .widget-avatar { font-size: 22px; line-height: 1.18; font-family: $fontCalvert; color: #333; font-weight: bold; }
.widget-article .widget-avatar span { display: inline-block; vertical-align: middle; padding-top: 3px; width: 50px; margin: 0px 5px 0px 0px;}
.widget-article .widget-avatar .avatar { padding-top: 0; width: 50px; margin: 0px 5px 0px 0px; }
.widget-article .widget-avatar i { position: absolute; top: -15px; left: -15px; font-size: 33px; }
.widget-article .widget-avatar i span { padding: 0; vertical-align: top;}

@media #{$sm-screen} {
	.widget-article { margin-bottom: 20px; }
	.widget-article img { width: 100%; height: auto; }

	.widget-article .widget-avatar { padding-top: 14px; margin-left: 0; font-size: 18px; }
	.widget-article .widget-avatar i { left: 0; font-size: 25px; }
	.widget-article .widget-avatar .avatar { display: none; }
	.widget-article .widget-avatar span br { display: none; }
}

//widget-caption
.widget-caption { font-style: italic; color: #999; }
.widget-caption h6 { color: #777; font-size: 14px; font-weight: bold; font-style: normal; margin: 2px 0; }

@media #{$sm-screen} {
	.widget-caption i,
	.widget-caption h6 { display: none; }
}

@media #{$mobile} {
	.widget-caption { font-size: 14px; }
}

//Widget text
.widget-text { padding: 12px 15px; border: 1px solid #ddd; border-radius: 5px; font-size: 14px; }
.widget-text h6 { color: #777; font-size: 16px; font-weight: bold; margin: 0 0 10px; }
.widget-text p { margin: 0; }
