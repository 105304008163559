//Player

.player-content,
.player-image { display: inline-block; vertical-align: middle; }

.player-image { border: 1px solid #dadada; }

.player-content { font-family: $fontCalvert; font-size: 12px; line-height: 1; padding-left: 3px; }
.player-content strong { display: block; font-size: 18px; color: #333; padding-top: 3px; }
.player-content a { text-decoration: none; color: #08619f; }

.player-inner { padding-top: 8px; }
.player-inner img { width: 100%; height: auto; }