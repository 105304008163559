//Btns
.btn { font-weight: bold; text-transform: uppercase; color: #333; text-decoration: none; @include transition(background .3s, color .3s, opacity .3s); }

.btn-success { padding: 11px 13px 10px; border: none; font-weight: normal; text-transform: none; color: #FFF; }

.btn-lightgreen { padding: 3px 10px 5px; background: $brand-lightgreen; color: #fff; border-radius: 5px; }
.btn-lightgreen:hover { color: #fff; background: #04b5a2; }

.btn-warning { padding: 5px 10px; border: none; border-radius: 5px; }

.btn-info { padding: 4px 56px 6px; border: none; color: #FFF; }

.btn-white { padding: 3px 19px; border-color: #fff; color: #fff; }
.btn-white:hover, .btn-white:focus { background: #fff; color: $brand-primary; }

.btn-default { padding: 2px 19px; border-color: #ccc; color: $brand-info; }

.btn-app { display: block; background: #000; padding: 0; width: 100%;}
.btn-app:hover { opacity: .8; }

.btn-sm { padding: 2px 19px; font-size: 16px; text-transform: uppercase; font-weight: bold; }
.btn-lg { min-width: 284px; padding: 21px 30px 17px; font-size: 22px; text-transform: uppercase; font-weight: bold; }
.btn-xl { font-size: 22px; padding: 23px 10px 21px; min-width: 370px; border-radius: 6px; text-transform: uppercase; font-weight: bold; }
.btn-xxl { font-size: 22px; padding: 23px 10px 21px; min-width: 574px; border-radius: 6px; text-transform: uppercase; font-weight: bold; }

@media #{$md-screen} {
	.btn-lg { min-width: 234px; }
	.btn-xl { min-width: 304px; }
	.btn-xxl { min-width: 476px; }
}

@media #{$sm-screen} {
	.btn-lg { min-width: 290px; }
	.btn-xl { min-width: 340px; }
	.btn-xxl { min-width: 596px; }

	.btn-app { display: inline-block; width: 168px; height: 64px; }
}

@media #{$mobile} {
	.btn-default { font-size: 24px; }

	.btn-xxl,
	.btn-xl,
	.btn-lg { min-width: 0; width: 100%; display: block; }
}

@media #{$mobile} {
	.btn-app { width: 168px; height: 64px; }
}


//MOD

.btn-orobie{

          color: #474747;
          font-weight: 400;
          &:hover{ background-color: #0c629f; color: #ffffff; border: 1px solid #0c629f; }


}

.btn-home{

          &:hover{

                    background-color: green;
                    border: 1px solid #004e00;

          }

}
.btn-home-active{

          background-color: green;
          border: 1px solid green;
          color: #fff;

}

.btn-del {

          &:hover{
                    background-color: $btn-cancella; color: #ffffff;
                    border: 1px solid $hover-cancella;
          }


}

.btn-bianco  {background-color: #fff; color: #999; font-weight: 100;
border: 0px; padding: 6px 12px; border-radius: 4px; height: 40px; float: right;}

.btn-bianco:hover {background-color: $backgroundgrigio; }

.btn-camera {bottom: -175px;}

.btn-popup {background-color: rgb(0, 97, 161); border: 0px; padding: 10px 65px;  font-weight: normal;  }

/* IE 10 fix */
.btn-file ::-ms-browse {
    width: 100%;
    height: 100%
}

.btn-send {

          background-color: $btn-salva;
          font-weight: normal;
          height: 40px;
          border: none;
          color: #343434;
          margin: 0px 5px;

          &:hover{
                    background-color: $hover-salva;
                    color: #FFF;
          }
}

.div-salva {text-align: right; margin: 15px 0px; float: right; margin-bottom: 125px;}

.btn-salva:hover .btn-salva {background-color: $hover-salva;}
.btn-salva {

          background-color: $btn-salva;
          font-weight: normal;
          border: none;
          color: #343434;
          float: right;
          height: 40px;

          @media (max-width: $screen-xs) { width: 100%!important; bottom: 5px;    }
          @media (min-width: $screen-sm){ width: auto;  }

          &:hover{
                     background-color: #3b5c7d;
                    color: #fff;
          }
}

.btn-continua:hover { background-color: $hover-salva;}
.btn-continua {
          margin-top: 15px;
          background-color: $btn-salva;
          font-weight: normal;
          height: 40px;
          float: right;
          border: none;
          color: #343434;

@media (max-width: $screen-xs) { width: 100%!important; }
          @media (min-width: $screen-sm){ width: 100%!important; }

          &:hover{
                     background-color: #3b5c7d;
                    color: #fff;
          }
}

@media #{$mobile} {
	.btn-continua { width: 100%!important; } }


.btn-salta{ display: block; text-transform: uppercase; margin-top: 15px;
text-decoration: none; width: 100%; text-align: center; padding: 8px 12px !important; color: #333; }

.btn-giallo, .btn-giallo:hover, .btn-gallery {background-color: $color-gallery; border: 0px; color: #fff; font-size: 1rem;   }
.btn-rosso, .btn-rosso:hover, .btn-reportage {background-color: $color-reportage; border: 0px; color: #fff; font-size: 1rem; margin-right: 20px; }
.btn-navy, .btn-navy:hover, .btn-articolo {background-color: $color-articolo; border: 0px; color: #fff; font-size: 1rem; margin-right: 20px;}
.btn-verde, .btn-verde:hover, .btn-album {background-color: $color-album; border: 0px; color: #fff; font-size: 1rem; margin-right: 20px;}
.btn-acqua, .btn-acqua:hover, .btn-evento {background-color: $color-evento; border: 0px; color: #fff; font-size: 1rem; margin-right: 20px;}
.btn-azzurro, .btn-azzurro:hover, .btn-itinerario {background-color: $color-itinerario; border: 0px; color: #fff; font-size: 1rem; margin-right: 20px;}


.btn-mod{
          @media #{$md-screen}{
                   margin: auto;
                   display: table;     }
}

.btn-editcover {
    text-align: center;
    color: #000;
    opacity: 0.7;
    font-weight: 100;
    background-color: #fff;
    border: 0;
    padding: 6px 12px 5px 12px;
    border-radius: 4px;
    height: 40px;
    margin-left: 15px;
     }

.btn-edit-title, a.btn.btn-default.fileinput-upload.fileinput-upload-button {border-radius: 4px; background-color: $color-album; color:#FFF;}

.btn-100 {width: 100%}

.btn-annulla {background-color: $btn-cancella !important; color: #FFF !important; margin: 10px;}
.btn-annulla:hover {background-color: $hover-cancella !important; color: #FFF !important;}

.btn-invia {background-color: #92c201; color: #Fcommentoqscommentoqsbloccolike likecom FF;}
.btn-invia:hover {background-color: #709402; color: #FFF;}
