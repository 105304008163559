.article-single {
          //          padding: 45px 188px;

}

.sez-base {

          .single-sez {
                    padding: 0px;
                    //                    background-color: #0000fe;
                    @include container-fixed;

                    @media (min-width: $screen-sm-min) {
                              width: $container-sm;
                    }
                    @media (min-width: $screen-md-min) {
                              width: $container-md;
                    }
                    @media (min-width: $screen-lg-min) {
                              width: $container-lg;
                    }
          }

          .colonna--base {
                    @extend .col-md-7;
                    @extend .col-md-push-4;
          }

          .colonna--medium {
                    @extend .col-md-10;
                    @extend .col-md-push-1;
          }

          .colonna--sx {

                    @extend .col-md-10;
                    @extend .col-md-push-1;
                    display: block;
                    //           background-color: #000066;

          }

          .colonna--large {

                    background-color: #000066;
                    z-index: 6 !important;
                    position: relative;
                    //                    height: 50px;

          }

          h1 {
                    margin: 0 0 8px;
                    font-size: 3.0em;
                    line-height: 1.2em;
                    color: $titlecolor;
                    font-family: $fontCalvert;
                    font-weight: bold;
                    margin-bottom: 20px;
          }

          h3 {
                    font-size: 2.0em;
                    line-height: 1.18;
                    color: $txtcolor;
                    font-family: $fontCalvert;
                    font-weight: bold;
                    margin-bottom: 30px;
          }

          p {

                    // overflow: hidden;
                    font-size: 20px;
                    line-height: 1.4;
                    font-weight: lighter;
                    // margin : 0px 0px 65px 0px;
                    display: block;
                    -webkit-margin-before: 0px;
                    -webkit-margin-after: 3em;
                    -webkit-margin-start: 0px;
                    -webkit-margin-end: 0px;
                    //                    background-color: #0000fe;

          }

          //          FOTOGRAFIE

          .medium-image {

                    display: block;
                    //                    margin-bottom: 30px;
                    -webkit-margin-after: 3em;

                    //                    @extend .col-md-10;
                    //                    @extend .col-md-offset-1;

                    img {
                              width: 100%;

                    }

                    figcaption {
                              font-size: 0.8em;
                              font-style: italic;
                              font-weight: 300;
                              padding: 10px 0px 0px 0px;
                              color: $txtcolor;
                              -webkit-margin-before: 3em;
                              @extend .col-md-3;
                              position: absolute;
                    }
          }

          .imagecontent {

          }

          .small-image-left {

                    width: 55%;
                    //                 margin-top: 43px;
                    //                    margin-left: -170px;

                    float: left;
                    margin-right: 30px;
                    padding-top: 10px;
                    padding-bottom: 10px;

                    .aspectratio {

                              position: relative;
                              width: 100%;
                              margin: 0 auto;
                              display: block;

                    }

                    .photography {
                              padding-bottom: 66.4%;
                    }

                    img {

                              position: absolute;
                              top: 0;
                              left: 0;
                              width: 100%;
                              //                                  height: 100%;

                              display: block;
                              //                                  position: relative;
                              margin: auto;
                              //                              max-width: 100%;
                              box-sizing: border-box;

                    }

          }

          .box-sx {

                    // position: relative;
                    //  width: 100%;
                    // margin: 0 auto;
                    //  display: block;
                    // @extend .col-md-2;
                    //  @extend .col-md-push-1;

                    padding: 0px;

                    .content-box-sx {

                              width: 100%;

                              @media (min-width: $screen-sm-min) {

                              }

                              @media (min-width: $screen-md-min) {
                                        width: 210px;
                                        position: absolute;
                                        //                                         -webkit-margin-after: 2.4em;
                                        margin: 2.4em auto 2.4em auto;
                                        padding: 12px 15px;
                                        border: 1px solid #ddd;
                                        border-radius: 5px;

                              }

                              @media (min-width: $screen-lg-min) {
                                        width: 260px;
                                        position: absolute;
                                        //                                       -webkit-margin-after: 2.4em;
                                        margin: 10px 0px 10px 0px;
                                        padding: 12px 15px;
                                        border: 1px solid #ddd;
                                        border-radius: 5px;
                              }

                              p {
                                        font-size: 14px;
                                        margin-top: 0px;
                                        margin-bottom: 0px;
                                        padding-bottom: 0px;
                              }

                              h2 {
                                        font-size: 22px;
                                        font-weight: 700;
                                        margin-bottom: 0px;
                                        margin-top: 0px;
                              }

                    }

                    //                    @extend .col-md-push-9;
                    //                    margin-bottom: 0px;
                    //                    margin-top: 20px;
                    //                    float: left;
          }

}

// VERSIONE 02 //////

// ====================================
//============== TEST IE =============

//.article-single {
//
//          > h1 {
//
//                    padding-right: 20px;
//                    padding-left: 20px;
//                     @extend .posizionecontenuto--centrale;
//
//                    @media (min-width: $screen-sm-min) {
////                              width: $container-sm;
////                              padding-right: 60px;
////                              padding-left: 210px;
////                              font-size: 2.65em;
//
//                    }
//                    @media (min-width: $screen-md-min) {
////                              width: $container-md;
////                              padding-right: 100px;
////                              padding-left: 260px;
//
//                              //                              padding-left: 27vw;
//                              //                              padding-right: 8vw;
//
//                    }
//                    @media (min-width: $screen-lg-min) {
////                              width: $container-lg;
//                              //                              padding-left: 20vw;
//                              //                              padding-right: 7vw;
////                              padding-right: 120px;
////                              padding-left: 310px;
//
//                    }
//
//                    position: relative;
//                    display: block;
//                    //                    margin: auto;
//                    // overflow: hidden;
//                    font-size: 2.2em;
//                    line-height: 1.3;
//                    font-weight: 600;
//                    -webkit-margin-before: 0px;
//                    -webkit-margin-after: 0.6em;
//                    margin: 25px auto 25px auto;
//                    color: $titlecolor;
//                    font-family: $fontCalvert;
//                    text-rendering: optimizeLegibility;
//
//          }
//}
//
//.posizionecontenuto {
//
//          padding-right: 20px;
//          padding-left: 20px;
//
//          @media (min-width: $screen-sm-min) {
//                    width: $container-sm;
//                    padding-right: 60px;
//                    padding-left: 210px;
//
//          }
//          @media (min-width: $screen-md-min) {
//                    width: $container-md;
//                    padding-right: 100px;
//                    padding-left: 260px;
//
//                    //                              padding-left: 27vw;
//                    //                              padding-right: 8vw;
//
//          }
//          @media (min-width: $screen-lg-min) {
//                    width: $container-lg;
//                    //                              padding-left: 20vw;
//                    //                              padding-right: 7vw;
//                    padding-right: 120px;
//                    padding-left: 310px;
//
//          }
//
//}
//
//.posizionecontenuto--centrale {
//
//            max-width: 740px;
//           padding-left: 20px;
//            padding-right: 20px;
//
//          @media (min-width: $screen-sm-min) {
//                    width: $container-sm;
////                    padding-right: 60px;
////                    padding-left: 210px;
//
//          }
//          @media (min-width: $screen-md-min) {
//                    width: $container-md;
////                    padding-right: 100px;
////                    padding-left: 260px;
//
//                    //                              padding-left: 27vw;
//                    //                              padding-right: 8vw;
//
//          }
//          @media (min-width: $screen-lg-min) {
//
////                    width: 800px;
//
////                    width: $container-lg;
////                    padding-left: 100px;
////                    padding-right: 160px;
//                    //                              padding-left: 20vw;
//                    //                              padding-right: 7vw;
////                    padding-right: 120px;
////                    padding-left: 310px;
//
//          }
//
//}
//
//
//.posizionesinistra {
//
//          padding-left: 5px;
//          padding-right: 10px;
//
//          @media (min-width: $screen-sm-min) {
//                    width: $container-sm;
//                    padding-left: 5px;
//                    padding-right: 10px;
//
//          }
//          @media (min-width: $screen-md-min) {
//                    width: $container-md;
//
//                    padding-left: 15px;
//                    padding-right: 20px;
//
//          }
//          @media (min-width: $screen-lg-min) {
//                    width: $container-lg;
//                    padding-left: 15px;
//                    padding-right: 30px;
//                    //
//                    color: #f9322d;
//
//          }
//}
//
//.content--left {
//
//          width: auto;
//
//          @media (min-width: $screen-sm-min) {
//                    width: $container-sm;
//                    max-width: 185px;
//
//          }
//          @media (min-width: $screen-md-min) {
//                    width: $container-md;
//                    max-width: 220px;
//          }
//          @media (min-width: $screen-lg-min) {
//                    width: $container-lg;
//
//                    max-width: 250px;
//
//          }
//}
//
//.sez-base-primary {
//
//          &:focus {
//                    outline-style: none;
//                    outline-width: 0px;
//                    outline-color: #ffffff;
//          }
//
//          p{margin-bottom: 35px;}
//         > p {
//
//                                        @extend .posizionecontenuto--centrale;
//
//                    @media (min-width: $screen-sm-min) {
//                              margin-bottom: 65px;
//                              -webkit-margin-before: 0px !important;
//                              -webkit-margin-after: 3em !important;
//
//                    }
//
//                    position: relative;
//                    display: block;
//                    margin: auto;
//                    // overflow: hidden;
//                    //                    font-size: 20px;
//                    font-size: 1.4em;
//                    line-height: 1.4em;
//                    font-weight: lighter;
//                    margin-bottom: 35px;
//                    //                    display: block;
////                    -webkit-margin-before: 0px !important;
////                    -webkit-margin-after: 1.5em !important;
//                    //                    -webkit-margin-start: 0px;
//                    //                    -webkit-margin-end: 0px;
//                    //                    background-color: #0000fe;
//
//          }
//
//          a {
//                    font-weight: bold;
//                    cursor: pointer;
//          }
//          //FINE P
//
//          > h2 {
//
////                    @extend .posizionecontenuto;
//                    @extend .posizionecontenuto--centrale;
//
//                    position: relative;
//                    display: block;
//                    margin: auto;
//                    // overflow: hidden;
//                    font-size: 1.65em;
//                    line-height: 1.2;
//                    font-weight: 600;
//                    -webkit-margin-before: 0px;
//                    -webkit-margin-after: 0.6em;
//                    margin-bottom: 15px;
//                    color: $titlecolor;
//                    font-family: $fontCalvert;
//                    text-rendering: optimizeLegibility;
//
//          }
//          // FINE H2
//
//          > h3 {
//
//                    @extend .posizionecontenuto--centrale;
//
//                    position: relative;
//                    display: block;
//                    margin: auto;
//                    // overflow: hidden;
//                    font-size: 1.25em;
//                    line-height: 1.1;
//                    font-weight: 900;
//                    -webkit-margin-before: 0px;
//                    -webkit-margin-after: 0.6em;
//                    margin-bottom: 15px;
//                    color: $titlecolor;
//                    font-family: $fontCalvert;
//
//          }
//
//          > ul {
//                    @extend .posizionecontenuto--centrale;
//
//                    @media (min-width: $screen-md-min) {
//                              margin: 0px auto 65px auto;
//
//                    }
//
//                    position: relative;
//                    display: block;
//                    margin: 0px auto 35px auto;
//
//                    li {
//                              margin-left: 20px;
//                              font-size: 20px;
//                              line-height: 1.4;
//                              font-weight: lighter;
//                    }
//
//          }
//
////          .imgcentrale {
////                    margin: 0px auto 15px auto;
////                    -webkit-margin-before: 0px;
////                    -webkit-margin-after: 3em;
////
////                    @media (min-width: $screen-md-min) {
////                              margin-bottom: 35px;
////
////                    }
////
////                    //
////                    //                    img { width: 100%;}
////                    //
////                    @media (min-width: $screen-sm-min) {
////                              width: $container-sm;
////                              // padding-left: 0px;
////                              // padding-right: 100px;
////                    }
////                    @media (min-width: $screen-md-min) {
////                              width: $container-md;
////                              padding-right: 100px;
////                              padding-left: 15px;
////
////                    }
////                    @media (min-width: $screen-lg-min) {
////                              width: $container-lg;
////                              padding-right: 120px;
////                              padding-left: 15px;
////                              //                              padding-left:4vw;
////                              //                              padding-right: 7vw;
////
////                    }
////                    //
////                    figcaption {
////
////
////
////                              &:before {
////
////                                        content: '\f077';
////                                        font-family: fontawesome;
////                                        position: absolute;
////                                        top: -12px;
////                                        font-size: 10px;
////
////                              }
////
////                              @media (min-width: $screen-md-min) {
////                                        position: absolute;
////                                        padding: 0px 0px 10px 0px;
////                                        margin: 25px 0px;
////
////                              }
////
////                              font-size: 0.9em;
////                              line-height: 1.3em;
////                              font-style: italic;
////                              font-weight: 300;
////                              width: 100%;
////                              margin: 0px 30px 0px 20px;
////                              padding: 0px 0px 20px 0px;
////                              border-bottom: 1px dotted $txtchiarocolor;
////                              color: $txtcolor;
////                              -webkit-margin-before: 2em;
////                              margin-top: 25px;
////                              // width: 15vw;
////                              display: block;
////                              position: relative;
////                              z-index: 10;
////
////                              @extend .content--left;
////                              @extend .posizionesinistra;
////
////                              display: block;
////                              position: relative;
////
////                    }
////                    //
////          }
//
////          .imgfullsize {
////
////                    -webkit-margin-before: 0px;
////                    -webkit-margin-after: 3em;
////                    margin-bottom: 35px;
////                    z-index: 10;
////                    position: relative;
////
////
////                    figcaption {
////                              &:before {
////
////                                        content: '\f077';
////                                        font-family: fontawesome;
////                                        position: absolute;
////                                        top: -12px;
////                                        font-size: 10px;
////
////                              }
////                    }
////
////                    .didafoto {
////                              @extend .posizionesinistra;
////                              margin: auto;
////                              position: relative;
////
////                    }
////
////                    figcaption {
////                              @extend .content--left;
////
////
////                              &:before {
////
////                                        content: '\f077';
////                                        font-family: fontawesome;
////                                        position: absolute;
////                                        top: -12px;
////                                        font-size: 10px;
////
////                              }
////
////                              @media (min-width: $screen-md-min) {
////                                        position: absolute;
////                                        padding: 0px 0px 10px 0px;
////                                        margin: 25px 0px;
////                                        float: left;
////
////                              }
////
////                              //                              width: 250px;
////
////                              font-size: 0.9em;
////                              font-style: italic;
////                              font-weight: 300;
////                              //                              padding: 0px 0px 10px 0px;
////                              margin: 0px 20px 0px 20px;
////                              padding: 0px 0px 20px 0px;
////                              border-bottom: 1px dotted $txtchiarocolor;
////                              color: $txtcolor;
////                              -webkit-margin-before: 2em;
////                              margin-top: 25px;
////
////                              position: relative;
////                              display: block;
////                              z-index: 10;
////
////                    }
////          }
//
////          .imgleft {
////
////                    @extend .posizionesinistra;
////
////                    margin: auto;
////                    position: relative;
////                    margin-bottom: 35px;
////
////                    @media (min-width: $screen-md-min) {
////                              margin-bottom: 5px;
////
////                    }
////                    // z-index: 15;
////
////                    .aspectratio {
////
////                              @extend .content--left;
////
////                              @media (min-width: $screen-sm-min) {
////                                        float: left;
////                              }
////
////                    }
////
////                    figcaption {
////
////                              &:before {
////
////                                        content: '\f077';
////                                        font-family: fontawesome;
////                                        position: absolute;
////                                        top: -12px;
////                                        font-size: 10px;
////
////                              }
////                              @extend .content--left;
////
////                              @media (min-width: $screen-md-min) {
////                                        margin-top: 25px;
////                                        position: absolute;
////                                        padding: 0px 0px 10px 0px;
////                                        margin: 25px 0px 10px 0px;
////
////                              }
////
////                              margin: 0px 20px 0px 20px;
////                              padding: 0px 0px 20px 0px;
////                              border-bottom: 1px dotted $txtchiarocolor;
////                              position: relative;
////                              display: block;
////
////                              font-size: 0.9em;
////                              font-style: italic;
////                              font-weight: 300;
////
////                              color: $txtcolor;
////                              -webkit-margin-before: 2em;
////
////                              z-index: 10;
////
////                    }
////
////          }
//
//          .box--sinistro {
//
////                    ========================
////                    ==== ATTENZIONE =========
//                              visibility: hidden;
//                              display: none;
////                    ========================
////                    ========================
//
//                    position: relative;
//                    z-index: 10;
//                    margin: 10px 20px 25px 20px;
//                    padding: 0px 0px 0px 0px;
//
//                    @media (min-width: $screen-md-min) {
//
//                              margin: auto;
//                              padding: 0px 0px 0px 20px;
//
//                    }
//
//                    @extend .posizionesinistra;
//
//                    .content--sx {
//
//                              @media (min-width: $screen-md-min) {
//
//                                        float: left;
//                                        border: none;
//                                        padding: 0px 0px 0px 0px;
//
//                              }
//
//                              border-bottom: 1px dotted $txtchiarocolor;
//                              border-top: 1px dotted $txtchiarocolor;
//
//                              padding: 20px 0px 20px 0px;
//
//                              color: $txtcolor;
//                              font-weight: 300;
//                              font-style: italic;
//                              font-size: 0.9em;
//                              line-height: 1.35em;
//
//                              @extend .content--left;
//
//                              > h2 {
//                                        font-size: 22px;
//                                        font-weight: 700;
//                                        margin-bottom: 0px;
//                                        margin-top: 0px;
//                              }
//
//                    }
//
//          }
//
//          .slider {
//                    position: relative;
//                    //                    visibility: hidden;
//                    z-index: 10;
//                    margin-bottom: 35px;
//          }
//
//
//              > .imgfullsize {
//
//                    -webkit-margin-before: 0px;
//                    -webkit-margin-after: 3em;
//                    margin-bottom: 35px;
//                    z-index: 10;
//                    position: relative;
//
//
//                    figcaption {
//                              &:before {
//
//                                        content: '\f077';
//                                        font-family: fontawesome;
//                                        position: absolute;
//                                        top: -12px;
//                                        font-size: 10px;
//
//                              }
//                    }
//
//
//
//                    figcaption {
//                              @extend .content--left;
//
//
//                              &:before {
//
//                                        content: '\f030';
//                                        font-style: normal;
//                                        font-family: fontawesome;
//                                        position: absolute;
//                                        color: #ffffff;
//                                        top: -12px;
//                                        left: -10px;
//                                        background-color: #00c5f9;
//                                        border-radius: 50px;
//                                        padding: 5px;
//                                        font-size: 10px;
//
//                              }
//
//                              @media (min-width: $screen-md-min) {
//                                        position: absolute;
////                                     margin: 25px 0px;
//
//                                        float: left;
//                                        top: 20px;
//                                        top:20px;
//                                        left:20px;
//
//                                        background-color: #ffffff;
//                                        padding: 6px;
//                                        border-radius: 2px;
//                                        border: none;
//
//                              }
//
//                              //                              width: 250px;
//
//                              font-size: 0.9em;
//                              font-style: italic;
//                              font-weight: 300;
//                              //                              padding: 0px 0px 10px 0px;
//                              margin: 0px 20px 0px 20px;
//                              padding: 0px 0px 20px 0px;
//                              border-bottom: 1px dotted $txtchiarocolor;
//                              color: $txtcolor;
//                              -webkit-margin-before: 2em;
//                              margin-top: 25px;
//
//                              position: relative;
//                              display: block;
//                              z-index: 10;
//
//                    }
//          }
//
//
//     .imgcentrale {
//                    margin: 0px auto 15px auto;
//                    -webkit-margin-before: 0px;
//                    -webkit-margin-after: 3em;
//                   position: relative;
//
//                    @media (min-width: $screen-md-min) {
//                              margin-bottom: 35px;
//
//                    }
//
//                    //
//                    //                    img { width: 100%;}
//                    //
//                    @media (min-width: $screen-sm-min) {
//                              width: $container-sm;
//                              // padding-left: 0px;
//                              // padding-right: 100px;
//                    }
//                    @media (min-width: $screen-md-min) {
//                              max-width: 960px;
////                              width: $container-md;
////                              padding-right: 100px;
////                              padding-left: 15px;
////                              padding-left: 100px;
//
//                    }
//                    @media (min-width: $screen-lg-min) {
//                              width: $container-lg;
////                              padding-right: 120px;
////                              padding-left: 15px;
//
//                              //                              padding-left:4vw;
//                              //                              padding-right: 7vw;
//
//                    }
//                    //
//                    figcaption {
//
//
//
//                              &:before {
//
//                                         content: '\f030';
//                                        font-style: normal;
//                                        font-family: fontawesome;
//                                        position: absolute;
//                                        color: #ffffff;
//                                        top: -12px;
//                                        left: -10px;
//                                        background-color: #00c5f9;
//                                        border-radius: 50px;
//                                        padding: 5px;
//                                        font-size: 10px;
//
//
//                              }
//
//                             @media (min-width: $screen-md-min) {
//                                        position: absolute;
////                                     margin: 25px 0px;
//
//                                        float: left;
//                                        top: 20px;
//                                        top:20px;
//                                        left:20px;
//
//                                        background-color: #ffffff;
//                                        padding: 6px;
//                                        border-radius: 2px;
//                                        border: none;
//
//                              }
//
//                              font-size: 0.9em;
//                              line-height: 1.3em;
//                              font-style: italic;
//                              font-weight: 300;
//                              width: 100%;
//                              margin: 0px 30px 0px 20px;
//                              padding: 0px 0px 20px 0px;
//                              border-bottom: 1px dotted $txtchiarocolor;
//                              color: $txtcolor;
//                              -webkit-margin-before: 2em;
//                              margin-top: 25px;
//                              // width: 15vw;
//                              display: block;
//                              position: relative;
//                              z-index: 10;
//
//                              @extend .content--left;
//                              @extend .posizionesinistra;
//
//                              display: block;
//                              position: relative;
//
//                    }
//                    //
//          }
//
//     .imgleft {
//
//                    @extend .posizionesinistra;
//
//               img{  margin: auto;
//                    position: relative;
//                    margin-bottom: 35px;
//
//                    @media (min-width: $screen-md-min) {
//                              margin-bottom: 5px;
//
//                    }
//                    // z-index: 15;
//
//                    .aspectratio {
//
//                              @extend .content--left;
//
//                              @media (min-width: $screen-sm-min) {
//                                        float: left;
//                              }
//
//                    }}
//
//
//
//                    figcaption {
//
//                              &:before {
//
//                                        content: '\f077';
//                                        font-family: fontawesome;
//                                        position: absolute;
//                                        top: -12px;
//                                        font-size: 10px;
//
//                              }
//                              @extend .content--left;
//
//                              @media (min-width: $screen-md-min) {
//                                        margin-top: 25px;
//                                        position: absolute;
//                                        padding: 0px 0px 10px 0px;
//                                        margin: 25px 0px 10px 0px;
//
//                              }
//
//                              margin: 0px 20px 0px 20px;
//                              padding: 0px 0px 20px 0px;
//                              border-bottom: 1px dotted $txtchiarocolor;
//                              position: relative;
//                              display: block;
//
//                              font-size: 0.9em;
//                              font-style: italic;
//                              font-weight: 300;
//
//                              color: $txtcolor;
//                              -webkit-margin-before: 2em;
//
//                              z-index: 10;
//
//                    }
//
//          }
//
//
//
//}

// ========================================
// ========================================

//.medium-editor-placeholder:after{
//          color: #54f953 !important;
//
//          display: block;
//          margin-bottom: 65px;
//-webkit-margin-before: 0px !important;
//-webkit-margin-after: 3em !important;
//
//}
//
//
//          .medium-editor-placeholder {
//
//              margin-bottom: 65px;
//-webkit-margin-before: 0px !important;
//-webkit-margin-after: 3em !important;
//          color: #00c5f9!important;
//
//}

//======================================
//======================================
//========= INIZIO TEST IE =================
//.medium-editor-placeholder {
//          @extend .posizionecontenuto;
//          margin: auto;
//}
//
//.medium-editor-insert-plugin.medium-editor-placeholder:after {
//          @extend .posizionecontenuto--centrale;
//
//          @media (min-width: $screen-sm-min) {
//                    margin-bottom: 65px;
//
//                    -webkit-margin-after: 3em !important;
//
//          }
//
//          // overflow: hidden;
//          //                    font-size: 20px;
//          font-size: 1.4em;
//          line-height: 1.4;
//          font-weight: lighter;
//          margin-bottom: 35px;
//
//          //                    display: block;
//          //                    -webkit-margin-before: 0px!important;
//          //                    -webkit-margin-after: 1.5em!important;
//          //                    -webkit-margin-start: 0px;
//          //                    -webkit-margin-end: 0px;
//          //                    background-color: #0000fe;
//
//}
//
//
//.medium-editor-insert-plugin:after{
//@extend .posizionecontenuto--centrale;
//}
//
//
//.medium-editor-placeholder {
//          margin-top: -10px;
//}
//
//.medium-insert-buttons {
//
//          width: 100%;
//          .contenitore-bottone {
//                    padding-right: 20px;
//                    padding-left: 20px;
//                    margin: auto;
//
//                    @media (min-width: $screen-sm-min) {
//                              width: $container-sm;
//                              padding-right: 60px;
//                              //                              padding-left: 210px;
//
//                    }
//                    @media (min-width: $screen-md-min) {
//                              width: $container-md;
//                              padding-right: 100px;
//                              //                              padding-left: 260px;
//
//                              //                              padding-left: 27vw;
//                              //                              padding-right: 8vw;
//
//                    }
//                    @media (min-width: $screen-lg-min) {
//                              width: $container-lg;
//                              //                              padding-left: 20vw;
//                              //                              padding-right: 7vw;
//                              padding-right: 120px;
//                              //                              padding-left: 310px;
//
//                    }
//
//                    @media (min-width: $screen-sm-min) {
//                              margin-bottom: 65px;
//
//                              -webkit-margin-after: 3em !important;
//
//                    }
//
//          }
//
//          .medium-insert-buttons-show {
//
//          }
//
//}
//
////.medium-editor-insert-plugin:before {
////      color: #f9152e !important;
////}
//
//.medium-insert-images {
//}
//
//.medium-insert-active {
//}

//======================================
//======================================

//.medium-insert-images-center {
//          margin: 0px auto 15px auto;
//          -webkit-margin-before: 0px;
//          -webkit-margin-after: 3em;
//
//          @media (min-width: $screen-md-min) {
//                    margin-bottom: 35px;
//
//          }
//
//          //
//          //                    img { width: 100%;}
//          //
//          @media (min-width: $screen-sm-min) {
//                    width: $container-sm;
//                    // padding-left: 0px;
//                    // padding-right: 100px;
//          }
//          @media (min-width: $screen-md-min) {
//                    width: $container-md;
//                    padding-right: 100px;
//                    padding-left: 15px;
//
//          }
//          @media (min-width: $screen-lg-min) {
//                    width: $container-lg;
//                    padding-right: 120px;
//                    padding-left: 15px;
//                    //                              padding-left:4vw;
//                    //                              padding-right: 7vw;
//
//          }
//          //
//          figcaption {
//                    &:before {
//
//                              content: '\f077';
//                              font-family: fontawesome;
//                              position: absolute;
//                              top: -12px;
//                              font-size: 10px;
//
//                    }
//
//                    @media (min-width: $screen-md-min) {
//                              position: absolute;
//                              padding: 0px 0px 10px 0px;
//                              margin: 25px 0px;
//
//                    }
//
//                    font-size: 0.9em;
//                    line-height: 1.3em;
//                    font-style: italic;
//                    font-weight: 300;
//                    width: 100%;
//                    margin: 0px 30px 0px 20px;
//                    padding: 0px 0px 20px 0px;
//                    border-bottom: 1px dotted $txtchiarocolor;
//                    color: $txtcolor;
//                    -webkit-margin-before: 2em;
//                    margin-top: 25px;
//                    // width: 15vw;
//                    display: block;
//                    position: relative;
//                    z-index: 10;
//
//                    @extend .content--left;
//                    @extend .posizionesinistra;
//
//                    display: block;
//                    position: relative;
//
//          }
//}
//
//.medium-insert-images-left {
//
//          @extend .posizionesinistra;
//
//          margin: auto;
//          position: relative;
//          margin-bottom: 35px;
//
//          @media (min-width: $screen-md-min) {
//                    margin-bottom: 5px;
//
//          }
//          // z-index: 15;
//
//          .aspectratio {
//
//                    @extend .content--left;
//
//                    @media (min-width: $screen-sm-min) {
//                              float: left;
//                    }
//
//          }
//
//          figcaption {
//
//                    &:before {
//
//                              content: '\f077';
//                              font-family: fontawesome;
//                              position: absolute;
//                              top: -12px;
//                              font-size: 10px;
//
//                    }
//                    @extend .content--left;
//
//                    @media (min-width: $screen-md-min) {
//                              margin-top: 25px;
//                              position: absolute;
//                              padding: 0px 0px 10px 0px;
//                              margin: 25px 0px 10px 0px;
//
//                    }
//
//                    margin: 0px 20px 0px 20px;
//                    padding: 0px 0px 20px 0px;
//                    border-bottom: 1px dotted $txtchiarocolor;
//                    position: relative;
//                    display: block;
//
//                    font-size: 0.9em;
//                    font-style: italic;
//                    font-weight: 300;
//
//                    color: $txtcolor;
//                    -webkit-margin-before: 2em;
//
//                    z-index: 10;
//
//          }
//
//}
//
//.medium-insert-image-full{
//
//          -webkit-margin-before: 0px;
//                    -webkit-margin-after: 3em;
//                    margin-bottom: 35px;
//                    z-index: 10;
//                    position: relative;
//
//                    figcaption {
//                              &:before {
//
//                                        content: '\f077';
//                                        font-family: fontawesome;
//                                        position: absolute;
//                                        top: -12px;
//                                        font-size: 10px;
//
//                              }
//                    }
//
//                    .didafoto {
//                              @extend .posizionesinistra;
//                              margin: auto;
//                              position: relative;
//
//                    }
//
//                    figcaption {
//                              @extend .content--left;
//
//                              &:before {
//
//                                        content: '\f077';
//                                        font-family: fontawesome;
//                                        position: absolute;
//                                        top: -12px;
//                                        font-size: 10px;
//
//                              }
//
//                              @media (min-width: $screen-md-min) {
//                                        position: absolute;
//                                        padding: 0px 0px 10px 0px;
//                                        margin: 25px 0px;
//                                        float: left;
//
//                              }
//
//                              //                              width: 250px;
//
//                              font-size: 0.9em;
//                              font-style: italic;
//                              font-weight: 300;
//                              //                              padding: 0px 0px 10px 0px;
//                              margin: 0px 20px 0px 20px;
//                              padding: 0px 0px 20px 0px;
//                              border-bottom: 1px dotted $txtchiarocolor;
//                              color: $txtcolor;
//                              -webkit-margin-before: 2em;
//                              margin-top: 25px;
//
//                              position: relative;
//                              display: block;
//                              z-index: 10;
//
//
//
//                    }
//
//}





