//Stats

.stats {
	list-style: none outside none;
	margin: 0;
	padding: 0;
	overflow: hidden;
}

.stat {
	float: left;
	width: 25%;
}

.stat-icon {
	overflow: hidden;
	border-left: 1px solid #ccc;
	font-size: 55px;
	float: left;
	margin-right: 10px;
	color: #c5c5c5;
	line-height: 1;
}

.stat-icon i {
	margin-left: -20px;
}

.stat-icon .ico-eye {
	margin: -8px 0 0 -40px;
}

.stat-icon .ico-camera {
	margin-left: -28px;
}

.stat-content {
	overflow: hidden;
	font-size: 15px;
	padding-top: 7px;
}

.stat-content strong {
	display: block;
	font-size: 30px;
	color: #6d6d6d;
}

.stat-content span {
	display: block;
	font-size: 18px;
	color: #6d6d6d;
	padding-top: 10px;
}

.stat-content em {
	font-size: 14px;
}
@media #{$md-screen} {
	.stat-content span {
		font-size: 15px;
	}

	.stat-content em {
		font-size: 12px;
	}

	.stat-icon {
		margin-right: 10px;
	}
}
@media #{$sm-screen} {
	.stat {
		width: 23%;
	}

	.stat-icon {
		margin-right: 4px;
	}

	.stat-content {
		font-size: 13px;
	}

	.stat:last-child {
		width: 30%;
	}
}
@media #{$mobile} {
	.stat,
	.stat:last-child {
		width: 50%;
	}

	.stats {
		margin-bottom: -20px;
	}

	.stat {
		padding-bottom: 37px;
	}

	.stat-content {
		font-size: 16px;
	}

	.stat-content strong {
		font-size: 34px;
		line-height: 1;
	}

	.stat-content span {
		padding: 2px 0 9px;
	}

	.stat-icon {
		min-width: 68px;
	}

	.stat:nth-child(even) {
		text-align: right;
		@include flexbox;
		@include flex-direction(row-reverse);
	}

	.stat:nth-child(even) .stat-icon {
		float: none;
		border: none;
		margin: 0;
	}

	.stat:nth-child(even) .stat-icon i {
		margin: 0 -22px 0 0;
	}
}
@media #{$mobile-sm} {
	.stat-icon {
		min-width: 50px;
	}
}
