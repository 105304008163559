//Wrapper
.wrapper { min-height: 100%; position: relative; overflow: hidden; }

.wrapper-tertiary:before,
.wrapper-secondary:before {
          content: '';
          display: block;
          height: 106px;
          width: 100%;
//          background: #002c4a;
}

.wrapper-tertiary:before { height: 50px; }

@media #{$sm-screen} { 
	.wrapper-secondary:before { height: 50px; }

	.wrapper-inner { width: 100%; @include transition(margin .4s ease); }
	.wrapper-move-right { margin-left: 300px; }
	.wrapper-move-right .header { margin-left: 300px; }
	.wrapper-move-right .header .logo { opacity: 0; }
	.wrapper-move-right .header .nav-user { margin-right: -80px; }
	
	.wrapper-move-left { margin-left: -300px; }
	.wrapper-move-left .header { margin-left: -300px; }
	.wrapper-move-left .header .logo { opacity: 0; }
	.wrapper-move-left .header .header-actions { opacity: 1; visibility: visible; }

	.wrapper-move-left .nav-user button { width: 262px; }
	.wrapper-move-left .nav-user .avatar span { opacity: 0; }

	.wrapper-move-left .nav-user button em,
	.wrapper-move-left .nav-user button strong { position: static; opacity: 1; visibility: visible; @include transition(opacity .4s ease, visibility .4s ease); }
}

@media #{$mobile-sm} {
	.wrapper-move-right { margin-left: 250px; }
	.wrapper-move-right .header { margin-left: 250px; }

	.wrapper-move-left { margin-left: -250px; }
	.wrapper-move-left .header { margin-left: -250px; }

	.wrapper-move-left .nav-user button { width: 214px; }
}