//Ribbons

.ribbon { position: absolute; display: inline-block; padding: 6px 10px 7px; font-size: 13px; color: #fff; font-family: $fontCalibri; line-height: $line-height-base; }
.ribbon:after { content: ''; position: absolute; top: 100%; right: 15px; border-width: 10px 6px; border-style: solid; margin-top: -5px; @include rotate(90); }
.ribbon strong { position: absolute; top: 0; bottom: 0; left: 100%; margin-left: 5px; background: #fed02f; font-weight: bold; font-size: 20px; line-height: 28px; text-align: center; padding: 0 5px; }

.ribbon-lg { padding: 6px 14px 7px; font-size: 19px; font-weight: bold; }
.ribbon-lg strong { line-height: 32px; }
.ribbon-lg:after { right: 25px; border-width: 17px 8px; margin-top: -9px; }

.ribbon-primary { background: $brand-primary; }
.ribbon-primary:after { border-color: $brand-primary transparent transparent $brand-primary; }

.ribbon-success { background: $brand-success; }
.ribbon-success:after { border-color: $brand-success transparent transparent $brand-success; }

.ribbon-info { background: $brand-info; }
.ribbon-info:after { border-color: $brand-info transparent transparent $brand-info; }

.ribbon-warning { background: $brand-warning; }
.ribbon-warning:after { border-color: $brand-warning transparent transparent $brand-warning; }

.ribbon-danger { background: $brand-danger; }
.ribbon-danger:after { border-color: $brand-danger transparent transparent $brand-danger; }

.ribbon-lightgreen { background: $brand-lightgreen; }
.ribbon-lightgreen:after { border-color: $brand-lightgreen transparent transparent $brand-lightgreen; }


.ribbon-reportage { background: $color-reportage; }
.ribbon-reportage:after { border-color: $color-reportage transparent transparent $color-reportage; }

.ribbon-articolo { background: $color-articolo; }
.ribbon-articolo:after { border-color: $color-articolo transparent transparent $color-articolo; }

.ribbon-album { background: $color-album; }
.ribbon-album:after { border-color: $color-album transparent transparent $color-album; }

.ribbon-evento { background: $color-evento; }
.ribbon-evento:after { border-color: $color-evento transparent transparent $color-evento; }

.ribbon-itinerario { background: $color-itinerario; }
.ribbon-itinerario:after { border-color: $color-itinerario transparent transparent $color-itinerario; }

.ribbon-diario { background: $color-diario; }
.ribbon-diario:after { border-color: $color-diario transparent transparent $color-diario; }

.ribbon-sponsor { background: $brand-danger; }
.ribbon-sponsor:after { border-color: $brand-danger transparent transparent $brand-danger; }
