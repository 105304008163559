//Footer
.footer { padding: 25px 0; border-top: 1px solid #d6d6d6; position: relative; }
.footer-title { font-family: $fontCalibri; color: #0f0f0f; margin: 0 0 13px; }
.footer-logo { display: inline-block; vertical-align: middle; margin: 5px 0 20px; background: url(images/footer-logo.png) no-repeat 0 0; font-size: 0; line-height: 0; width: 153px; height: 40px; }

.footer .copyrights { padding-top: 62px; }

.footer-nav ul { list-style: none outside none; padding: 0; margin: 0; }
.footer-nav li + li { padding-top: 7px; }
.footer-nav a { text-decoration: none; color: $text-color; }
.footer-nav a:hover { text-decoration: underline; }

.footer-address { padding-bottom: 10px; }
.footer-address ~ .footer-address { padding-top: 24px; }
.footer-address a { color: inherit; text-decoration: none; }
.footer-address a:hover { text-decoration: underline; }
.footer-actions { padding-top: 5px;  a { margin-bottom: 5px; width: 100%; height: 100%;} }
// .footer-actions a + a { margin-top: 18px; }

@media #{$md-screen} {
	.footer-address ~ .footer-address { padding-top: 5px; }
}

@media #{$sm-screen} {
	.footer .sm-clear { clear: both; }
	.footer .copyrights { padding: 35px 0 33px; }
	.footer-title-alt { padding-top: 25px; }
	.footer-address { padding-bottom: 9px; }
	.footer-address ~ .footer-address { padding-top: 0; }
}

@media #{$mobile} {
	.footer .sm-clear { clear: none; }
	.footer-title-alt { padding-top: 0; }
	.footer-nav { padding-bottom: 20px; }
	.footer [class*="col-xs-6"]:nth-child(odd) { clear: both; }
	.footer .col-last { position: absolute; bottom: 96px; right: 0; height: 100px;

	img {margin-top: 5px; } }
}

@media (max-width: 479px) {
	.footer .col-last { bottom: 146px; }
}

@media #{$mobile-sm} {
	.footer .col-last { bottom: 168px; }
}

.box_header_orobox {
    width: 753px;
    height: 401px;
    margin-left: 110px;
    margin-bottom: 20px;
    text-indent: -9999px;
    background: url(../img/header_orobox.jpg) no-repeat 0 0;
}