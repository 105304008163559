@import 'sass/variables';
@import "sass/bootstrap";
//@import '../vendor/bootstrap-sass-3.3.6/bootstrap-sass-3.3.6/assets/stylesheets/bootstrap';

@import 'sass/mixins';
@import 'sass/base';
@import 'sass/fonts';
@import 'sass/orobieicons';
@import 'sass/helpers';
@import 'sass/btn';
@import 'sass/ico';
@import 'sass/logo';
@import "sass/avatar";
@import 'sass/wrapper';
@import 'sass/header';
@import 'sass/nav';
@import 'sass/dropdown-menu';

@import 'sass/list';
@import 'sass/ribbon';
@import 'sass/main';
@import 'sass/bar';
@import 'sass/socials';
@import 'sass/section';
@import 'sass/article';
@import 'sass/body-article';
@import 'sass/ads';
@import 'sass/gallery';
@import 'sass/callout';
@import 'sass/counter';
@import 'sass/widget';
@import 'sass/judge';
@import 'sass/post';
@import 'sass/avatar';
@import 'sass/slider';
@import 'sass/stat';
@import 'sass/comment';
@import 'sass/player';
@import 'sass/profile';
@import 'sass/feed';
@import 'sass/popup';
@import 'sass/footer';
@import "sass/forms";
@import "sass/profilo";
@import "sass/album";
@import "sass/ricerca";
@import "sass/registrazione";
@import "sass/datepicker";
@import "fontw/font-awesome";
@import "sass/fileinput";
@import "sass/editor/medium-editor";
@import "sass/select2";
@import "sass/like";
@import "sass/intro";
@import "sass/cookies-code";
@import 'sass/custom-article'; //camminata delle Orobie
@import "sass/pages-events";
// @import "sass/tag-form";
//dsfasdf

@import "sass/landing-page/landing";

.modal-backdrop.in{ opacity: 0.8;}
.modal-backdrop { z-index: 11;}
