//Counter
.counter ul { padding: 0; margin: 0; list-style: none outside none; overflow: hidden; padding-bottom: 3px; }
.counter li { position: relative; float: left; width: 25%; font-size: 19px; color: $brand-primary; text-align: center; line-height: .9; }
.counter li + li:before { content: ''; position: absolute; top: 24px; left: -3px; width: 8px; height: 8px; background: #393939; border-radius: 50%; }
.counter strong { display: block; font-size: 56px; padding-bottom: 3px; }

@media #{$md-screen} {
	.counter strong { font-size: 50px; }
	.counter li + li:before { top: 20px; width: 6px; height: 6px; }
}

@media #{$sm-screen} {
	.counter strong { font-size: 56px; }
	.counter li + li:before { top: 24px; width: 8px; height: 8px; }
}


