//Icons

.ico-e,
.ico-zoom-out,
.ico-zoom2,
.ico-bubble,
.ico-arrow-up,
.ico-arrow-left,
.ico-slider-prev,
.ico-slider-next,
.ico-hearth-o { background-repeat: no-repeat; background-position: center center; background-size: 100% auto; }

.ico-arrow-left { width: 8px; height: 14px; background-image: url(images/arrowleft.svg); }

.ico-slider-prev { width: 35px; height: 60px; background-image: url(images/arrowleft.svg); }
.ico-slider-next { width: 35px; height: 60px; background-image: url(images/arrowright.svg); }

.ico-hearth-o { width: 30px; height: 32px; background-image: url(images/heart.svg); }
.ico-bubble { width: 20px; height: 18px; background-image: url(images/bubble.svg); }

.ico-arrow-up { width: 6px; height: 10px; background-image: url(images/arrow-gray.svg); @include rotate(-90); }

.ico-zoom2 { width: 18px; height: 18px; background-image: url(images/zoom-in.svg); }
.ico-zoom-out { width: 18px; height: 18px; background-image: url(images/zoom-out.svg); }