//Sections

.section-secondary { background: #fff; }

//Section articles
.section-articles { padding: 40px 0 37px; }
.section-articles .section-title { text-align: center; color: #333; padding-bottom: 10px; }
.section-articles .section-subtitle { margin: -20px 0 15px; text-align: center; color: #333; padding-bottom: 10px; }
.section-articles .section-content { max-width: 570px; margin: 20px auto 0; text-align: center; }
.section-articles .section-actions { text-align: center; padding: 34px 0 50px; }

.section-articles-secondary { padding: 23px 0 12px; }
.section-articles-secondary .section-title { margin: 0; padding: 0 0 30px; }

@media #{$sm-screen} {
	.section-articles .section-content { margin-top: 50px; }
}

@media #{$mobile} {
	.section-articles { padding: 40px 5px 27px; }
	.section-articles .section-content { max-width: 100%; margin: 50px 0 0; text-align: left; }
	.section-articles .section-content .section-title { text-align: left; }

	.section-articles-secondary .section-title { text-align: left; }
}

//section-gallery
.section-gallery { padding: 10px 3% 60px; }
.section-gallery-alt { padding: 108px 0 60px; }
.section-gallery-secondary { padding: 50px 0 0; }

.section-gallery .section-head { background: #fff; text-align: center; }
.section-gallery .section-title { padding: 45px 0 28px; margin: 0; color: #333; }
.section-gallery .section-body { padding-top: 54px; }
.section-gallery .section-actions { text-align: center; padding-top: 34px; }
.section-gallery .section-actions a { margin: 0 2px; }
.section-gallery .section-actions i { font-size: 36px; }
.section-gallery .section-actions .btn-success { text-align: center; font-size: 2.5rem; }
.section-gallery .section-actions .btn-success i { float: right; }
.section-gallery .section-actions .btn-info { text-align: center; font-size: 2.5rem; }
.section-gallery .section-actions .btn-info i { float: left; }
.section-gallery .section-actions .btn-xxl { text-align: center; }
.section-gallery .section-actions-secondary { padding: 20px 0 17px; border-width: 3px 0 1px; border-style: solid; border-color: $brand-success transparent #ccc; margin-top: 60px; background: #fff; }

@media #{$md-screen} {
	.section-gallery .section-body { padding-top: 50px; }

}

@media #{$sm-screen} {


	.section-gallery-alt { padding: 150px 0 60px; }
	.section-gallery .section-title { padding-top: 0; }

	.section-gallery-secondary { padding-top: 20px; }
	.section-gallery-secondary .section-title { padding-top: 20px; }
}

@media #{$mobile} {
	.section-gallery { padding: 70px 5px 60px; }
	.section-gallery-alt { padding: 150px 5px 60px; }
	.section-gallery .section-title { text-align: left; padding: 0 15px 35px; }

	.section-gallery .section-actions .btn { position: relative; text-align: center; }
	.section-gallery .section-actions .btn ~ .btn { margin-top: 30px; }
	.section-gallery .section-actions .btn i { position: absolute; top: 50%; right: 30px; margin-top: -18px; }
}

//Section about
.section-about { font-size: 20px; line-height: 1.4; color: #444; }
.section-about .section-title { padding-bottom: 16px; font-family: $fontCalvert; font-weight: bold; line-height: 1.3; }
.section-about .container { position: relative; min-height: 400px; padding-top: 37px; padding-bottom: 60px; }
.section-about .section-image { position: absolute; bottom: 0; right: 0; }
.section-about p { padding-bottom: 20px; }
.section-about a { color: #0c629f; font-weight: bold; }
.section-about .widgets { padding-top: 60px; }
.section-about .widgets:before { content: ''; position: absolute; left: -13px; top: 60px; bottom: 20px; width: 1px; background: #dddddd; }

@media #{$md-screen} {
	.section-about .widgets { padding-top: 45px; }
	.section-about .widgets:before { top: 40px; bottom: 10px; }
	.section-about .section-image { right: -130px; }
}

@media #{$sm-screen} {
	.section-about .container { padding-top: 30px; padding-bottom: 26px; }
	.section-about .section-content { padding-left: 20px; }
	.section-about .section-image { right: -240px; }
	.section-about .widgets { overflow: hidden; padding: 26px 0 30px; border-top: 1px solid #ddd; }
	.section-about .widgets:before { display: none; }
}

@media #{$mobile} {
	.section-about { padding: 0 5px; font-size: 22px; line-height: 1.5; }
	.section-about .container { padding-top: 40px; }
	.section-about .section-content { padding: 0; }
	.section-about .section-image { position: static; text-align: right; margin-bottom: -26px; padding-top: 30px; }
}

//Section sponsors
.section-sponsors { padding: 10px 0; border-width: 1px 0; border-style: solid; border-color: #ccc transparent; text-align: center; }
.section-sponsors .section-title { margin: 0 0 10px; color: #333; }

//section-judges
.section-judges { padding: 7px 0 10px; border-top: 1px solid #ccc; }
.section-judges .section-title { text-align: center; color: #333; padding-bottom: 10px; }

@media #{$mobile} {
	.section-judges { padding: 0 5px; }
}

//Section posts
.section-posts { padding: 52px 0; box-shadow: inset 0 10px 10px rgba(0,0,0, .05); }
.section-posts .row ~ .row { padding-top: 60px; }
.section-posts .section-actions { margin-top: -17px; text-align: right; font-weight: bold; }
.section-posts .section-actions a { color: $brand-info; text-decoration: none; font-size: 18px; text-decoration: none; }
.section-posts .section-actions a:hover { text-decoration: underline; }

@media #{$sm-screen} {
	.section-posts .row ~ .row { padding: 0; }
	.section-posts .widget-about { margin-bottom: 30px; }
	.section-posts .section-actions { text-align: center; }

	.section-posts	.widget-ad { border-bottom: 1px solid #eeeeee; margin-bottom: 70px; margin-left: -4px; margin-right: -4px; }

	.section-posts .section-actions { margin: 20px 0 0; }
}

@media #{$mobile} {
	.section-posts { padding: 0 5px 30px; }
	.section-posts .widget-about { margin-bottom: 60px; }
	.section-posts .section-actions a { font-size: 22px; }
}

//Section-comments
.section-comments { padding: 38px 0 45px; }
.section-comments .section-head { padding-left: 0px; font-weight: 100; margin-bottom: 20px;}
.section-comments .section-title { color: #333; }
//.section-comments .content { padding-left: 99px; }
.section-comments .content { padding-left: 0px; }
.section-comments .sidebar { max-width: 300px; width: 100%; }

.section-comments-alt { border-width: 1px; border-style: solid; border-color: #ccc; }

@media #{$md-screen} {
	.section-comments .content { padding-left: 0px; }
}

@media #{$sm-screen} {
	.section-comments .section-head { padding: 0; text-align: center; }
	.section-comments .content { padding: 0 40px; }
	.section-comments .sidebar { max-width: 100%; }
	.section-comments .widget-map { margin: 44px 40px 60px; }
	.section-comments .widget-ad-sm { margin-left: -4px; margin-right: -4px; }
}

@media #{$mobile} {
	.section-comments .section-head { text-align: left; }
	.section-comments .content { padding: 0 5px; }
	.section-comments .widget-map { margin: 30px 0; }
}

@media (max-width: 479px) {
	.section-comments .content { padding: 0; }
	.section-comments .widget-ad-sm { margin-left: 0; margin-right: 0; }
}
