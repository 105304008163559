.article-events{

	// color: #fc0000;

	p{font-size: 17px;
    line-height: 1.5em;
    font-weight: lighter;}

		h5{font-weight: 600;
		font-size: 17px;
	margin-top: 20px;}

	ul.listapercorsi{

		font-size: 15px;
	    line-height: 1.5em;
	    font-weight: lighter;

			li{display: block; margin-bottom: 10px;}

	}

	.classe{

		font-weight: 600;
		color: #0c629f;

	}




}
.registerbot{

	visibility: hidden;
	display: none;

	padding: 8px;
	background-color: $color-itinerario;
	color: #ffffff;
	font-weight: 600;
	border-radius: 4px;
	margin: 10px 0px;

	@media #{$mobile}{

			display: table;
			visibility: visible;

	}


}
