.menuprofilo {

          //          background-color: #ffffff;
          display: block;
          padding: 10px;

          ul.menuvert {

                    list-style: none;
                    padding: 0px;
                    margin: 50px 0px 0px;

                    li {
                              display: block;
                              line-height: 1.5em;
                              border-bottom: 1px solid #efefef;
                              

                              a {
                                        font-weight: 300;
                                        display: block;
                                        text-decoration: none;
                                        line-height: 1.8em;
                                        color: #888888;
                                        text-transform: uppercase;
                                        padding: 4px;
                                        font-size: 1.4rem;

                                        &:hover {
                                                  background-color: #4e97cb;
                                                  color: #ffffff;
                                        }
                              }

                    }

                    li:last-child {border-bottom: 0px;}

          }

          ul.menuvertnomargin {margin: 0px}

}

@media #{$sm-screen} { .menuprofilo {display: none;} }
.menuprofilo-mobile {display: block;}

.blocco-form-profilo {

          background-color: #ffffff;
          padding: 10px;
          border-top: 4px solid #0c629f;
          margin-bottom: 30px;

          .row {
                    margin: 9px 0px;

                    //                     @media (min-width: $screen-xs) { margin:0px;    }

          }

          .radioption {
                    line-height: 30px;
          }
          p{margin: 5px 15px;}

}
