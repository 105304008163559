//judges

.judges { list-style: none outside none; margin: 0; padding: 0; }
.judge h5 { font-family: $fontCalvert; color: #444; letter-spacing: -0.018em; }
.judge-image { padding-bottom: 5px; }
.judge-image img { width: 100%; height: auto; }

@media #{$md-screen} {
	.judge h5 { font-size: 14px; letter-spacing: 0; }
}

@media #{$sm-screen} {
	.judge { padding-bottom: 26px; }
	.judge:nth-child(3n+1) { clear: both; }
	.judge h5 { font-size: 16px; }
}

@media #{$mobile} {
	.judges { margin: 0 -5px; }
	.judge { padding: 0 5px; }
	.judge:nth-child(2n+1) { clear: both; }
	.judge:nth-child(3n+1) { clear: none; }
	.judge h5 { font-size: 22px; font-weight: bold; }
}