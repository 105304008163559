.box-ricerca{

          z-index: 25;
          margin-top:20%;
          .input-ricerca{

                   border: none;
                    background: transparent;
                    border-bottom: 1px solid #808080;
                    font-size: 30px;
                    padding: 15px;
                    width:100%;
                    background-color: #fff;



          }
          .input-ricerca:focus{
                    border: none;
          }


}

.bottom20 {margin-bottom: 20px; padding: 20px;}

@media #{$sm-screen} { .input-ricerca{width:100%;} }
@media #{$sm-screen} { .ricerca-btn{width:100%;} }

.ricerca-btn {background-color: #92c201; color: #fff; height: 67px; font-size: 2rem; border: 0px;}