//Callout
.callout { padding: 19px 0 11px; border-bottom: 1px solid #ccc; overflow: hidden; }

.callout .counter,
.callout-content { float: left; }

.callout .counter { min-width: 334px; margin: -5px 0 0 48px; }
.callout-content { max-width: 372px; text-align: right; color: #000; font-size: 17px; }
.callout-content h3 { margin: 0 0 17px; font-weight: bold; color: $brand-success; }

.callout-actions { float: right; }

@media #{$md-screen} {
	.callout-content { max-width: 315px; padding-top: 13px; }
	.callout-content h3 { font-size: 25px; letter-spacing: -0.016em; margin-bottom: 12px; }

	.callout .counter { min-width: 300px; margin: 2px 0 0 16px; }
}

@media #{$sm-screen} {
	.callout { padding: 2px 0 13px; }
	.callout-content { float: none; text-align: center; max-width: 100%; margin: 0; padding-bottom: 5px; }
	.callout-content h3 { letter-spacing: 0; }
	.callout-content p { display: none; }
	.callout-actions { margin-right: 20px; }

	.callout .counter { margin: 0; min-width: 334px; }
}

@media #{$mobile} {
	.callout { padding: 2px 5px 30px; }

	.callout-actions,
	.callout .counter { float: none; }
	.callout-actions .btn { padding-left: 0; padding-right: 0; }
	
	.callout .counter { margin: 0 auto; max-width: 334px; width: 100%; min-width: 0; }
	
	.callout-actions { padding-top: 15px; margin: 0; }
}