@import url( 'https://fonts.googleapis.com/css?family=Roboto:300,400,700,900');
$colorblu: #3069b6;
$colorarancio: #f2a917;
$colortxt: #2a2a2a;

.headlanding {
	// height: 35vh;
	height: 200px;
	background-image: url('/static/img/landing/background-landing.jpg');
	background-position: bottom center;
	background-size: cover;
	background-repeat: no-repeat;
	border-bottom: 4px solid #cdcdcd;
	display: block;

	.blocco-apertura {
		margin-top: 30px;
		height: 80vh;
	}

	.app-logo {
		max-width: 200px;
	}

	.app-headline {
		font-weight: 800;
		color: #ffffff;
		text-transform: uppercase;
		font-size: 1.8em;
		@media (min-width: $screen-lg-min) {
			font-size: 4.0em;
		}
	}

	.img-phone {
		width: auto;
		position: absolute;
		display: block;

		img {
			max-width: 310px;
		}
	}
}

.blocco-content {
	font-family: 'Roboto', sans-serif!important;

	h1 {
		font-family: 'Roboto', sans-serif;
		font-weight: 500;
		color: $colorblu;

		span {
			color: $colorarancio;
		}
		font-size: 2.1em;
	}

	p {
		font-family: 'Roboto', sans-serif;
		font-size: 1.3em;
		line-height: 1.6em;
		color: $colortxt;
	}

	.blocco-01 {}
	// BLOCCO 02
	// ================================

	.blocco-02 {
		padding: 70px 0;

		.itemlist {
			text-align: center;
			border-right: 1px solid #ebebeb;

			&:last-of-type {
				border: none;
			}

			img.ico {
				display: block;
				margin: auto;
				width: auto;
				margin-bottom: 20px;
			}
			font-size: 1.3em;
			text-align: center;
			color: $colorblu;
			font-weight: 500;
			line-height: 1.3em;
		}
	}
}
// BLOCCO ACTION
// =====================

.blocco-action {
	background-color: #f8f8f8;

	.variante01 {
		.img-mobile-install {
			text-align: center;

			img {
				display: block;
				margin: auto;
			}
		}

		span.action {
			font-family: 'Roboto', sans-serif!important;

			display: block;
			font-size: 2.0em;
			margin-top: 30px;
			color: $colorarancio;
			font-weight: 600;
			text-align: center;
			vertical-align: middle;
			line-height: 1.2em;
		}

		.list-app-button{
			display: block;
			margin: auto;
			width: auto;

			li{
				text-align: center;
				display:  inline;
				float: left;
				width: 50%;
				padding: 20px 10px;

				a{
					display: block; max-width: 200px;
					margin: auto;

				}
			}
		}


	}
	// FINE BLOCCO ACTION
}





.struttura-step{

	padding: 30px 0px 0px 0px;


	h2{
		color: $colorblu;
		font-weight: 600;
		line-height: 1.3em;
		font-size: 2.0em;

		span{
			color: $colorarancio;
		}
	}

}



// BREAKPOIN SMALL
// ================
@media (min-width: $screen-sm-min) {
	.headlanding {

		height: 400px;

		.blocco-apertura {
			margin-top: 50px;
			height: 400px;
		}

		.app-headline {
			font-size: 3.8em;
		}
	}

	.blocco-content {
		h1 {
			font-size: 2.6em;
			line-height: 1.1em;
			font-weight: 500;
			margin-bottom: 0.4em;
		}
	}

	.blocco-action{
		span.action{
			font-size: 2.6em;
			margin-top: 20px;
		}
	}
	// FINE SM-MIN
}
// BREAKPOIN MEDIUM
// ================
@media (min-width: $screen-md-min) {
	.headlanding {
		.blocco-apertura {
			margin-top: 80px;
			height: 400px;
		}

		.app-headline {
			font-size: 3.8em;
		}
		// fine HEADLANDING
	}

	.blocco-content {
		h1 {
			font-size: 3.0em;
			line-height: 1.1em;
			margin-bottom: 0.7em;
		}
	}


	.blocco-action{
		span.action{

			font-size: 2.8em!important;
			margin-top: 40px;
		}

		.list-app-button{
			li{padding: 40px;}
		}
	}

	.struttura-step{

		padding: 80px 0px 0px 0px;


		h2{
			margin-top: 70px;
			font-size: 2.5em;
		}

		.img-step3, .img-step2, .img-step1{
			width: 100%;
		}

	}

.blocco-step3{
	.struttura-step{
		padding: 0px 0px 0px 0px!important;
	}
}

	// fine media breakpoint MD-MIN
}


@media (min-width: $screen-lg-min) {





}


@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and ( min--moz-device-pixel-ratio: 2), only screen and ( -o-min-device-pixel-ratio: 2/1), only screen and ( min-device-pixel-ratio: 2), only screen and ( min-resolution: 192dpi), only screen and ( min-resolution: 2dppx) {
	.blocco-02 {
		.itemlist {
			.ico {
				max-width: 50px!important;
				max-height: 50px!important;
			}
		}
	}
}
