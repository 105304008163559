
// BOTTONE LIKE

.dr--likeorobie{
	cursor: pointer;
	font-family: fontawesome;
	// height: 30px;
	// width: 30px;
	font-size: 30px;
	display: block; // background-color:#ffffff;
	color: #ffffff;
	font-weight: 100;

	&.black{
		color: #6b6c6c;
	}



	&.select:before{
		content: "\f004";
		font-family: fontawesome;
	}



	&.unselect:before {

		content: '\f08a';
		font-family: fontawesome;
	}

}
