//Bar

.bar {
    position: relative;
    padding-top: 19px;
    background: #fff;
    box-shadow: 0 2px 30px rgba(0, 0, 0, .08);
}

.bar:after {
    content: '';
    clear: both;
    display: table;
}

.bar-fixed {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    z-index: 9;
}

.bar-visible {
    position: fixed;
    transition: opacity 0.5s linear;
    opacity: 1;
}

.bar-hidden {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: opacity 1.5s linear;
}

.bar-alt:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    display: block;
    width: 100%;
    height: 5px;
    background: rgba(144, 196, 0, .4);
}

.bar-alt .progress {
    position: absolute;
    top: 100%;
    left: 0;
    height: 5px;
    max-width: 100%;
    background: #90c400;
}

.bar-alt.bar-fixed {
    position: fixed;
    -webkit-transition: opacity 0.5s ease-in;
    -moz-transition: opacity 0.5s ease-in;
    -o-transition: opacity 0.5s ease-in;
    -ms-transition: opacity 0.5s ease-in;
    transition: opacity 0.5s ease-in;
}

.bar-alt.bar-hidden {
    padding-top: 0;
}

.bar-alt.bar-hidden .container {
    height: 0;
    overflow: hidden;
    opacity: 0;
}

.bar-alt.bar-visible ~ .main .widget-share.widget-fixed {
    top: 156px;
}

.bar-alt.bar-visible {
    padding-top: 19px;
}

.bar-alt.bar-visible .container {
    height: auto;
}

.bar-primary {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    /*transition: all 0.3s ease-out;*/
}

.bar-primary.bar-fixed {
    position: fixed;
    top: 50px;
    -webkit-transition: opacity 2s ease-in;
    -moz-transition: opacity 2s ease-in;
    -o-transition: opacity 2s ease-in;
    -ms-transition: opacity 2s ease-in;
    transition: opacity 2s ease-in;
}

.bar .stats {
    // padding-left: 0px;
}

.bar .nav,
.bar .nav-tertiary,
.bar .socials {
    float: left;
}

.bar-inner {
    float: right;
}

.bar .socials {
    color: $brand-primary;
}

.bar-secondary {
    border-top: 1px solid #d6d6d6;
}

.bar-secondary-alt {
    box-shadow: none;
}

.bar .nav-alt {
    float: none;
    text-align: center;
}

.bar .nav-alt ul {
    display: inline-block;
    vertical-align: top;
}

.bar-tertiary {
    padding: 9px 0 10px;
    border-bottom: 1px solid #ccc;
    box-shadow: none;
}

.bar-quaternary {
    box-shadow: 0 2px 30px rgba(0, 0, 0, .2);
}

.bar-quaternary .bar-inner {
    padding: 18px 0 16px;
    float: none;
    border-top: 1px solid #f7f7f7;
}

.bar-quaternary .nav-quaternary {
    text-align: center;
}
@media #{$md-screen} {
    .bar .stats {
        // padding-left: 80px;
    }
}
@media #{$sm-screen} {
    .bar .stats {
        // padding-left: 40px;
    }
}
@media #{$mobile} {
    .bar .nav-alt {
        text-align: left;
        overflow: auto;
        white-space: nowrap;
    }

    .bar .nav-alt li {
        float: none;
        display: inline-block;
    }

    .bar .stats {
        // padding: 86px 0 0;
        margin: 0 -15px;
    }

    .bar .nav-quaternary {
        overflow: auto;
    }
}

.bar-album {
    min-width: 200px;

    h1,
    h1:hover {
        font-size: 1.2em;
        color: #CCC;
        text-decoration: none;
        margin-top: 16px;
        font-family: Calibri, "CalibriFF", Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        line-height: 0;
        text-align: right;
        position: relative;
        top: -10px;
    }

    a,
    a:hover {
        color: #999;
        text-decoration: none;
    }
}

.lista-strumenti {}

.social-list {
    padding: 0;
    margin: 0;
    display: block;
    float: right;
    margin-top: 15px;
    color: $objgrigio;

    li {
        display: inline;
        font-size: 1.3em;

        a {
            color: $objgrigio;
        }
    }

    li.editinglink {
        border-left: 1px dotted $objgrigio;
        margin: 0 0 0 8px;
        padding: 0 0 0 12px;

        a.editlink {
            color: $brand-primary;
        }
    }
}
//MENU DI EDITING
.editing-status {
    font-weight: lighter;
    line-height: 40px;
    color: $txtcolor;
    display: block;
    margin: 8px 0 0;

    > .chiaro {
        color: $txtchiarocolor;
    }
}

.strumenti-editing {
    float: right;
    list-style: none;
    padding: 18px 0 0;
    margin: 0;
    display: block;
    height: 55px;

    > li {
        display: inline-block;
        margin: 0 8px 0 0;
        padding: 0 20px 0 10px;
        border-right: 1px dashed $txtchiarocolor;

        &:last-of-type {
            border-right: none;
            padding-right: 0;
        }

        a {
            color: $txtcolor;
            font-weight: 200;
            display: block;
            text-decoration: none;
        }
    }

    .copyurl {}

    .pubblicabutton {
        color: #ed9914;
    }

    .pubblicabutton-mobile {
        font-size: 2rem;
    }

    .strumenti {}

    *:focus {
        outline: none;
    }
}

.strumenti-editing-mobile {
    float: left;
}
// FINE MENU EDITING

.centrato {
    margin: auto;
}

.nopadding {
    padding: 0 !important;
}
// MENU  CUSTOM
.menu-servizio {
    padding: 10px;
    background-color: #ffffff;
}

.intestazione-menu {
    display: block;
    font-style: italic;
    font-weight: 200;
    color: $txtcolor;
    border-bottom: 1px dotted $objgrigio;
    padding: 0 0 5px;
    margin: 0 0 5px;
    font-size: 0.8em;
}

.menu-servizio .title-menu {
    font-size: 1.3em;
    font-width: 200;
    margin: 0 0 10px;
}

.menu-servizio .descrizione {
    font-size: 0.8em;
    font-style: italic;
    font-weight: light;
    color: $txtchiarocolor;
    margin: 0 0 15px;
}

.url-copy {
    display: block;
    overflow: hidden;
    height: 20px;
}

.menu-url {
    li {
        a {
            padding: 0;
            cursor: pointer;
            cursor: hand;
        }
    }
    width: 250px;
    font-weight: 200;
    margin-right: -25px;
}

.publish-menu {
    width: 350px;
    font-weight: 200;
    margin-right: -26px;
}

.publish-menu-mobile {
    width: 290px;
}

.intro-txt {
    //          font-size: 0.8em;
    //          font-style: italic;
    font-weight: light; //          color: $txtcolor;
    display: block;
    margin: 20px 0 0;
}

.inputcopyurl {
    width: 100%;
    border: none;
    color: $brand-primary;
    cursor: pointer;
    font-size: 1.1rem;
}
//BOTTONE UPLOAD IMAGE BAR MENU
.uploadlink {
    .fa {
        font-size: 13px;
        margin-left: 5px;
    }

    .uploadimages {
        &:hover {
            color: $brand-primary;
        }
    }
}

.uploadlink-mobile {
    .fa {
        font-size: 2rem;
    }
}
//SEZIONE FIRMA MENU EDITING
.sezione-firme {
    display: block;
    //    padding: 15px 0px 15px 0px;
    margin: 0;
    //    border-bottom: 1px dotted $objgrigiochiaro;
    border-top: 1px dotted $objgrigiochiaro; //          font-size: 0.9em;
    color: $txtcolor;

    a {
        &:hover {
            background-color: $txtcolor;
        }
    }

    .input-firma {
        margin: 0;
        width: 100%;
        border: none;
        height: 32px;
        padding: 0 0 0 10px;
        border-left: 1px solid $objgrigiochiaro;
        line-height: 36px;
    }
}
//FINE SEZIONE MENU EDITING
// SELEZIONE DATA PUBBLICAZIONE ARTICOLO o GALLERIA
.sezione-data {
    display: block;
    //    padding: 15px 0px 15px 0px;
    margin: 0;
    //    border-bottom: 1px dotted $objgrigiochiaro;
    border-top: 1px dotted $objgrigiochiaro;
    // font-size: 0.9em;
    color: $txtcolor;

    a {
        &:hover {
            background-color: $txtcolor;
        }
    }
}

.selectdata {
    float: left;
    line-height: 50px;
    height: 50px;
}

.datapublic {
    //          float: right!important;
    font-size: 15px !important;
    font-weight: 400!important;
    color: $brand-primary!important; //          font-weight: 200!important;
    //          color: $txtcolor!important;
    line-height: 45px!important;
    height: 50px !important;
    display: inline-block;
    width: 150px !important;
    border: none !important;
    border-radius: 0 !important;
    margin-bottom: 0 !important;
    box-shadow: none !important; //          &:before {
    //                    content: '' \f073 '';
    //                    font-size: 30px;
    //          }

    &:focus {
        outline: none !important;
        box-shadow: none !important;
    }
}
// FINE SEZIONE DATA
//SEZIONE ACTION
.sezione-action {
    border-top: 1px dotted $objgrigiochiaro;
    padding: 10px 0 0;
}
//MODAL UPLOAD IMAGES ALBUM
.modal-large {
    width: 90%; //          height: 90vh;
}

.bodymodalimages {
    background-color: #ffffff;
    padding: 10px; //          height: 90vh;
}

.modal-content {
    border: none!important;
}
//FINE MODAL IMAGES UPLOAD
