//Article

.articles {
    margin: 0 -5px 20px;
    font-size: 0;
    line-height: 0;
}

.article {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 25%;
    padding: 0 10px 30px;
    font-size: $font-size-base;
    line-height: $line-height-base;
}

.article .ribbon {
    bottom: 100%;
    left: 20px;
    margin-bottom: -15px;
}

.article-inner {
    position: relative;
    border-radius: 4px;
    overflow: hidden;
}

.article-title {
    // text-transform: lowercase !important;
    font-size: 20px;
    line-height: 1.2em;
    margin: 0;
    padding-bottom: 5px;

    &:first-letter {
        text-transform: uppercase;
    }

    a {
        color: $titlecolor;
        text-decoration: none;
    }
    @media #{$mobile} {
        font-size: 26px;
        line-height: 1.35em;
        margin-bottom: 10px;
    }
    //          color: #0000ee!important;
}

.article-single .article-meta em a {
    color: #999;
    font-weight: 300;
    font-style: italic !important;
    font-size: 0.8em !important;

    &:Hover {
        text-decoration: underline !important;
    }

    &:after {
        content: ",";
    }

    &:before {
        content: "#";
    }

    &:last-of-type {
        &:after {
            content: "";
        }
    }
}

.article-content {
    position: relative;
    min-height: 149px;
    padding: 10px 15px 45px;
    background: #fff;

    p {
        display: block;
        overflow: hidden;
        line-height: 1.35em;
        font-size: 15px;
        //                 background-color: #0000ee;
        //                                    max-height: 170px;
        font-weight: lighter;

        strong {
            font-weight: 200;
        }
    }

    .box--single {
        height: 92px;
        overflow: hidden;
    }

    .box--solo {
        height: 212px;
        overflow: hidden;
        margin-top: 11px;
    }

    .box--leftimg {
        height: 230px;
        overflow: hidden;
        margin-top: 0;
        @media #{$mobile} {
            height: auto;
        }
    }

    .box--rightimg {
        height: 230px;
        overflow: hidden;
        margin-top: 15px; //background-color: #0000ee;
        @media #{$mobile} {
            height: auto;
        }
    }
    //          BREACKPOINT MOBILE ===========
    //          =============================
    @media #{$mobile} {
        height: auto;
        min-height: auto;

        p {
            font-size: 20px;
            padding-bottom: 10px;
        }

        .box--single {
            height: auto;
        }

        .box--leftimg {
            height: auto;
        }
    }
}

.article-content-secondary {
    text-align: center;
}

.article-content-tertiary {
    min-height: 298px;
}

.article-image a {
    display: block;
    overflow: hidden;
    height: 150px;
    background: #fff;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.article-image a:hover {
    opacity: 0.8;
}

.article .article-image img {
    display: none;
}

.article-title a {
    @include transition(color .3s);
}

.article-title a:hover {
    color: $brand-info;
}

.article-title-sm {
    padding: 0 0 8px;
    //          font-size: 18px;
}

.article-actions {
    position: absolute;
    bottom: 17px;
    left: 15px;
    right: 15px;
    text-align: center;
}

.article-meta {
    position: absolute;
    bottom: 12px;
    left: 15px;
    right: 15px;
    font-size: 12px;
    font-style: italic;
}

.article-meta span {
    display: block;
}

.article-author {
    padding-bottom: 5px;
}

.article-secondary,
.article-secondary .article-content,
.article-secondary .article-image {
    width: 50%;
}

.article-secondary .article-image a {
    height: 298px;
}

.article-secondary .article-content {
    min-height: 298px;
}

.article-tertiary {
    font-family: $fontCalvert;
    font-weight: bold;
}

.article-tertiary .article-image a {
    height: 298px;
}

.article-tertiary .article-title {
    font-size: 36px;
    line-height: 1;
    padding-bottom: 8px;
}

.article-tertiary .article-content {
    position: absolute;
    top: 15px;
    left: 0;
    right: 0;
    z-index: 2;
    background: none;
    color: #fff;
    pointer-events: none;
}

.article-single {
    padding: 0;
    position: relative;
}

.article-single .content {
    overflow: hidden;
    // font-size: 20px;
    line-height: 1.4;
    font-weight: lighter;
}

.article-single .content .alignright {
    margin-left: 20px;
}

.article-single .content h1 {
    margin: 0 0 8px;
		position: relative;
		display: block;
		//                    margin: auto;
		// overflow: hidden; s
		font-size: 2.2em;
		line-height: 1.3;
		font-weight: 600;
		-webkit-margin-before: 0;
		-webkit-margin-after: 0.6em;
		margin: 25px auto;
		color: $titlecolor;
		font-family: $fontCalvert;
		text-rendering: optimizeLegibility;
}

.article-single .content h2 {
	position: relative;
	display: block;
	margin: auto;
	// overflow: hidden;
	font-size: 1.65em;
	line-height: 1.2;
	font-weight: 600;
	-webkit-margin-before: 0;
	-webkit-margin-after: 0.6em;
	margin-bottom: 15px;
	color: $titlecolor;
	font-family: $fontCalvert;
	text-rendering: optimizeLegibility;

}

.article-single .content p{position: relative;
display: block;
margin: auto;
// overflow: hidden;
// font-size: 20px;
//                    font-size: 1.4em;
font-size: 20px;
line-height: 1.4em;
font-weight: lighter;
margin-bottom: 35px;}

.article-single .content h3 {
    font-family: "Calvert", serif;
		position: relative;
		display: block;
		margin: auto;
		// overflow: hidden;
		font-size: 1.3em;
		line-height: 1.45;
		font-weight: 400;
		-webkit-margin-before: 0;
		-webkit-margin-after: 0.6em;
		margin-bottom: 15px;
		color: $titlecolor;
		font-family: $fontCalvert;
		text-rendering: optimizeLegibility;
}

.article-single .contenp {
    padding-bottom: 20px;
}

.article-single .content a {
    text-decoration: none;
    color: #0559aa;
    font-weight: bold;
}

.article-single .content a:hover {
    text-decoration: underline;
}

.article-outter {
    position: relative;
}

.article-outter-mobile {
    margin-top: 80px;
}

.article-outter .widget-share,
.article-single .widget-share {
    position: absolute;
    top: 50px;
    right: 20px;
    z-index: 50;
}

.article-outter .widget-share ul,
.article-single .widget-share ul {
    background: #fff;
}

.article-outter .widget-share.widget-fixed,
.article-single .widget-share.widget-fixed {
    position: fixed;
    top: 100px;
    right: 50%;
    margin-right: -560px;
}

.article-outter .widget-share.widget-bottom,
.article-single .widget-share.widget-bottom {
    position: absolute;
    top: auto;
    bottom: 0;
    right: 20px;
    margin: 0;
}

.article-outter .widget-share,
.article-outter .widget-share.widget-bottom {
    right: 50%;
    margin-right: -560px;
}

.article-single .article-map {
    margin-top: 20px;
    margin-bottom: 20px;
    height: 60vh;
    width: 100%;
}

.article-single .article-meta {
    position: static;
    padding-bottom: 13px;
    font-size: 18px;
    font-family: $fontCalvert;
    font-weight: 300;
    font-style: normal;
    color: #002b4b;
}

.article-single .article-meta em {
    display: block;
    font-size: 20px;
    font-family: $fontCalibri;
    font-weight: normal;
    color: #777;
}

.article-single .article-image {
    margin: 30px 0 35px;
}

.article-single blockquote {
    text-align: center;
    color: $txtcolor;
    //          font-family: "Calvert", serif;
    font-family: $fontCalibri;
    font-size: 5rem;
    padding: 30px;
    border-top: 1px dashed $txtcolor;
    border-bottom: 1px dashed $txtcolor;
    margin-top: 30px;
    margin-bottom: 30px;
    border-left: 0;
    font-style: italic;
    font-weight: 700;
    line-height: 4.5rem;
}

.article-single .widget-about {
    margin-left: 30px;
    max-width: none;
    width: auto;
}

.article-single .widget-about ~ .widget-about {
    margin-top: 30px;
}

.article-single .widget-caption {
    margin-top: -13px;
}

.article-single .widget-text {
    margin-top: 130px;
}

.article-single .widget-map {
    margin-bottom: 25px;
}

.article-single .gallery-items {
    margin: 20px -5px 30px;
}

.article-tags {
    padding: 16px 0 15px;
    border-width: 1px 0;
    border-style: dashed;
    border-color: $txtchiarocolor;
    font-size: 20px;
    margin-top: 40px;
}

.article-tags--vuoto {
    border-top-width: 1px;
    border-top-style: dashed;
    border-top-color: $txtchiarocolor;
}

.article-tags a {
    text-decoration: none;
    text-transform: uppercase;
    color: $txtchiarocolor;
    margin-right: 27px;
    font-weight: 200;
}

.article-tags a:last-child {
    margin-right: 0;
}

.article-tags a:hover {
    text-decoration: underline;
}

.article-single-secondary {
    padding: 45px 100px 60px 0;
}

.article-single-tertiary {
    padding: 45px 88px;
}

.article-single-tertiary .content h3 {
    margin-bottom: 30px;
    font-family: "Calvert", serif;
    margin-top: 0;
}
@media #{$md-screen} {
    .article-single {
        padding: 0;
    }

    .article-single .widget-share {
        right: 10px;
    }

    .article-outter .widget-share,
    .article-outter .widget-share.widget-bottom,
    .article-outter .widget-share.widget-fixed,
    .article-single .widget-share.widget-fixed {
        margin-right: -470px;
    }

    .article-single .widget-share.widget-bottom {
        margin-right: 0;
        right: 10px;
    }

    .article-single-secondary {
        padding: 45px 80px 60px 0;
    }

    .article-single-tertiary {
        padding: 45px 80px;
    }

    .article-single .widget-about {
        margin-left: 10px;
    }
}
@media #{$sm-screen} {
    .article {
        width: 50%;
    }

    .article-secondary {
        width: 100%;
    }

    .article-single .article-image img {
        width: 100%;
        height: auto;
    }

    .article-single .content {
        overflow: visible;
    }

    .article-single .widget-share {
        right: 0;
    }

    .article-outter .widget-share,
    .article-outter .widget-share.widget-bottom,
    .article-outter .widget-share.widget-fixed,
    .article-single .widget-share.widget-fixed {
        margin-right: -360px;
    }

    .article-single .widget-share.widget-bottom {
        margin-right: 0;
        right: 0;
    }

    .article-single .content .ad {
        display: block;
        float: none;
        width: auto;
        padding: 0;
        margin: 0 -44px 64px;
    }

    .article-single .content .ad img {
        width: 100%;
        height: 90px;
    }

    .article-single .content .ad-alt {
        margin: 0 -24px;
    }

    .article-single-secondary {
        padding: 45px 20px 60px;
    }

    .article-single .widget-about {
        margin: 0;
    }

    .article-single .widget-about {
        margin-bottom: 40px;
    }

    .article-single .widget-about:first-child {
        margin-bottom: auto;
    }

    .article-single .widget-about ~ .widget-about {
        margin-top: 20px;
    }

    .article-single .article-image {
        margin-bottom: 0;
    }

    .article-single .widget-caption {
        margin-top: 0;
        padding: 5px 0 20px;
    }
}
@media #{$mobile} {
    .article,
    .article-secondary {
        width: 100%;
    }

    .article-secondary .article-content,
    .article-secondary .article-image {
        width: 100%;
        float: none;
    }

    .article-content,
    .article-secondary .article-content {
        min-height: 0;
    }

    .article {
        padding-bottom: 20px;
    }

    .article-content {
        padding: 17px 15px 55px;
    }

    .article-meta {
        font-size: 16px;
    }

    .article-secondary .article-image a {
        height: 300px;
    }

    .article-image a {
        background-position: right 0;
    }

    .article .ribbon {
        top: 150px;
        bottom: auto;
        left: 5px;
        right: 5 5px;
        padding: 0;
        height: 3px;
        font-size: 0;
        line-height: 0;
    }

    .article .ribbon:after {
        display: none;
    }

    .article-secondary .ribbon {
        top: 300px;
    }

    .article .ribbon-alt,
    .article-secondary .ribbon-alt {
        top: 0;
        border-radius: 3px 3px 0 0;
    }

    .article-title-sm {
        padding: 0;
    }

    .article-single {
        padding: 0;
        //                    font-size: 22px;
        //                    font-size: 1.3em;
        line-height: 1.36;
    }

    .article-single .content {
        font-size: 22px;
    }

    .article-single .widget-share {
        display: none;
    }

    .article-single .content h3 {
        font-size: 28px;
        line-height: 1.18;
        font-family: "Calvert", serif;
    }

    .article-single .content h3 br {
        display: none;
    }

    .article-single blockquote {
        padding: 10px;
        margin: 20px 0;
    }

    .article-single blockquote p {
        line-height: 1.2;
        padding: 0;
    }

    .article-single blockquote p:before {
        margin-left: -15px;
    }

    .article-tags {
        padding-bottom: 0;
    }

    .article-tags a {
        display: inline-block;
        margin-bottom: 15px;
    }

    .article-single .content .ad {
        margin: 0 0 64px;
    }

    .article-single .content .ad img {
        height: 80px;
    }
}
@media #{$mobile-sm} {
    .article-single blockquote p {
        font-size: 32px;
    }
}

.add-coverimage {
    text-align: center;

    hr {
        margin-top: -18px;
        border: 1px dashed #CCC;
    }
}

.post_body {
    background-color: #FFF;
    text-align: left;

    p:focus {
        outline: 0 solid transparent;
    }

    h1 {
        color: #393939;
        line-height: 1.4em;
    }

    h2 {
        color: #393939;
        font-family: "Calvert", serif;
    }

    h3 {
        color: #393939;
        font-family: "Calvert", serif;
        margin-top: 10px;
    }

    blockquote {
        text-align: center;
        color: #333;
        font-family: "Calvert", serif;
        font-size: 3rem;
        padding: 30px;
        border-top: 1px dashed #CCC;
        border-bottom: 1px dashed #CCC;
        margin-top: 30px;
        margin-bottom: 30px;
        border-left: 0;
    }

    a {
        color: #4e97cb;
        font-weight: 600;
    }

    i {
        font-size: 3rem;
        color: #999;
        margin-right: 7px;
    }

    i:hover {
        color: #666;
    }
}

.minicaption {
    p {
        font-size: 1.3rem;
    }
}

.tright {
    text-align: right;
}

.popover {
    border: 0 solid #FFF;
    -webkit-box-shadow: 0;
    margin-top: -3px;
}

.popover.right > .arrow {
    border-right-color: #FFF;
    display: none;
}

figcaption {
    .editor {
        color: #777;
        font-size: 14px;
        font-weight: bold;
        font-style: normal;
        margin: 2px 0;
        line-height: 1.4;
        text-align: left;
    }

    .post_body figcaption p {
        font-size: 1.3rem;
        font-style: italic;
        line-height: 1.4;
    }
}

.cont-b-layout {
    position: absolute;
    top: 0;
    opacity: 0;
    height: 298px;
    width: 96%;
    background-image: url('images/b-bg.png');
    background-repeat: repeat;
}

.cont-b-layout:hover {
    position: absolute;
    top: 0;
    opacity: 1;
}

.b-layout {
    height: 50px;
    text-align: center;
    margin-top: 10px;
    top: 45px;
    position: relative;

    .current,
    div:hover {
        opacity: 1;
    }

    img {
        height: 38px;
    }
}

.w100 {
    width: 220px;
    text-align: center;
    display: block;
    height: 80px;
    margin: 30px auto;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 20px 0;
}

.w33 {
    margin: 0 2px;
    opacity: 0.6;
    float: left;
}

.w17 {
    margin: 0 2px;
    opacity: 0.6;
    float: left;
}

.b-btn {
    position: absolute;
    right: 8px;
    top: 265px;
    width: 100%;
    text-align: right;

    i {
        color: #fff;
        font-size: 25px;
    }
}

.b-salva {
    text-align: center;
    width: 100%;
    min-height: 50px;
    position: absolute;
    bottom: 20px;
    z-index: 100;

    button {
        background-color: $btn-salva;
        color: #000;
        margin: 10px;
    }

    button:hover {
        background-color: $hover-salva;
    }

    span {
        a {
            i {
                color: #FFF;
            }
        }
    }
}

.b-salva2 {
    bottom: -20px !important;
}

.editing-layout {
    visibility: visible;
}

.editing-layout:hover {
    visibility: visible;
}

.b-drag {
    position: relative;
    top: -40px;
    right: 10px;
    width: 100%;
    text-align: right;

    i {
        font-size: 25px;
        color: #FFF;
    }
}

.b-add {
    min-height: 298px;
}

.b-agg {
    text-align: center;
    background-color: #d5d5d5;
    width: 100%;
    min-height: 298px;
    padding-top: 110px;

    i {
        color: #fff;
        font-size: 50px;
    }

    a {
        color: #fff;
        text-decoration: none;
    }
}

.b-agg:hover {
    opacity: 0.7;
}

.edit-articles {
    border: 1px dashed;
    padding-top: 20px;
    position: relative;
}

.b-success {
    font-size: 14px;
    padding: 10px;
    background-color: #c7eac8;
    border: 1px solid #3c763d;
    color: #3c763d;
    border-radius: 4px;
    text-align: center;
    margin: 10px;
}

.b-error {
    font-size: 14px;
    padding: 10px;
    background-color: #e5cbcb;
    border: 1px solid #a94442;
    color: #a94442;
    border-radius: 4px;
    text-align: center;
    margin: 10px;
}

.b-popup {
    width: 100%;
    min-height: 500px;
    background-color: #fff;
    border-radius: 20px;
    font-size: 14px;

    input {
        width: 100%;
        height: auto;
    }

    .button-cerca {
        border-radius: 5px;
        width: 100%;
        height: 35px;
        font-size: 14px;
    }
}

.b-i {
    text-align: center;

    i {
        font-size: 30px;
        margin-right: 5px;
    }

    .fa-check-circle {
        color: #92c201;
    }
}

.b-rigacerca {
    padding-bottom: 15px;
    border-bottom: 1px solid #f7f7f7;
}

.b-bottoni {
    text-align: center;
    margin-top: 10px;

    button {
        padding: 4px 8px;
        border: 1px solid #ccc;
        font-size: 12px;
        color: #333;
        border-radius: 0;
        margin: 0 -2px;
    }

    button:hover {
        border: 1px solid #ccc;
    }

    button:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    button:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .current {
        background-color: #e6e6e6;
    }
}

.b-data {
    font-size: 11px;
    text-align: center;
    padding-left: 0;
}

.b-image {
    background-size: cover;
    border: 1px solid #ccc;
    min-height: 55px;
}

.b-leggi {
    color: #FFF;
    position: absolute;
    top: 220px;
    width: 100%;
    text-align: center;
}

.b-editcover {
    height: 30px;
    position: absolute;
    bottom: 0;

    i {
        color: #FFF;
        font-size: 20px;
        margin-right: 10px;
    }
}

.article-single .content {
    .fullslider {
        background-color: #000066;
        position: relative;
        height: 300px;

        .sliderbox {
            //                              position: absolute;
            width: 100%;
        }
    }

    > p {
        //                    @extend .col-md-9;
        //                    @extend .col-md-offset-3;
        //                  clear: right;
    }

    .box-sx {
        //                    @extend .col-md-3;
        float: left;
        position: relative;
        //                 display: block;
        .content-box-sx {
            position: absolute;
            margin-top: 20px;
            padding: 12px 15px;
            border: 1px solid #ddd;
            border-radius: 5px;

            p {
                font-size: 14px;
                margin-top: 0;
                margin-bottom: 0;
                padding-bottom: 0;
            }

            h2 {
                font-size: 18px;
                font-weight: 700;
                margin-bottom: 0;
            }
        }
        //                    @extend .col-md-push-9;
        //                    margin-bottom: 0px;
        //                    margin-top: 20px;
        //                    float: left;
    }

    .medium-insert-images {
        @extend .col-md-12;
    }

    figure {
        figcaption {
            // @extend .col-md-3;
            position: relative;
            font-size: 0.7em;
            font-style: italic;
            font-weight: 300;
            margin: 0 20px;
            padding: 0 0 20px;
            border-bottom: 1px dotted #cbcdcd;
            color: #6b6c6c;
            -webkit-margin-before: 2em;
            margin-top: 25px;
            display: block;
            z-index: 10;
            line-height: 2rem;


            p {
                width: 100%;
                position: absolute;
                padding: 0;
                margin: 0;
            }
        }
    }

    blockquote {
        //                    @extend .col-md-12;
        line-height: 1.0em;
        text-align: center;
        color: $txtcolor;
        //          font-family: "Calvert", serif;
        font-family: $fontCalibri;
        font-size: 3em;
        padding: 30px;
        border-top: 1px dashed $txtcolor;
        border-bottom: 1px dashed $txtcolor;
        margin-top: 30px;
        margin-bottom: 30px;
        border-left: 0;
        font-style: italic;
        font-weight: 700;
        max-width: 900px;
        margin-left: auto;
        margin-right: auto;
    }
}

.article-single {
    blockquote {
        //                    @extend .col-md-12;
        line-height: 1.0em;
        text-align: center;
        color: $txtcolor;
        //          font-family: "Calvert", serif;
        font-family: $fontCalibri;
        font-size: 2.5em;
        padding: 30px;
        border-top: 1px dashed $txtcolor;
        border-bottom: 1px dashed $txtcolor;
        margin-top: 30px;
        margin-bottom: 30px;
        border-left: 0;
        font-style: italic;
        font-weight: 700;
        max-width: 900px;
        margin-left: auto;
        margin-right: auto;
    }
}

.rotation90 {
    -ms-transform: rotate(270deg);
    /* IE 9 */
    -webkit-transform: rotate(270deg);
    /* Chrome, Safari, Opera */
    transform: rotate(270deg);
    width: 50px;
    position: relative;
    left: 45%;

    i {
        font-size: 5em;
        color: #ccc;
    }
}

.cover-tutorial {
    height: 30vh !important;
}

.tutorial-p {
    margin-top: 50px;
}

.tutorial-bg {
    background-color: #f1f1f1;
}
// NUOVO LAYOUT X NEWS IN HOME
.article-image-full {
    min-height: 298px;
    background-size: cover;
}

.article-image-full-link {
    width: 100%;
    min-height: 298px;

    a {
        width: 100%;
        height: 100%;
    }
}

.article-sponsor {
    color: $brand-danger;
    line-height: 20px;
}
.article-single-darkimage-base {

  margin: -200px 0 0 0;
  transition: 0.5s margin ease-out;


  @media #{$md-screen} {
     margin: -210px 0 0 0;
  }
  @media #{$sm-screen} {
      margin: -215px 0 0 0;
      transition: 0.5s padding ease-out;
  }

  @media #{$mobile} {
     margin: -220px 0 0 0;
  }
}

.article-single-darkimage {
    margin: -80px 0 0 0;
    transition: 0.5s margin ease-out;

   @media #{$md-screen} {
        margin: -80px 0 15px 0;
        transition: 0.5s margin ease-out;
    }  /*
    @media #{$sm-screen} {
        margin: 150px 0 0;
        transition: 0.5s margin ease-out;
    }*/
    @media #{$mobile} {
        margin: -20px 0 0;
        transition: 0.5s margin ease-out;
    }



}

.margin-left-0 {margin-left: 0 !important;}

.no-padding {padding: 0 !important;}

.article-itinerario {
	// margin-top: -50px;
}

.medium-insert-images {padding-left: 0 !important; padding-right: 0 !important;}

.link-archivio-pagina {a {margin: 7px;
                          border: 1px solid #CCC;
                          padding: 3px 15px;
                          border-radius: 4px;
                          line-height: 45px;

                        }
                       a:hover {text-decoration: none !important;  }

                       h1 {margin: 30px 0 15px 15px !important;  }


              }
