.form-control{

          border-radius: 0px;
          border: 1px solid #e1e1e1;
          height: 40px;
          line-height: 1.3em;
          margin: 0px 0px 10px 0px;

}




.boxamici{
          margin-right: 0px !important;
          margin-top:22px;
          .img-amico{ width: 50px; height: 50px; background-color: #ffffff; display: block; float: left; margin-right: 10px;  }
          .nomeamico{  display: block; font-weight: 800; color:#000000; font-size: 1.3em; line-height: 1.5em;}
          .counter{ display: block; color: #919191; font-size: 0.9em; font-style: italic;}
          .btnsegui {
                    margin-top: 15px;

                    background-color: #fff;
                    color: #b4b4b4;
                    &:hover{ background-color: green; color: #fff;}

          }


}

.boxamicilast{

          margin-bottom: 40px;
           @media (min-width: $screen-xs) { margin-bottom:20px;    }
           @media (min-width: $screen-sm){ margin-bottom: 30px;  }
}


.kv-avatar .file-preview-file-preview-frame,.kv-avatar .file-preview-frame:hover {
    margin: 0;
    padding: 0;
    border: none;
    box-shadow: none;
    text-align: center;
}
.kv-avatar .file-input {
    display: table-cell;
    max-width: 220px;
}

.section-form {background-color: $backgroundgrigio; padding-top: 20px;}

.title-form {background-color: $backgroundgrigio; border-bottom: 1px solid #ccc;
              h5 {color: #000; margin-left: 15px; font-weight: 900; font-size: 2.8rem;} 
}

.form-tables {background-color: #fff; }

.form-fields {padding: 15px;
li {width: 100%; background-color: #999; text-transform: capitalize; }

}

.margin25-10 {margin: 25px 0 10px 0;}

.popup-copertina {min-height: 300px !important;}

.closemodal-album{

	padding:0px!important;
	margin:-20px 12px 0px 0px!important;
	font-size: 30px!important;

}
