@import "settings";
@import "animations/image-loading";
@import "animations/pop-upwards";
@import "components/anchor-preview";
@import "components/file-dragging";
@import "components/placeholder";
@import "components/toolbar";
@import "components/toolbar-form";
@import "util/clearfix";
@import "themes/default";


// contenteditable rules
.medium-editor-element {
    word-wrap: break-word;
    min-height: 30px;

    img {
//        max-width: 100%;
           width: 100%;
    }

    sub {
        vertical-align: sub;
    }

    sup {
        vertical-align: super;
    }
}

.medium-editor-hidden {
    display: none;
}

.select-sponsorizzato {line-height: 20px; margin-right: 10px; height: 30px;}

.sponsorizzato {border-top: 1px dotted #e8e8e8; padding: 10px 0 0; 
  select {background: #FFF; border: 1px solid #e8e8e8; } }
