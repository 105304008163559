//Lists

[class^="list-"] { list-style: none outside none; padding: 0; margin: 0; }

//List meta
.list-meta { overflow: hidden; }

.list-meta dt,
.list-meta dd { display: inline-block; vertical-align: middle; }

.list-meta dt { margin-top: -2px; }
.list-meta dd { padding-right: 16px; }
.list-meta i { font-size: 12px; }
.list-meta span { margin: 0 3px; }

.list-meta li { float: left; }
.list-meta li + li { padding-left: 22px; }
.list-meta-lg { font-size: 28px; }
.list-meta-lg i { font-size: 18px; margin-top: -6px; }

//list-sponsors
.list-sponsors { padding: 0 100px; text-align: justify; font-size: 0; line-height: 0; }
.list-sponsors:after { content: ''; display: inline-block; width: 100%; }
.list-sponsors li { display: inline-block; vertical-align: middle; font-size: $font-size-base; line-height: $line-height-base; }

@media #{$sm-screen} {
	.list-sponsors { padding: 20px 20px 10px; }
	.list-sponsors img { max-width: 80%; }
}

@media #{$mobile} {
	.list-sponsors { padding: 0 5px 15px; }
	.list-sponsors li { width: 33.33%; }
	.list-sponsors li + li { text-align: center; }
	.list-sponsors li:last-child { text-align: right; }
}

//List stats
.list-stats { padding: 0; margin: 0; overflow: hidden; }
.list-stats li { float: left; font-size: 21px; cursor: pointer;}
.list-stats li + li { padding-left: 18px; cursor: pointer;}

@media #{$mobile} {
	.list-stats { color: #777; margin: 0 -10px; }
	.list-stats li { width: 50%; padding: 0 10px 3px; font-size: 25px; }
	.list-stats li + li { padding: 0 10px 3px; }
}

@media #{$mobile-sm} {
	.list-stats li { width: 100%; }
}
