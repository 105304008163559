//Socials
.socials ul { list-style: none outside none; padding: 0; margin: 0; }
.socials li { display: inline-block; vertical-align: middle; font-size: 14px; }
.socials li + li { padding-left: 10px; }
.socials a { @include transition(color .3s); }
.socials a:hover { color: $brand-success; }

@media #{$md-screen} {
	.socials li + li { padding-left: 7px; }
}

@media #{$sm-screen} {
	.socials li + li { padding-left: 14px; }
}