//Sliders

.slider {position: relative; }
.slider .owl-item { backface-visibility: visible; }
.slides { list-style: none outside none; padding: 0; margin: 0; }

//Slider gallery
.slider-gallery { padding: 0 100px; }
.slider-gallery .slide { text-align: center; display: table; width: 100%}
.slider-gallery .slide-image { display: table-cell; vertical-align: middle; width: 100%}
.slider-gallery .slide-image img { width: auto; max-width: 100%; text-align: center; display: inline-block; }
.slider-gallery .owl-nav div { position: absolute; top: 50%; margin-top: -30px; @include transition(opacity .3s); }
.slider-gallery .owl-nav div:hover { opacity: .8; }
.slider-gallery .owl-prev { left: -80px; }
.slider-gallery .owl-next { right: -80px; }


@media #{$md-screen} {
	.slider-gallery { padding: 0 80px; }
}

@media #{$sm-screen} {
	.slider-gallery { padding: 0 40px; }
	.slider-gallery .owl-nav div { margin-top: -20px; }
	.slider-gallery .owl-nav div i { width: 24px; height: 40px; }
	.slider-gallery .owl-prev { left: -50px; }
	.slider-gallery .owl-next { right: -50px; }
}

@media #{$mobile} {
	.slider-gallery { padding: 0; }
	.slider-gallery .owl-nav { display: none; }
}

//Slider profile
.slider-profile { background: #fff; }
.slider-profile .slide-image { background: #000; } 
.slider-profile .slide-image img { opacity: .5; @include transition(opacity .6s); }
.slider-profile .active .slide-image img { opacity: 1; }
.slider-profile .owl-nav div { position: absolute; top: 50%; margin-top: -30px; @include transition(opacity .3s); }
.slider-profile .owl-nav div:hover { opacity: .8; }
.slider-profile .owl-prev { left: 115px; }
.slider-profile .owl-next { right: 115px; }

.slider-profile .owl-dots { text-align: center; padding-top: 21px; }
.slider-profile .owl-dot { display: inline-block; vertical-align: middle; width: 5px; height: 5px; margin: 0 7px; border-radius: 50%; background: #ccc; @include transition (background .3s); }

.slider-profile .owl-dot:hover,
.slider-profile .owl-dot.active { background: #333; }

@media #{$md-screen} {
	.slider-profile .owl-prev { left: 20px; }
	.slider-profile .owl-next { right: 20px; }
}

@media #{$sm-screen} {
	.slider-profile .owl-nav div { margin-top: -20px; }
	.slider-profile .owl-nav div i { width: 24px; height: 40px; }
	.slider-profile .owl-prev { left: 10px; }
	.slider-profile .owl-next { right: 10px; }
}

@media #{$mobile} {
	.slider-profile .owl-dots { padding-top: 8px; }
	.slider-profile .owl-nav { display: none; }
}
