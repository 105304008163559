//Profile

.profile .col-static { position: static; }
.profile .widgets { position: absolute; top: 0; right: 15px; }

.profile-cover { height: 383px; background-size: cover; background-position: center center; background-repeat: no-repeat; }
.profile-cover img { display: none; }

.profile-bar { padding: 13px 0; background: #fff; text-align: right; height: 60px;}

//.profile-content { padding: 50px 0 90px; }
.profile-content { padding: 50px 0 0px; }
.profile-content .container, .profile-cover .container { position: relative;}
.profile .widget-ad { text-align: right; }
.profile .list-stats { position: absolute; bottom: 100%; right: 359px; margin-bottom: 65px; }

.profile-image { margin-top: -235px; overflow: hidden; padding-left: 20px; padding-right: 20px; position: relative; }
.profile-image img { width: 100%; height: auto; border: 8px solid #fff; border-radius: 20px;  }

.profile-entry { padding: 10px 25px 6px 0; }
.profile-title { margin: 12px 0 6px; color: #333; }

.profile-actions { padding-bottom: 5px; border-bottom: 1px solid #ddd; }
.profile-actions a { text-decoration: none; }
.profile-actions .btn { margin-bottom: 10px; }

.profile-actions-alt i { font-size: 20px; color: #424242; margin-right: 5px; }
.profile-actions-alt a { text-decoration: none; color: #777; }
.profile-actions-alt a:hover { text-decoration: underline; }
.profile-actions-alt li { list-style: none; padding: 14px; border-bottom: 1px solid #ddd; i {font-size: 12px;   } }
.profile-actions-at li:last-child { border-bottom: 0px solid #ddd;}
.profile-actions-alt ul {padding-left: 0px;}

.profile-actions-secondary { text-align: right; padding: 14px 210px 14px 0; }
.profile-actions-secondary a { font-weight: bold; text-decoration: none; color: #015fa5; font-size: 18px; }

.profile-socials { font-size: 16px; padding: 15px 0 16px 15px; border-bottom: 1px solid #ddd; }
.profile-socials ul { list-style: none outside none; margin: 0; padding: 0; overflow: hidden; }
.profile-socials li { float: left; margin-right: 10px; }
.profile-socials a { @include transition(opacity .3s); }
.profile-socials a:hover { text-decoration: none; opacity: .8; }
.profile-socials i { width: 40px; height: 40px; color: #fff; text-align: center; line-height: 40px; }
.profile-socials .ico-youtube { background: #ec4d46; font-size: 12px; }
.profile-socials .ico-twitter { background: #29c3f5; font-size: 12px; }
.profile-socials .ico-facebook { background: #3b5998; }
.profile-socials .ico-instagram { background: #3b82b8; }


.profile-cont-btn {position: absolute; top: 330px; right: 15px; font-size: 17px;}
.profile-btn-editcover {
    float: right;
    text-align: right;
    opacity: 0.9;
    font-weight: 300;
    background-color: #fff;
    border: 0;
    padding: 9px 12px 9px 12px;
    border-radius: 4px;
    a {color: #000;} }

.profile-btn-crop {
    float: right;
    opacity: 0.9;
    font-weight: 100;
    background-color: #fff;
    border: 0;
    padding: 9px 12px 9px 12px;
    border-radius: 4px;
    margin-left: 10px;
    a {color: #000;} }






.profile-cover, .album-cover {

          .button-mod {
                              padding: 10px 10px 5px;
                              background: #ffffff;
                              opacity: 0.5;
                              color: #000;
                              font-size: 2rem;
                              font-weight: 100;
                              text-align: center;
                               cursor: pointer;
                              border-radius: 5px;

                              width: 200px;
                              margin: 320px auto;
                              position:absolute;
                              right: 15px;


          }
}




@media #{$md-screen} {
	.profile .list-stats { right: 239px; }
	.profile-image { margin-top: -200px; }


}

@media #{$sm-screen} {
	.profile .col-static { position: relative; }
	.profile .container { max-width: 100%; }
  // .profile-content { padding: 0px 50px 90px; width: auto; }

	.profile-image { width: 163px; margin-top: -180px; }
	.profile .list-stats { position: static; margin: 0; }
//	.profile .btn { width: calc(50% - 10px); }

//	.profile-bar { padding: 13px 50px; }

	.profile-entry { padding: 0; p {margin: 15px 0px; } }

	.profile-actions { overflow: hidden; padding: 16px 0 10px; }
	.profile-actions .btn { float: left; }
	.profile-actions .btn ~ .btn { float: right; margin: 0; }

	.profile-socials { padding-left: 0; }
	.profile-socials i { width: 30px; height: 30px; font-size: 12px; line-height: 30px; }

	.profile-actions-alt { border: none; padding: 0; bottom: 21px; right: 13px; }

	.profile .widgets { position: static; }
	.profile .widget-ad { margin: 60px -45px 0; padding-bottom: 60px; }

	.profile-actions-secondary { padding-right: 0; }

}

@media #{$mobile} {
   .profile-cont-btn {font-size: 16px; top: 70px;}

    .profile-cover {

              height: 50vh;

              .button-mod{

                        margin-top: 80px;

                        display: block;

    }}


//	.profile .btn { font-size: 20px; padding: 10px 0; }
	.profile-content { padding: 30px 5px; }
	.profile-image { width: 150px; padding-left: 0px; }
	.profile-title {  font-size: 28px; margin: 0px 0px 10px 0px; }
	.profile .list-stats { margin: 0 -10px; }

	.profile-socials i { width: 40px; height: 40px; line-height: 40px; font-size: 16px; }

	.profile-actions-alt { bottom: 25px; }
	.profile-actions-alt { line-height: 0 }

	.profile-actions-secondary { padding: 26px 0 60px 52px; text-align: left; border-top: 1px solid #ccc; margin-top: 44px; }
	.profile-actions-secondary a { font-size: 22px; }
}

@media (max-width: 479px) {
//	.profile-image { margin-top: -120px; }
//	.profile .btn { font-size: 18px; }
	.profile .widget-ad { margin: 60px 0 0; }
}

.newavatar {background-color: #FFF; width: 85%;
            opacity: 0.8; position: absolute;
            bottom: 0px; padding: 10px 10px 30px 20px;
            -webkit-border-bottom-right-radius: 20px;
            -webkit-border-bottom-left-radius: 20px;
            -moz-border-radius-bottomright: 20px;
            -moz-border-radius-bottomleft: 20px;
            border-bottom-right-radius: 20px;
            border-bottom-left-radius: 20px;

            a { color: #333; cursor: pointer; font-size: 12px; visibility: yes;}
            i {font-size: 17px; visibility: yes; margin-right: 10px;}

            }
@media (max-width: 1209px) {.newavatar {width: 180px} }
@media (max-width: 991px) {.newavatar {width: 125px} }
@media #{$mobile}  {.newavatar i { visibility: yes} }


table.notice_settings { width: 100%;
                        td {padding: 10px 20px; text-align: center;}
                        td:first-child {text-align: left;}
                        tr {border-bottom: 1px solid #e8e8e8;}
                        i {margin-right: 5px;}

@media #{$mobile}  {font-size: 12px;
                    i {font-size: 40px !important; width: 100%; margin-bottom: 4px; text-align:center !important;}
                    td {padding: 10px 5px !important; text-align: center;}
                    td:first-child {text-align:center !important;}

                    }

}

.userlist { text-align: left;
  ul { -webkit-padding-start: 0;}
  li { padding: 15px 0 5px 0; border-bottom: 1px solid #e5e5e5;
       li { padding: inherit; border-bottom: inherit;} }
  li:last-child { border-bottom: 0px ; }
  img {border-radius: 5px; width: 30px; height: 30px; margin-left: 20px;}
  a {color: #777; font-size: 1.8rem;}
  span  {margin-left: 10px; line-height: 30px;}
            }
