//Gallery

.dr--bar-imgdettaglio {}

.gallery {
	background: #101010;
	// margin-top: 50px;
}

.gallery-expanded {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 99;
}

.gallery-expanded .container {
	width: 80%;
}

.gallery-head {
	padding: 17px 0 12px;
	border-bottom: 1px solid #707070;
	overflow: hidden;
	height: 55px;
}

.gallery-head-actions {
	float: left;
	margin: 3px 30px 0 0;
}

.gallery-head-actions a {
	color: #fff;
	text-decoration: none;
}

.gallery-head-actions a:hover {
	text-decoration: underline;
}

.gallery-head-actions i {
	margin: -2px 1px 0 0;
}

.gallery-title {
	float: left;
	color: #fff;
	font-family: $fontCalvert;
	margin: 0;
}

.gallery-body {
	padding-top: 32px;
}

.gallery-foot {
	padding: 22px 0 14px;
}

.gallery-foot-inner {
	float: right;
	// margin-right: 60px;
	@media #{$mobile} {
		margin-right: 5px;
	}
}

.gallery-foot .container {
	position: relative;
	overflow: hidden;
}

.gallery-meta {
	float: left;
	padding-top: 3px;
	// margin-left: 96px;
	color: #fff;
	line-height: 1;
	font-size: 14px;
}

.gallery-meta .avatar {
	margin-right: 5px;
}

.gallery-meta span {
	display: inline-block;
	vertical-align: middle;
}

.gallery-meta strong {
	display: block;
	font-weight: normal;
	font-size: 20px;
	color: #FFF;
}

.gallery-thumbs {
	//position: absolute;
	//top: 0;
	//left: 350px;
	//right: 350px;
	//text-align: center;
	//position: relative;
	//width: 400px;
	//overflow: hidden;
	//height: 90px;
}

.gallery-thumbs div {
	display: inline-block;
	margin: 0 2px;
	cursor: pointer;
	@include transition(opacity .3s);
}

.gallery-thumbs .active,
.gallery-thumbs div:hover {
	opacity: 0.8;
}

.gallery-actions,
.gallery-credits {
	float: left;
}

.gallery-credits {
	min-width: 240px;
	margin-right: 6px;
	font-size: 15px;
	padding-top: 3px;
}

.gallery-credits strong {
	display: block;
	color: #6d6d6d;
	padding-bottom: 2px;
}

.gallery-actions {
	padding-right: 13px;
}

.gallery-actions a {
	color: #fff;
	font-size: 30px;
	line-height: 30px;
	text-decoration: none;
}

.gallery-actions a.dr-tohome {}

.fa-trophy {
	transition: color 0.5s ease;

	&.select {
		color: #ffffff;
	}

	&.selectcoppa {
		color: #ffffff;
	}

	&.unselect {
		color: $text-color;

		&:hover {
			color: #ffffff;
		}
	}
}

.gallery-actions a + a {
	margin-left: 8px;
}

.gallery-actions .link-fav-active .ico-hearth-o {
	background-image: url("images/heart3.svg");
}
/*.gallery-items { overflow: hidden; margin: 0 -5px; }
.gallery-item { position: relative; float: left; width: 39.7%; padding: 0 5px 10px; }
.gallery-item-full { width: 100%; }
.gallery-item-size2 { width: 20.4%; }
.gallery-item-size3 { width: 46.6%; }
.gallery-item-size4 { width: 18.2%; }
.gallery-item-size5 { width: 35.2%; }
.gallery-item-size6 { width: 23%; }
.gallery-item-size7 { width: 31%; }
.gallery-item-size8 { width: 26%; }
.gallery-item-size9 { width: 13.6%; }
.gallery-item-size10 { width: 34.4%; }
.gallery-item-1of2 { width: 50%; }
.gallery-item-1of3 { width: 33.333%; }
*/
.gallery-item {
	border: 0;
}
/*.gallery-item-image a { display: block; }*/
.gallery-item-image {
	width: 100%;
	height: 100%;
}

.gallery-item-image img {
	width: 100%;
	height: auto;
}

.gallery-item-content {
	position: absolute;
	top: 50%;
	left: 35px;
	right: 35px;
	padding: 28px 10px;
	background: rgba(255, 255, 255, .85);
	@include transform(translateY(-50%));
}

.gallery-item-content ~ .gallery-item-overlay {
	display: none;
}

.gallery-item-meta {
	position: relative;
	float: left;
	padding-right: 13px;
	border-right: 1px solid;
	margin-right: 16px;
	font-size: 22px;
	font-weight: 300;
	font-family: $fontCalvert;
	line-height: 1;
	text-align: right;
	min-width: 81px;
}

.gallery-item-meta strong {
	display: block;
	padding-bottom: 6px;
}

.gallery-item-meta-lightgreen {
	color: $brand-lightgreen;
}

.gallery-item-meta-danger {
	color: $brand-danger;
}

.gallery-item-meta-success {
	color: $brand-success;
}

.gallery-item-meta .ribbon {
	bottom: 100%;
	left: 5px;
	margin-bottom: 17px;
}

.gallery-item-entry {
	overflow: hidden;
	text-align: left;
}

.gallery-item-title {
	margin: -2px 0 0;
	color: #333;
	font-size: 16px;
	font-weight: 600;
}

.gallery-item-title a {
	text-decoration: none;
	color: inherit;
}

.gallery-item-title span {
	display: block;
	text-transform: none;
}

.gallery-item-overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 15px 5px;
	background: rgba(0, 44, 73, .8);
	color: #fff;
	text-align: center;
	//opacity: 0;
	//visibility: hidden;
	//@include transition(opacity .6s, visibility .6s);
}

.gallery-item-overlay h4 {
	font-size: 17px;
	margin-top: 40px;
	position: relative;
	text-transform: uppercase;
	@include transform(translateY(-50%));
}

.gallery-item-overlay-foot {
	display: block;
	width: 100%;

	a {
		height: 20px;
		display: block;
	}
}

.gallery-item-overlay-actions {
	position: absolute;
	bottom: 15px;
	right: 15px;
	float: right;

	a {
		height: 35px !important;
		float: right;
	}
}

.gallery-item-overlay .list-meta {
	font-size: 12px;
	text-align: center;
	margin-top: 5px;
	height: 130px;
	color: #FFF;
}

.gallery-item-overlay .list-meta dt {
	margin-right: 3px;
}

.gallery-item-overlay .list-meta dd,
.gallery-item-overlay .list-meta dt {
	padding-bottom: 6px;
	line-height: 1;
}

.gallery-item-size4 .gallery-item-overlay-foot {
	left: 6px;
	right: 6px;
}

.gallery-item-size4 .gallery-item-overlay .list-meta {
	font-size: 12px;
}

.gallery-item:hover .dr--gallery-item-overlay,
.gallery-item:hover .gallery-item-overlay {
	opacity: 1;
	visibility: visible;
}

.gallery-item-credits {
	width: 100%;
	margin: 0 0 10px;
}

.gallery-item-spacer {
	width: 100%;
	height: 20%;
}

.gallery-item-credits img {
	width: 30px !important;
	height: 30px !important;
}

.gallery-item-credits span {
	font-size: 14px;
	display: inline-block;
	vertical-align: middle;
	text-align: left;
	padding-left: 4px;
	line-height: 1;
}

.gallery-item-credits span strong {
	display: block;
}

.gallery-item-credits a {
	color: #fff;
}

.gallery-item-credits-secondary {
	margin: 3px 0 0;
	text-align: left;

	img {
		width: 22px !important;
		height: 22px !important;
	}
}

.gallery-item-credits-secondary a {
	color: #999;
	text-decoration: none;
	float: left;
	margin-left: 5px;
	line-height: 20px;
}

.gallery-item-actions {
	right: 10px;
}

.gallery-item-avatar {
	border-radius: 50%;
	overflow: hidden;
	display: inline-block;
	vertical-align: middle;
}
@media #{$md-screen} {
	.gallery-title {
		margin-left: 80px;
	}

	.gallery-meta {
		margin-left: 80px;
	}

	.gallery-credits {
		min-width: 190px;
	}

	.gallery-item-content {
		left: 25px;
		right: 25px;
	}

	.gallery-item-title span {
		display: inline;
	}

	.gallery-item-size2 .gallery-item-overlay h4,
	.gallery-item-size4 .gallery-item-overlay h4 {
		font-size: 16px;
	}

	.gallery-item-size2 .gallery-item-overlay .list-meta,
	.gallery-item-size4 .gallery-item-overlay .list-meta {
		display: none;
	}
}
@media #{$sm-screen} {
	/*.gallery-item { width: 50%; } */
	/* .gallery-item-full { width: 100%; }
           .gallery-item-size2 { width: 27.9%; }
           .gallery-item-size3 { width: 50%; }
           .gallery-item-size4 { width: 33.8%; }
           .gallery-item-size5 { width: 66.2%; }
           .gallery-item-size6 { width: 23%; }
           .gallery-item-size7 { width: 31%; }
           .gallery-item-size8 { width: 26%; }
           .gallery-item-size9 { width: 13.6%; }
           .gallery-item-size10 { width: 34.4%; }
           .gallery-item-1of2 { width: 50%; }
           .gallery-item-1of3 { width: 33.333%; }
           .gallery-item-sm-1 { width: 72.1%; }
           .gallery-item-sm-2 { width: 50%; } */
	.gallery-item-overlay h4 {
		font-size: 18px;
		left: 20px;
		right: 20px;
	}

	.gallery-item-overlay .list-meta {
		font-size: 12px;
	}

	.gallery-item-overlay .list-meta dd {
		padding-right: 10px;
	}

	.gallery-item-content {
		left: 35px;
		right: 35px;
	}

	.gallery-head {
		border: none;
		padding-bottom: 10px;
	}

	.gallery-title {
		margin-left: 28px;
	}

	.gallery-head-actions .link-back {
		font-size: 0;
		line-height: 0;
	}

	.gallery-head-actions .ico-arrow-left {
		width: 16px;
		height: 26px;
		margin-top: -12px;
	}

	.gallery-body {
		padding-top: 0;
	}

	.gallery-meta {
		margin-left: 40px;
	}

	.gallery-actions {
		padding-right: 3px;
	}

	.gallery-credits {
		min-width: 180px;
	}

	.gallery-thumbs {
		left: 280px;
		right: 280px;
	}

	.gallery-expanded .container {
		width: 100%;
	}
}
@media #{$mobile} {
	.gallery-head {
		position: relative;
		padding: 13px 5px 11px;
	}

	.gallery-title {
		font-size: 15px;
		margin-left: 31px;
	}

	.gallery-head-actions {
		position: absolute;
		top: 13px;
		left: 20px;
	}

	.gallery-body .container {
		padding: 0;
	}
	/* .gallery-item-size6 { width: 50%; }
             .gallery-item-size8 { width: 50%; }
             .gallery-item-size9 { width: 28.5%; }
             .gallery-item-size10 { width: 71.5%; }

             .gallery-item-sm-3 { width: 43%; }
             .gallery-item-size7 { width: 57%; } */
	.gallery-item-overlay {
		display: none;
	}

	.gallery-foot .container {
		overflow: visible;
	}

	.gallery-foot .container:after {
		content: '';
		clear: both;
		display: table;
	}

	.gallery-thumbs {
		display: none;
	}

	.gallery-meta {
		margin-left: 5px;
	}

	.gallery-credits {
		position: absolute;
		top: 100%;
		left: 20px;
		z-index: 2;
		margin-top: 24px;
		font-size: 22px;
	}

	.gallery-credits strong {
		padding-bottom: 0;
	}

	.gallery-actions {
		padding-right: 5px;
	}

	.gallery-item-content {
		top: auto;
		left: 5px;
		right: 5px;
		bottom: 10px;
		padding: 8px 10px 15px;
		@include transform(none);
	}

	.gallery-item-credits-secondary {
		display: none;
	}

	.gallery-item-meta {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		font-size: 0;
		line-height: 0;
		padding: 0;
		margin: 0;
		border: none;
	}

	.gallery-item .ribbon {
		left: 0;
		font-size: 0;
		line-height: 0;
		height: 3px;
		width: 100%;
		padding: 0;
		margin: 0;
	}

	.gallery-item .ribbon:after {
		display: none;
	}

	.gallery-item-title {
		font-size: 20px;
	}
}
@media #{$mobile-sm} {
	.gallery-item {
		width: 100%;
	}

	.gallery-item-title {
		font-size: 16px;
		text-transform: none;
	}

	.gallery-item-content {
		padding: 5px;
	}
}
// ALBUM FOTOGRAFICO

h1.titoloalbum {
	font-size: 2.8em;
	color: #ffffff;
	display: block;
	text-align: center;
	line-height: 1.4em;
	margin-top: 5%;
	@media #{$mobile} {
		font-size: 1.6em;
		line-height: 1.3em;
	}
}

.mod-cover {
	padding: 10px 10px 5px;
	background: #fff;
	opacity: 0.5;
	color: #000;
	font-size: 2rem;
	font-weight: 100;
	text-align: center;
	cursor: pointer;
	border-radius: 5px;
	width: 200px;
	position: absolute;
	float: right;
}

.a.block {
	display: block;
	color: #FFF;
}

.gallery-item-credits-overlay {
	img {
		float: left;
		margin-right: 10px;
		border-radius: 3px;
	}

	span {
		width: 200px;
	}
}

.dr--img-item-gallery {
	display: block;
	width: 100%;
	height: 100%;
	background-position: center;
	background-size: cover;
}

.dr--gallery-item-overlay {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	padding: 15px 5px;
	background: rgba(0, 44, 73, .8);
	color: #fff;
	text-align: center;
	opacity: 0;
	visibility: hidden;
	@include transition(opacity .6s, visibility .6s);
	pointer-events: none;
}

.dr--gallery-item-overlay-foot {
	.list-meta {
		font-size: 11px!important;
		border-top: 1px solid rgba(255, 255, 255, 0.2);
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
		margin: 15px;
		padding: 10px 0;
	}
}

.dr--img-link-overlay {
	line-height: 1.4em;
	display: inline-block;
	max-width: 100%;
	font-size: 14px;
	font-weight: bold;
	font-weight: 500;
	color: #fff;
	position: relative;
	position: static;
	pointer-events: auto;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.dr--gallery-item-overlay-actions {
	z-index: 1000;
	position: absolute;
	width: 100%;
	bottom: 15px;
	padding: 0 15px;
	//right: 15px;
	float: right;

	a {
		height: 35px !important;
		float: right;
	}
}

.dr--content-overlay-center {
	//position: absolute;
	//top: 0px;
	//bottom: 0px;
	//left: 0px;
	//right:0px;
	//display: table;
	// vertical-align: middle;
	// height: 40px;
	//margin-top:20%;
	margin: auto;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	max-height: 120px;
	@media #{$sm-screen} {
		max-height: 90px;
	}

	.avatarbox {
		margin-top: 0;
	}

	h4 {
		font-size: 16px;
		display: block;
		margin: auto;
		padding: 0 20%;
		@media #{$sm-screen} {
			padding: 0 5%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

.dr--actions-single-image {
	float: right;
	@media #{$mobile} {
		visibility: hidden;
		display: none;
	}

	ul {
		padding: 0;
		margin: 0;

		li {
			display: inline-block;
			font-size: 20px;
			margin-right: 8px;

			a {
				color: #ffffff;
			}
		}
	}
}

.vote-pack {
	display: block;
	float: right;

	ul.vote-pack-list {
		padding: 0;
		margin: 0;

		li {
			display: inline-block;
		}
	}
}

.dr-tohome {
	// color: #ffffff;
	// font-size: 30px; // line-height: 30px;
	// padding-top: 80px;
	// display: block; // float: left;
	display: block;
	border-right: 1px dotted $text-color;
	padding-right: 20px;
	margin-right: 10px;
}

.img-infobar01 {
	padding: 20px 0 10px;

	.info-user {
		float: left;
		display: block;
	}
}

.dr--img-credits {
	// float: right;
	text-align: right;
	padding-right: 20px;

	&.hidden-sm.hidden-md.hidden-lg {
		// height: 20px;
		// line-height: 20px;
		text-align: left;
		border-bottom: 1px dotted $txtchiarocolor;
		padding-bottom: 8px;
		padding-top: 4px;
		margin-bottom: 10px;
		display: block;

		span {
			display: inline;
		}

		.dr-data-caricamento {
			color: #000000!important;
		}
	}

	span.dr-data-caricamento {
		font-weight: 800;
		color: #ffffff;
	}

	span.dr--diritti {
		font-weight: 100;
		font-style: italic;
		display: block;
	}
}
// .onlyfromtablet{
//
// 	visibility: hidden;
// 	display: none;
//
// }
//
// .onlymobile{
// 	visibility: hidden;
// 	display: none;
// }
// @media #{$mobile} {
//
// 	.onlymobile{
// 		visibility: visible!important;
// 		display: block!important;
// 	}
//
//
// }
// @media #{$sm-screen} {
// 	.onlyfromtablet{
// 		visibility: visible!important;
// 		display: block!important;
// 	}
// }

.gallery-item-meta-vert {
	float: none;
	border-right: 0 solid;
	padding-right: 0;
	border-bottom: 1px solid;
	padding-bottom: 13px;
	margin-right: 0;
	text-align: center;

	strong {
		display: inline;
	}
}

.gallery-item-title-vert {
	margin: 8px;
}

.gallery-item-content-vert {
	left: 7px;
	right: 7px;
}
@media #{$sm-screen} {
	.gallery-item-title-vert {
		margin: 4px 2px;
		font-size: 13px;
	}
}
@media #{$mobile} {
	.gallery-item-meta-vert {
		border-bottom: 0 solid;
	}
}

.icona-verde {color: #33cc33 !important;}
