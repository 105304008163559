//Ads
.ads { overflow: hidden; margin: 0 -5px; }
.ad { float: left; width: 25%; padding: 0 5px; }
.ad a { display: block; border: 1px solid #ccc; border--radius: 5px; background: #fff; }
.ad img { width: 100%; height: auto; }

@media #{$sm-screen} {
	.ad { width: 100%; text-align: center; }
	.ad ~ .ad { margin-top: 21px; }
	.ad a { height: 90px; line-height: 90px; padding: 0 10px; }
	.ad img { height: 90%; width: auto; }
}
