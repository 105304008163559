//Comment

.comments-actions {
	padding: 16px 0 10px;
	border-width: 1px 0;
	border-style: solid;
	border-color: #ccc;
	margin-bottom: 20px;
	font-size: 17px;
	font-weight: bold;
}

.comments-actions a {
	color: #055aaa;
	text-decoration: none;
}

.comments-actions a:hover {
	text-decoration: underline;
}

.comments-actions i {
	margin: -4px 4px 0 0;
}

.comment {
	overflow: hidden;
	padding-bottom: 15px;
}

.comment ~ .comment {
	padding-top: 15px;
}

.comment-avatar {
	position: relative;
	float: left;
	padding-right: 30px;
}

.comment-avatar:after {
	content: '';
	position: absolute;
	top: 3px;
	right: 14px;
	border-width: 5px;
	border-style: solid;
	border-color: transparent transparent transparent #055aaa;
}

.comment-content {
	overflow: hidden;

	p {
		margin: 0 0 15px;
		line-height: 1.4em;
//      color: $txtcolor;
		font-size: 1.12em;
	}
}

.comment-author {
	margin: -4px 0 7px;
}

.comment-author a {
	color: #055aaa;
	font-weight: bold;
	font-size: 17px;
	text-decoration: none;
}

.comment-author em {
	padding-left: 4px;
	font-size: 12px;
	color: $txtchiarocolor;
}

.comment-author a:hover {
	text-decoration: underline;
}

.comment-title {
	padding: 1px 0 7px;
	margin: 0;
	color: #3b3c40;
	font-weight: bold;
	font-family: $fontCalvert;
}

.comment-respond textarea {
	width: 100%;
	height: 143px;
	padding: 4px 8px;
	background: #ffffff;
	border: 1px solid #ccc;
	font-size: 16px;
	resize: vertical;
	-webkit-appearance: none;
	border-radius: 0;
}

.comment-actions {
	text-align: right;
	padding-top: 16px;
}

.comment-actions .btn .ico-bubbles {
	margin-right: 10px;
}
@media #{$mobile} {
	.comment-actions .btn {
		display: block;
		width: 100%;
		font-size: 22px;
		padding: 20px 0;
	}

	.comment-actions .btn-sm-alt {
		font-size: 18px;
		padding: 15px 0;
	}
}
@media #{$mobile-sm} {
	.comment-actions .btn {
		display: block;
		width: 100%;
		font-size: 18px;
		padding: 15px 0;
	}
}

.logincomment{
	display: block;
	margin-bottom: 20px;
}

.commentoqs {
	list-style: none;
	float: left;
	font-size: 0.9em;
	font-style: italic;
	height: 27px;
	font-weight: 100;

	a {
		padding: 6px 12px;
		background-color: #0061a1;
		border-radius: 4px;
		text-align: center;
		clear: both;
		color: #FFF;
		font-size: 14px;
		margin-right: 7px;
	}

	a:hover {
		background-color: #00426e;
	}
}

li.bloccolike {
	list-style: none;
	float: left;
	border-left: 1px solid #ccc;
	margin-left: 5px;
	padding: 0 0 0 3px;
}

i.fa.fa-reply,
li.likecom,
li.nolikecom,
li.replycom {
	list-style: none;
}

.replycom a {
	font-size: 12px;
}

// /RISPOSTA commentoqs

.risp-comment{

	margin-left:60px;
	display: none;
	visibility: hidden;

}.reply_comments_list{
	margin-left:70px;

}

.risp-comments-2l{

	margin-top: 10px;
	display: none;
	visibility: hidden;

}

.comment-total{
	border-bottom: 1px dashed #cfcfcf;
	padding-top:25px;
}
