/* ------------------------------------------------------------ *\
	Dropdown-menu
\* ------------------------------------------------------------ */

.dropdown-menu {
//          left: auto;
//          right: 0; /*width: 284px;*/
          border: none;
          margin-top: 10px;
          box-shadow: none;
          border-radius: 3px;
          background: -webkit-gradient(linear, left bottom, left top, from(rgba(255, 255, 255, .9)), to(rgba(255, 255, 255, 1)));
          background: -webkit-linear-gradient(bottom, rgba(255, 255, 255, .9) 0%, rgba(255, 255, 255, 1) 100%);
          background: -moz-linear-gradient(bottom, rgba(255, 255, 255, .9) 0%, rgba(255, 255, 255, 1) 100%);
          background: linear-gradient(to top, rgba(255, 255, 255, .9) 0%, rgba(255, 255, 255, 1) 100%);
          //          border: 1px solid $txtcolor;

          //          -webkit-box-shadow: 0px 0px 25px 1px rgba(0,0,0,0.34);
          //-moz-box-shadow: 0px 0px 25px 1px rgba(0,0,0,0.34);
          //box-shadow: 0px 0px 25px 1px rgba(0,0,0,0.34);

          //          -webkit-box-shadow: 1px 2px 2px 2px rgba(0,0,0,0.22);
          //          -moz-box-shadow: 1px 2px 2px 2px rgba(0,0,0,0.22);
          //          box-shadow: 1px 2px 2px 2px rgba(0,0,0,0.22);
//          padding: 5px;

          box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .05), 0 0 1px 1px rgba(0, 0, 0, .15);
          -moz-box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .05), 0 0 1px 1px rgba(0, 0, 0, .15);
        -webkit-box-shadow: 0 1px 1px 1px rgba(0, 0, 0, .05), 0 0 1px 1px rgba(0, 0, 0, .15);

            -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
            -webkit-animation-duration: 0.6s;
            animation-duration: 0.6s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
 @-webkit-keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
  }
  @keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
  }

}

.dropdown-menu-left:before {
          content: '';
          position: absolute;
          bottom: 100%;
          left: 25px;
          border-width: 6px 6px;
          border-style: solid;
          border-color: transparent transparent transparent rgba(0, 0, 0, .35);
//          margin-bottom: -9px;
          margin-bottom: 0px;
//          box-shadow: -1px -1px 1px -1px rgba(0, 0, 0, .44);
          @include rotate(-90);
}

.dropdown-menu-right:before {

           content: '';
          position: absolute;
          bottom: 100%;
          right: 25px;
          border-width: 6px 6px;
          border-style: solid;
          border-color: transparent transparent transparent rgba(0, 0, 0, .35);
//          margin-bottom: -9px;
          margin-bottom: 0px;
//          box-shadow: -1px -1px 1px -1px rgba(0, 0, 0, .44);
          @include rotate(-90);

}

.dropdown-menu > li {
          overflow: hidden;
          text-align: left;
}

.dropdown-menu > li:first-child {
          border-radius: 5px 5px 0 0;
}

.dropdown-menu > li:last-child {
          border-radius: 0 0 5px 5px;
}

//.dropdown-menu > li + li { border-top: 1px solid #aac8da; }
.dropdown-menu > li > a {
          padding: 15px 35px 15px 15px;
          text-decoration: none;
          font-size: 16px;
          color: #5097cd;
          @include transition(color .3s, background .3s);
          text-transform: capitalize;
          border-bottom: 1px solid #e8e8e8;
          font-weight: 600;
}

.dropdown-menu > li:last-child > a {
          border-bottom: none;
}

//.dropdown-menu > li > a:hover { background: #fff; color: #5097cd; }

.dropdown-menu > li > a > .fa {

          font-size: 15px;
          margin-right: 10px;
}

.bell-menu {
          padding: 15px;
          width: 400px;
          h3 {
                    color: #333;
                    text-transform: uppercase;
                    font-size: 2rem;
                    font-weight: 100;
                    margin-top: 0px;
                    margin-bottom: 2px;
          }
          p {
                    color: #999;
                    font-size: 1.4rem;
                    font-weight: 100;
          }
          input {
                    border: 0px;
                    color: #999;
                    font-weight: 100;
                    font-size: 1.4rem;
                    padding: 12px;
                    width: 370px;
                    height: 30px;
                    background-color: $backgroundgrigio;
          }
          hr {
                    margin-top: 13px;
                    margin-bottom: 13px;
          }
          span {
                    text-align: left;
                    margin-top: 2px;
          }
          i {
                    margin-right: 5px;
          }
          label {
                    margin-bottom: 0px;
                    font-weight: normal;
                    font-size: 14px;
          }

}

.bell-menu-box {
          min-width: 400px;
          margin-right: -23px;
          li a {
                    text-transform: none;
                    display: inline;
          }
}

.bg-bell-menu {
          background-color: #FFF;
          a {
                    color: #0061a1;
                    font-weight: bold;
          }
          li {
                    border-bottom: 1px solid #e8e8e8;
                    padding: 10px;
                    line-height: 17px;
                    list-style: none;
          }

          i {
                    font-size: 1.3rem;
          }
}

.img-menu-bell {
          margin-left: 43px;
          img {
                    width: 70px;
                    height: auto;
                    margin-top: 10px;
          }
}

.bell-menu-av {
  width: 32px;
height: 32px;
background-color: #CCC;
float: left;
margin-right: 15px;
background-size: cover;

          img {
                    display: none;

                    width: 32px;
                    height: 32px;
                    border-radius: 5px;
                    margin-right: 10px;
          }

}

.dropdown {
          list-style: none;
}

//NO NO NO NO NO NO

//i.fa.fa-pencil-square-o {
//          color: #999;
//}
//NO NO NO NO NO NO
//
//

a.mydraft {
          color: #999 !important;
}


.dropdown-backdrop{ visibility: hidden; display: none;}
